import { FC, ReactNode, useMemo, useState } from 'react'

import { useWindowSize } from '@/lib/hooks/useWindowSize'
import useSwipe, { SwipeDirection } from '@/lib/hooks/useSwipe'
import { classNames } from '@/lib/misc/Utils'

interface ServiceWizardSummaryProps {
  isExtended?: boolean
  title: string
  summaryBody: ReactNode
  extendedSummarySidebar: ReactNode
  extendedSummaryBody: ReactNode
}

const ServiceWizardSummary: FC<ServiceWizardSummaryProps> = ({
  isExtended,
  title,
  summaryBody,
  extendedSummaryBody,
  extendedSummarySidebar,
}) => {
  const [isMobileExtended, setIsMobileExtended] = useState(false)

  const [innerWidth] = useWindowSize()
  const swipeProps = useSwipe({
    onFinished: () => setIsMobileExtended(false),
    swipeDirection: SwipeDirection.DOWN,
  })
  const isSmallScreen = useMemo(() => innerWidth < 756, [innerWidth])

  return isExtended ? (
    <div className="bg-auxiliary-3 flex flex-col md:grid md:grid-cols-3 rounded-lg">
      <div className="bg-brand-4 shadow-2xl col-span-2 rounded-lg">
        <h5 className="mt-7 px-7 text-white text-heading-4 tracking-tightest">
          Service Summary
        </h5>
        <div className="flex flex-col px-7 mb-4">{extendedSummaryBody}</div>
      </div>
      {extendedSummarySidebar}
    </div>
  ) : isMobileExtended || !isSmallScreen ? (
    <div className={classNames('mt-4 mb-8', isSmallScreen && 'w-full h-full')}>
      {isSmallScreen && (
        <div
          {...swipeProps}
          onClick={() => setIsMobileExtended(false)}
          className="fixed z-30 left-0 top-0 h-[100vh] w-full bg-black/80"
        />
      )}

      <div
        {...swipeProps}
        className={classNames(
          'z-30 bg-auxiliary-3 max-h-[90%]',
          isSmallScreen
            ? 'fixed bottom-0 left-0 w-full overflow-y-auto rounded-t-2xl'
            : 'rounded-lg'
        )}
      >
        <div
          className={classNames(
            'bg-brand-4 shadow-2xl px-4 py-7',
            isSmallScreen
              ? 'rounded-t-2xl border-1 border-neutral-40 box-border il-dialog'
              : 'rounded-lg'
          )}
        >
          <div className="mb-3 text-white">
            <h3 className="text-heading-4">{title}</h3>
            <h5 className="mt-1 text-14 leading-4 tracking-tightest">
              Service Summary
            </h5>
          </div>

          {summaryBody}
        </div>
      </div>
    </div>
  ) : (
    <div
      onClick={() => setIsMobileExtended(true)}
      className="fixed z-30 bottom-0 left-0 w-full flex justify-center items-center rounded-t-2xl px-6 py-3 bg-brand-4 text-white text-14 leading-4 cursor-pointer"
    >
      View Summary
    </div>
  )
}

export default ServiceWizardSummary
