import { InputHTMLAttributes, MouseEventHandler, forwardRef, memo } from 'react'

import Input, { BasicInputProps } from '@/components/GenericInput'

import SearchIcon from '@icons/magnifying-glass'
import CloseIcon from '@icons/x-mark'

interface InputSearchProps
  extends BasicInputProps,
    InputHTMLAttributes<HTMLInputElement> {
  onReset?: () => void
  showSearchIcon?: boolean
}

const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  ({ onReset, showSearchIcon = true, ...props }, ref) => {
    const onResetHandler: MouseEventHandler<SVGSVGElement> = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (onReset) {
        onReset()
      }
    }

    return (
      <Input type="search" {...props} ref={ref}>
        <div className="absolute -translate-y-2/4 right-3 top-2/4 z-20">
          {showSearchIcon && !props.value && (
            <SearchIcon className="text-neutral-40" />
          )}
          {!!props.value && (
            <CloseIcon
              className="text-neutral-40 cursor-pointer"
              onClick={onResetHandler}
              data-cy="search-close-icon"
            />
          )}
        </div>
      </Input>
    )
  }
)

export default memo(InputSearch)
