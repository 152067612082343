import { FC, useEffect, useState } from 'react'
import Input from '@/components/GenericInput'
import { GenericErrors } from '@/lib/definitions/generic.types'
import Checkbox from '@/components/Checkbox'
import { LAGProperties } from '@/pages/ServiceWizard/ServiceWizard.types'

interface LAGCreateFormProps {
  values?: LAGProperties
  setLagInfo: (lagInfo: LAGProperties | undefined) => void
}

const LAGCreateForm: FC<LAGCreateFormProps> = ({ values, setLagInfo }) => {
  const [isFormVisible, setIsFormVisible] = useState(!!values)
  const [lagProperties, setLagProperties] = useState<any>(values)
  const [lagErrors, setLagErrors] = useState<GenericErrors | undefined>()

  const setLagMemberCount = (count: number) => {
    const memberCount = Number(count)

    if (
      memberCount &&
      (isNaN(memberCount) || memberCount < 1 || memberCount > 10)
    ) {
      setLagErrors({
        memberCount: ['LAG members must be a number between 1 and 10'],
      })
      return
    } else {
      setLagErrors(undefined)

      setLagProperties({ ...lagProperties, memberCount })
    }
  }

  const setLagName = (name: string) => {
    setLagProperties({ ...lagProperties, name })
  }

  const toggleFormVisible = (visible: boolean) => {
    setIsFormVisible(visible)
  }

  useEffect(() => {
    if (!isFormVisible) {
      setLagInfo(undefined)
    } else if (
      isFormVisible &&
      (!lagProperties?.name || !lagProperties?.memberCount)
    ) {
      setLagInfo({ name: '', memberCount: 0 })
    } else {
      setLagInfo(lagProperties)
    }
  }, [lagProperties, isFormVisible, setLagInfo])

  return (
    <div>
      <Checkbox
        cy="lag-form-toggle"
        checked={isFormVisible}
        className="h-5 w-5"
        onChange={(e) => toggleFormVisible(e.target.checked)}
      >
        <div className="text-heading-byline tracking-tightest text-brand-1 ml-2 cursor-pointer">
          Create LAG
        </div>
      </Checkbox>
      {isFormVisible && (
        <div>
          <div className="mt-2.5">
            <Input
              name="lagMemberCount"
              cy="input-lag-member-count"
              label="Number of LAG members*"
              value={lagProperties?.memberCount || ''}
              {...(!!lagErrors &&
                lagErrors.memberCount && {
                  errors: lagErrors.memberCount,
                })}
              required
              onChange={(e: any) => setLagMemberCount(e.target.value)}
              placeholder="eg: 2"
            />
          </div>
          <div className="mt-2.5">
            <Input
              type="text"
              name="lagName"
              cy="input-lag-name"
              label="LAG name*"
              value={lagProperties?.name || ''}
              {...(!!lagErrors &&
                lagErrors.name && {
                  errors: lagErrors.name,
                })}
              required
              onChange={(e: any) => setLagName(e.target.value)}
              placeholder="eg: LAG1"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default LAGCreateForm
