import { FC, useEffect, useMemo, useState } from 'react'

import Input from '@/components/GenericInput'
import useIpAccessWizardState from '@/lib/hooks/useIpAccessWizardState'
import {
  CommittedDataRateOption,
  PrefixOption,
  ContractTerm,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import WidePrefix from '@/pages/ServiceWizard/partials/WidePrefix'
import AggregatedBilling from '@/pages/ServiceWizard/partials/AggregatedBilling'
import {
  BillingType,
  ServiceComponentVLANType,
} from '@/lib/definitions/api.types'
import { classNames, notifyExtensionsTermChange } from '@/lib/misc/Utils'
import WizardContractTerm from '@/pages/ServiceWizard/partials/WizardContractTerm'
import VlanSelection from '@/pages/ServiceWizard/partials/VlanSelection'
import CommittedDataRateSelector from '@/pages/ServiceWizard/partials/CommittedDataRateSelector'

interface TabIpAccessProps {
  getPrices: (type: BillingType) => void
}

const TabIpAccess: FC<TabIpAccessProps> = ({ getPrices }) => {
  const { ipAccessWizardState, updateIpAccessWizard } = useIpAccessWizardState()

  const {
    contract,
    prefixV4Items,
    selectedPrefixV4,
    selectedServiceSpeed,
    selectedLocation,
    vlanId,
    vlanType,
    bandwidthsItems,
    availableServiceBandwidths,
    selectedPortSpeed,
    serviceSpeedType,
    extensionPrices,
    lag,
    aggregatedCommitTotals,
  } = ipAccessWizardState

  const [isUsingExtensions, setIsUsingExtensions] = useState(false)

  const selectServiceSpeedType = (type: BillingType) => {
    updateIpAccessWizard({
      serviceSpeedType: type,
    })
  }

  const selectPrefixV4 = (prefix: PrefixOption) => {
    updateIpAccessWizard({
      selectedPrefixV4: prefix.id,
    })
  }

  const selectServiceSpeed = (serviceSpeed: CommittedDataRateOption) => {
    updateIpAccessWizard({
      selectedServiceSpeed: serviceSpeed,
    })
  }

  const serviceSpeeds = useMemo(() => {
    if (selectedPortSpeed?.isLag || lag !== undefined) {
      return Object.keys(bandwidthsItems || {}).map((key) => Number(key))
    }

    return availableServiceBandwidths?.[Number(selectedPortSpeed?.speed)]
  }, [selectedPortSpeed, bandwidthsItems, availableServiceBandwidths, lag])

  useEffect(() => {
    getPrices(serviceSpeedType || 'committed')
  }, [getPrices, serviceSpeedType])

  const setContractTerm = (term: ContractTerm) =>
    updateIpAccessWizard({ contract: term.period })

  const toggleAggregatedBilling = (isChecked: boolean) =>
    updateIpAccessWizard({ hasAggregatedBilling: isChecked })

  useEffect(() => {
    const isExt = selectedLocation?.type === 'Extension'
    setIsUsingExtensions(isExt)
    if (contract == 1 && isExt) {
      notifyExtensionsTermChange()
      updateIpAccessWizard({ contract: 12 })
    }
  }, [selectedLocation, contract, updateIpAccessWizard])

  return (
    <div className="w-full gap-y-10 flex flex-col">
      <h1 className="text-heading-4 tracking-tightest text-brand-1">
        Configure your IP Access
      </h1>

      <div>
        <div className="text-heading-byline tracking-tightest text-brand-1">
          Configure IP-Access
        </div>

        <div className="flex flex-col space-between gap-y-4 mt-2">
          <Input
            name="purchaseReference"
            cy="input-purchaseReference"
            label="Purchase Reference"
            onChange={(e: any) =>
              updateIpAccessWizard({ purchaseReference: e.target.value })
            }
            value={ipAccessWizardState.purchaseReference || ''}
            placeholder="eg: PO-1"
          />
        </div>
      </div>

      <WizardContractTerm
        inline={false}
        contract={contract}
        setContractTerm={setContractTerm}
        minContractTerm={isUsingExtensions ? 12 : 1}
      />

      {/*Prefixes*/}
      <div className="flex flex-col">
        <div>
          <div className="text-heading-byline tracking-tightest text-brand-1">
            Select IPv4 prefix size
          </div>
          <div className="my-2 text-neutral-50">
            You will be allocated an IPv6 /56 subnet in addition to your chosen
            IPv4 subnet
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 mt-4">
          {prefixV4Items?.map((prefix: PrefixOption) => (
            <WidePrefix
              prefixType="IPv4"
              key={prefix.id}
              prefix={prefix}
              onClick={selectPrefixV4}
              isActive={prefix.id === selectedPrefixV4}
            />
          ))}
        </div>
      </div>

      <div>
        <div className="text-heading-byline tracking-tightest text-brand-1">
          Select service speed
        </div>

        <div className="flex w-full gap-x-2 my-4 bg-white p-1 rounded-xl border-1 border-neutral-20">
          <div
            className={classNames(
              'rounded-lg w-full uppercase text-center py-3 px-4 cursor-pointer hover:opacity-75',
              serviceSpeedType === 'committed'
                ? 'bg-accent-40 text-white'
                : 'text-accent-60'
            )}
            onClick={() => selectServiceSpeedType('committed')}
            data-cy="wizard-committed-service-speed"
          >
            COMMITTED
          </div>
          <div
            className={classNames(
              'rounded-lg w-full uppercase text-center py-3 px-4 cursor-pointer hover:opacity-75',
              serviceSpeedType === 'flat'
                ? 'bg-accent-40 text-white'
                : 'text-accent-60'
            )}
            onClick={() => selectServiceSpeedType('flat')}
            data-cy="wizard-flat-service-speed"
          >
            FLAT
          </div>
        </div>
        <CommittedDataRateSelector
          serviceSpeeds={serviceSpeeds}
          bandwidthsItems={bandwidthsItems}
          aggregatedCommitTotals={aggregatedCommitTotals}
          selectedPortSpeed={selectedPortSpeed}
          selectedServiceSpeed={selectedServiceSpeed}
          extensionPrices={extensionPrices}
          hasAggregatedBilling={ipAccessWizardState.hasAggregatedBilling}
          selectServiceSpeed={selectServiceSpeed}
        />
      </div>

      <AggregatedBilling
        isChecked={ipAccessWizardState.hasAggregatedBilling}
        toggleAggregatedBilling={toggleAggregatedBilling}
        isDisabled={serviceSpeedType === 'flat'}
      />

      <VlanSelection
        onIdChange={(id: number) => updateIpAccessWizard({ vlanId: id })}
        onTypeChange={(type: ServiceComponentVLANType) =>
          updateIpAccessWizard({ vlanType: type })
        }
        vlanId={vlanId}
        vlanType={vlanType}
      />
    </div>
  )
}

export default TabIpAccess
