import { captureException } from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { useMutation } from 'react-query'

import { IpAccessTabSteps } from '@/pages/ip-access/IpAccess'
import IpAccessSummaryPricing from '@/pages/ip-access/IpAccessSummaryPricing'

import { IPAccessPriceRequest, PortTypes } from '@/lib/definitions/api.types'
import { GenericErrors } from '@/lib/definitions/generic.types'
import { Notify } from '@/lib/misc/Notify'
import useIpAccessWizardState from '@/lib/hooks/useIpAccessWizardState'
import { IpAccessService } from '@/lib/services/IpAccessService'

import SummaryPortSpeed from '@/pages/ip-transit/partials/SummaryPortSpeed'
import SummaryLocation from '@/pages/ip-transit/partials/SummaryLocation'

import { PortDetail } from '@/pages/ServiceWizard/ServiceWizard.types'
import ServiceWizardSummary from '@/pages/ServiceWizard/ServiceWizardSummary'
import SummaryContractTerm from '@/pages/ServiceWizard/partials/SummaryContractTerm'
import { price, renderLagMaxSpeedValue } from '@/lib/misc/Utils'

const ipAccess = new IpAccessService()

const IpAccessWizardSummary = () => {
  const { ipAccessWizardState, updateIpAccessWizard } = useIpAccessWizardState()

  const requestPrice = () => {
    const {
      selectedPortSpeed,
      selectedPrefixV4,
      contract,
      selectedLocation,
      serviceSpeedType,
      selectedServiceSpeed,
      lag,
      existingPortId,
    } = ipAccessWizardState

    const pricingRequest: IPAccessPriceRequest = {
      location: String(selectedLocation?.name),
      term: Number(contract),
      bandwidth: Number(selectedServiceSpeed?.speedInMbps),
      prefix_v4: selectedPrefixV4!,
      port: existingPortId ? undefined : (selectedPortSpeed?.name as PortTypes),
      type: serviceSpeedType || 'committed',
      lag_member_count: lag?.memberCount,
    }

    return ipAccess.requestIpAccessPriceSummary(pricingRequest)
  }

  const { mutate: requestPriceQuote } = useMutation(requestPrice, {
    onError: (error: GenericErrors) => {
      Notify.error(error?.message ?? 'An error occurred')
      captureException(error)
    },
    onSuccess: ({ data }) => {
      if (!data) {
        Notify.error('An error occurred while getting your order quote')
        updateIpAccessWizard({ quote: undefined })

        return
      }

      updateIpAccessWizard({ quote: data })
    },
  })

  const isExtended: boolean = useMemo(() => {
    if (!ipAccessWizardState?.activeTab) return false
    return ipAccessWizardState.activeTab === IpAccessTabSteps.Summary
  }, [ipAccessWizardState])

  useEffect(() => {
    if (isExtended) {
      requestPriceQuote()
    }
  }, [isExtended, ipAccessWizardState?.contract, requestPriceQuote])

  const portDetails: PortDetail[] = useMemo(() => {
    const { vlanId, selectedPortSpeed, selectedServiceSpeed } =
      ipAccessWizardState

    const portSpeedLabel = selectedPortSpeed
      ? `${selectedPortSpeed.speed} ${selectedPortSpeed.unit}`
      : ''

    const data: PortDetail[] = [
      {
        key: 'selected-port-speed-speed',
        label: 'Port Speed',
        value: portSpeedLabel,
      },
    ]

    if (selectedPortSpeed && !isExtended) {
      data.push({
        key: 'port_speed_nrc',
        label: 'Port Setup Fee',
        value: price(Number(selectedPortSpeed.nrc.amount)),
      })
      data.push({
        key: 'port_speed_mrc',
        label: 'Port Monthly Cost',
        value: price(Number(selectedPortSpeed.mrc.amount)),
      })
    }

    if (ipAccessWizardState.selectedPrefixV4) {
      const prefixV4 = ipAccessWizardState.prefixV4Items?.find(
        (prefix) => prefix.id === ipAccessWizardState.selectedPrefixV4
      )

      if (prefixV4) {
        data.push({
          key: 'prefix_v4',
          label: 'IPv4 Prefix',
          value: price(Number(prefixV4.mrc.amount)),
        })
      }
    }

    if (ipAccessWizardState.lag) {
      data.push({
        key: 'lag_member_count',
        label: 'LAG members',
        value: ipAccessWizardState.lag.memberCount,
      })

      if (selectedPortSpeed?.speed && ipAccessWizardState.lag.memberCount) {
        data.push({
          key: 'lag_maximum_speed',
          label: 'LAG maximum speed',
          value: renderLagMaxSpeedValue(
            selectedPortSpeed.speed,
            selectedPortSpeed.unit,
            ipAccessWizardState.lag.memberCount
          ),
        })
      }
    }

    if (ipAccessWizardState.vlanType === 'Tagged') {
      data.push({
        key: 'vlanId',
        label: 'VLAN ID',
        value: vlanId,
      })
    } else {
      data.push({
        key: 'vlanType',
        label: 'VLAN',
        value: ipAccessWizardState.vlanType,
      })
    }

    if (selectedServiceSpeed?.speed) {
      data.push({
        key: 'bandwidth',
        label: 'Bandwidth',
        value: `${selectedServiceSpeed?.speed} ${selectedServiceSpeed?.unit}`,
      })
    }

    return data
  }, [ipAccessWizardState, isExtended])

  return (
    <ServiceWizardSummary
      isExtended={isExtended}
      title="IP Access"
      summaryBody={
        <>
          <SummaryLocation location={ipAccessWizardState.selectedLocation} />

          {(Number(ipAccessWizardState.activeTab) !==
            IpAccessTabSteps.Location ||
            !!ipAccessWizardState?.existingPortId) && (
            <div className="mt-8 col-span-2">
              <SummaryPortSpeed portDetails={portDetails} />
            </div>
          )}

          <div className="flex flex-col">
            <SummaryContractTerm term={ipAccessWizardState.contract} />
          </div>
        </>
      }
      extendedSummaryBody={
        <>
          <SummaryLocation location={ipAccessWizardState.selectedLocation} />

          <div className="mt-8 mb-4 col-span-2">
            <SummaryPortSpeed isExtended portDetails={portDetails} />
          </div>
        </>
      }
      extendedSummarySidebar={<IpAccessSummaryPricing />}
    />
  )
}

export default IpAccessWizardSummary
