import { PortDetail } from '@/pages/ServiceWizard/ServiceWizard.types'

interface SummaryPortSpeedInterface {
  isExtended?: boolean
  portDetails: PortDetail[]
}

const SummaryPortSpeed = ({
  isExtended,
  portDetails,
}: SummaryPortSpeedInterface) => (
  <>
    <h3 className="text-white text-heading-byline my-2">Port Details</h3>

    <div className="w-full flex flex-col">
      {portDetails.map((field) => {
        if (!field.value || (isExtended && field.isNrc))
          return <div key={field.key} />

        return (
          <div className="flex justify-between mb-3" key={field.key}>
            <span className="font-text text-white">{field.label}</span>
            <span className="font-text text-brand-3 whitespace-nowrap">
              {field.value}
            </span>
          </div>
        )
      })}
    </div>
  </>
)

export default SummaryPortSpeed
