import { FC, memo } from 'react'
import { classNames } from '@/lib/misc/Utils'

import { CircleFlag } from 'react-circle-flags'
import { ExistingPortItem } from '@/pages/ServiceWizard/ServiceWizard.types'
import GlobeEuropeAfricaIcon from '@icons/globe-europe-africa'
import MapPinIcon from '@icons/map-pin'
import BuildingOfficeIcon from '@icons/building-office'

interface ExistingPortCardProps {
  selected?: number | string
  existingPort: ExistingPortItem
  className?: string
  cy?: string
  onClick?: () => void
}

const ExistingPortCard: FC<ExistingPortCardProps> = ({
  selected,
  existingPort,
  className,
  cy,
  onClick,
}) => {
  const location = existingPort.location
  const isLag: boolean = existingPort.isLag

  return (
    <div
      data-cy={cy}
      onClick={onClick}
      className={classNames(
        'bg-neutral-05 rounded-xl hover:shadow-[0px_10px_15px_-3px_rgba(36,14,50,0.10),0px_4px_20px_0px_rgba(36,14,50,0.10)] cursor-pointer transition-shadow',
        selected === existingPort.id &&
          'shadow-neutral-100 ring-2 ring-brand-5',
        className
      )}
    >
      <div className="p-4 bg-white rounded-t-xl">
        <div className="flex items-start mb-2 justify-between">
          <div className="flex items-center">
            <div className="mr-2 shrink-0">
              <CircleFlag
                countryCode={existingPort.location.city.country.short_name.toLocaleLowerCase()}
                height="32"
                width="32"
                alt={existingPort.location.city.country.short_name}
                title={existingPort.location.city.country.short_name}
              />
            </div>
            <div>{existingPort.location.city.name}</div>
          </div>
          <div className="font-text text-brand-4 text-right space-y-2">
            <span>
              {existingPort.portSpeed?.speed || '-'}
              {existingPort.portSpeed?.unit || ''}
            </span>

            {isLag && (
              <span className="text-neutral-60 block mt-1">
                {existingPort.lagCount} ports
              </span>
            )}

            {existingPort.serviceSid && (
              <div className="text-neutral-40">
                <span className="text-brand-5">{existingPort.serviceSid}</span>
              </div>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <div className="text-neutral-40">
            <span className="mr-2 block">
              {existingPort.isLag ? 'LAG name:' : 'Port name:'}
            </span>
            <span className="text-brand-5 break-words">
              {existingPort.name}
            </span>
          </div>
          {existingPort.virtualNetworkName && (
            <div className="text-neutral-40">
              <span className="mr-2 block">Virtual network name:</span>
              <span className="text-brand-5">
                {existingPort.virtualNetworkName}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="p-4 rounded-b-xl border-t">
        {location?.supplier_name && (
          <div className="flex">
            <span title="Supplier">
              <BuildingOfficeIcon className="w-4 h-4 mr-2" />
            </span>
            <div className="text-neutral-60">{location.supplier_name}</div>
          </div>
        )}

        <div className="flex">
          <span title="Location Description">
            <GlobeEuropeAfricaIcon className="w-4 h-4 mr-2" />
          </span>
          <div className="text-neutral-60">{location.description}</div>
        </div>

        <div className="flex">
          <span title="Address">
            <MapPinIcon className="w-4 h-4 mr-2" />
          </span>
          <div className="text-neutral-60">
            {location.line1}, {location.postal_code} {location.city.name}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ExistingPortCard)
