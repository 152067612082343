import { classNames } from '@/lib/misc/Utils'
import { ReactNode } from 'react'

interface SummaryFancyBoxProps {
  children: ReactNode
  className?: string
  wrapperClassName?: string
}

const SummaryFancyBox = ({
  children,
  className,
  wrapperClassName,
}: SummaryFancyBoxProps) => (
  <div
    className={classNames(
      'p-[0.85px] w-full rounded-lg box-content',
      wrapperClassName
    )}
    style={{
      background:
        'linear-gradient(160deg, rgba(216, 180, 253, 0.5) 0%, rgba(216, 180, 253, 0) 100%)',
    }}
  >
    <div className="bg-brand-4 rounded-lg h-full">
      <div
        className={classNames(
          'flex flex-row items-center justify-between font-text rounded-lg p-2 z-20 h-full',
          className
        )}
        style={{
          background:
            'linear-gradient(136deg, rgba(159, 115, 202, 0.30) 0%, rgba(159, 115, 202, 0.00) 100%)',
        }}
      >
        {children}
      </div>
    </div>
  </div>
)

export default SummaryFancyBox
