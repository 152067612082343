import { useEffect, useMemo, useRef, useState } from 'react'
import { parseResponseErrorsAndNotify, scrollToTop } from '@/lib/misc/Utils'
import useDDoSProtectionWizardState from '@/lib/hooks/useDDoSProtectionWizardState'
import ServiceWizard from '@/pages/ServiceWizard/ServiceWizard'
import DDoSProtectionWizardSummary from '@/pages/ddos-protection/partials/DDoSProtectionWizardSummary'
import { useApp } from '@/lib/provider/AppProvider'
import FeatureIsUnavailable from '@/components/FeatureIsUnavailable'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { captureException } from '@sentry/react'
import { Notify } from '@/lib/misc/Notify'
import { useNavigate } from 'react-router-dom'
import { useLoadServices } from '@/lib/queries/Services.queries'
import {
  IpServices,
  WizardTabItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import { ProductIdentifiers } from '@/lib/definitions/types'
import Button from '@/components/Button'
import useTransferToService from '@/lib/hooks/useTransferToWizard'
import TabPrefixes from '@/pages/ddos-protection/TabPrefixes'

const tabs: WizardTabItem[] = [
  {
    id: 'DDoSProtection',
    label: 'DDoS Protection',
    headline: 'Configure Your DDoS Protection',
  },
  { id: 'Summary', label: 'Summary' },
]

export enum DDoSProtectionTabSteps {
  DDoSProtection = 1,
  Summary = 2,
}

export type DDoSProtectionStep = keyof typeof DDoSProtectionTabSteps

type IpServicesStatus = 'LOADING' | 'HAS_SERVICES' | 'NO_SERVICES_FOUND'

const DDoSProtection = () => {
  const navigate = useNavigate()
  const [appState] = useApp()
  const { redirectToWizard } = useTransferToService()

  const {
    ddosProtectionWizardState,
    updateDDoSProtectionWizard,
    resetDDoSProtectionWizard,
  } = useDDoSProtectionWizardState()

  const wrapperRef = useRef<null | HTMLDivElement>(null)
  const [isPreviousStepDisabled, setIsPreviousStepDisabled] = useState(true)
  const [isPreviousHidden, setIsPreviousHidden] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(true)

  const { data: ipServices, isLoading: isIpServicesLoading } = useLoadServices({
    productId: [
      ProductIdentifiers.IP_TRANSIT,
      ProductIdentifiers.IP_ACCESS,
      ProductIdentifiers.LEGACY_IP_TRANSIT,
      ProductIdentifiers.LEGACY_IP_ACCESS,
    ],
  })

  const {
    activeTab,
    activeTabIndex,
  }: { activeTab: DDoSProtectionStep; activeTabIndex: number } = useMemo(() => {
    const currentTab =
      ddosProtectionWizardState?.activeTab ??
      (DDoSProtectionTabSteps[1] as DDoSProtectionStep)

    return {
      activeTab: currentTab,
      activeTabIndex: Number(DDoSProtectionTabSteps[currentTab]),
    }
  }, [ddosProtectionWizardState])

  const startOrder = () => {
    // do the logic when the API is ready!
  }

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation(
    // @ts-ignore the API is not ready yet.
    startOrder,
    {
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(
          error,
          'An internal error occurred while placing your order'
        )
        captureException(error)
      },
      onSuccess: async ({ data: servicesData }: { data: any }) => {
        if (servicesData) {
          Notify.success('Your order has been placed successfully')

          resetDDoSProtectionWizard()

          setTimeout(
            () => navigate(`/services/ddos-protection/${servicesData.id}`),
            1200
          )
        }
      },
    }
  )

  const handleTabChange = (newTabIndex: number) => {
    const newActiveTab: DDoSProtectionStep = DDoSProtectionTabSteps[
      newTabIndex
    ] as DDoSProtectionStep
    if (newActiveTab) updateDDoSProtectionWizard({ activeTab: newActiveTab })
  }

  const handleNextStep = async () => {
    const stepIndex: number = DDoSProtectionTabSteps[activeTab]
    if (stepIndex === tabs.length) {
      placeOrder()
      return
    }

    handleTabChange(stepIndex + 1)
    scrollToTop(wrapperRef)
  }

  const handlePreviousStep = () => {
    const stepIndex = DDoSProtectionTabSteps[activeTab]

    if (stepIndex > 1) {
      handleTabChange(stepIndex - 1)
      scrollToTop(wrapperRef)
    }
  }

  const navigateToWizard = (service: IpServices) => {
    navigate(`/services/${service}`)
    redirectToWizard('ddos-protection')
  }

  useEffect(() => resetDDoSProtectionWizard(), [resetDDoSProtectionWizard])

  useEffect(() => {
    const stepIndex: number = DDoSProtectionTabSteps[activeTab]

    // @TODO add validation to make sure that all prefixes values are valid

    setIsNextDisabled(isPlacingOrder)
    setIsPreviousStepDisabled(stepIndex === 1)
    setIsPreviousHidden(stepIndex === 1)
  }, [activeTab, ddosProtectionWizardState, isPlacingOrder])

  const servicesStatus: IpServicesStatus = useMemo(() => {
    if (isIpServicesLoading) return 'LOADING'

    return ipServices && ipServices.length > 0
      ? 'HAS_SERVICES'
      : 'NO_SERVICES_FOUND'
  }, [isIpServicesLoading, ipServices])

  if (!appState.waffleFlags.includes('ddos_protection'))
    return <FeatureIsUnavailable />

  if (!ddosProtectionWizardState) return <div />

  return (
    <ServiceWizard
      pageTitle="Order DDoS Protection"
      isSummaryStep={
        DDoSProtectionTabSteps[activeTab] === DDoSProtectionTabSteps.Summary
      }
      wrapperRef={wrapperRef}
      tabs={tabs}
      activeTabIndex={activeTabIndex}
      handlePreviousStep={handlePreviousStep}
      handleNextStep={handleNextStep}
      isPreviousStepDisabled={isPreviousStepDisabled}
      isNextDisabled={isNextDisabled}
      isPreviousHidden={isPreviousHidden}
      summarySection={<DDoSProtectionWizardSummary services={ipServices} />}
      nextButtonLabel="Go to checkout"
      backButtonLabel="Edit DDoS Protection"
      minimalLayout={servicesStatus !== 'HAS_SERVICES'}
    >
      {servicesStatus === 'LOADING' && <div>Loading...</div>}
      {servicesStatus === 'NO_SERVICES_FOUND' && (
        <div>
          <h3 className="text-heading-5">
            DDOS Protection requires an active IP-Transit or IP-Access Service
            to enable protection.
          </h3>

          <div className="flex flex-row mt-8 justify-around">
            <Button
              style="primary"
              onClick={() => navigateToWizard('ip-transit')}
            >
              Add IP-Transit service
            </Button>
            <Button
              style="primary"
              onClick={() => navigateToWizard('ip-access')}
            >
              Add IP-Access service
            </Button>
          </div>
        </div>
      )}

      {servicesStatus === 'HAS_SERVICES' && <TabPrefixes />}
    </ServiceWizard>
  )
}

export default DDoSProtection
