import React from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { GeoCircle, GeoRectangle } from '@/pages/ServiceWizard/ServiceWizard.types'
import { haversineDistance } from '@/lib/misc/Utils'
import GooglePlacesAutocomplete from '@/components//GooglePlacesAutocomplete'

const SEARCH_RADIUS = 5000  // 5km

// Workaround for wrong data returned by google
// See https://jet.inter.link/youtrack/issue/TECH-497/Implement-workaround-for-wrong-Google-geocoding-data for details
const STATIC_GEOCODING_DATA: {[key: string]: GeoRectangle}  = {
  "ChIJuRMYfoNhsUcRoDrWe_I9JgQ": {
    southWest: {lat: 53.399500, lng: 9.695041},
    northEast: {lat: 53.733072, lng: 10.197665}
  }
}

function isWideArea(rect:GeoRectangle){
  const diagonal = haversineDistance(rect.northEast.lat, rect.northEast.lng, rect.southWest.lat, rect.southWest.lng)
  return diagonal / 2 > SEARCH_RADIUS
}


interface LocationSearchInputProps {
  onPlaceSelected: (circle?: GeoCircle, rectangle?: GeoRectangle, country?: string) => void
}

// https://developers.google.com/maps/documentation/javascript/place-autocomplete
const LocationSearchInput:React.FC<LocationSearchInputProps> = ({onPlaceSelected}) => {
  const handleReset = () => {
    onPlaceSelected()
  }

  const handleSelect = async (details: any) => {
    if(STATIC_GEOCODING_DATA[details.place_id]){
      onPlaceSelected(undefined, STATIC_GEOCODING_DATA[details.place_id])
      return
    }
    const isCountry = details.types.indexOf("country") != -1
    const results = await getGeocode({ placeId: details.place_id })
    const { lat, lng } = getLatLng(results[0])
    const { geometry } = results[0]

    if(isCountry){
      onPlaceSelected(undefined, undefined, details.address_components[0].short_name)
    } else {
      const rect = !!geometry.bounds ? {
        southWest: {lat: geometry.bounds.getSouthWest().lat(), lng: geometry.bounds.getSouthWest().lng()},
        northEast: {lat: geometry.bounds.getNorthEast().lat(), lng: geometry.bounds.getNorthEast().lng()},
      } : null
      if(rect && isWideArea(rect)){
        onPlaceSelected(undefined, rect)
      } else {
        onPlaceSelected({center:{lat, lng}, radius: SEARCH_RADIUS})
      }
    }
  }

  return (
    <GooglePlacesAutocomplete
      onPlaceSelected={handleSelect}
      onReset={handleReset}
      showFullAddress
      placeholder="Search an address or city to find nearby datacenters..."
    />
  )
}

export default LocationSearchInput;
