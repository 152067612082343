import { DotSizesEnum } from '@/components/Dot'
import { TableDataInterface } from '@/lib/definitions/generic.types'
import { ReactElement } from 'react'

export interface ServiceItem extends TableDataInterface {
  id: string | number
  sid: any
  location: {
    name: string
    country: string
  }
  locations: {
    name: string
    country: string
  }[]
  name: {
    name: string
    id: string | number
  }
  url: string
  product: string
  project: string
  startDate: number
  state: {
    style: string
    size?: DotSizesEnum
    title?: string
  }
  virtualNetworkName: string
}

export interface ServiceComponentItem extends TableDataInterface {
  id: string | number
  sco: any
  name: string
  component_name: string
}

export interface TicketItem extends TableDataInterface {
  ticket_number: number
  subject: {
    ticket_number: number
    subject: string
  }
  service: string
  type: {
    style?: string
    label: string
  }
  status: {
    style?: string
    label: string
  }
  escalation: {
    style?: string
    label: string
  }
  priority: {
    style?: string
    label: string
  }
  createdOn: string
  lastUpdatedOn: string
}

export interface SimpleDataItem {
  label: string | ReactElement
  value?: string | number | ReactElement
  className?: string
  labelClassName?: string
  valueClassName?: string
}

export enum ProductIdentifiers {
  PORT = 'PID47',

  // Internet Connectivity:
  IP_TRANSIT = 'PID19',
  IP_ACCESS = 'PID20',
  LEGACY_IP_TRANSIT = 'PID10',
  LEGACY_IP_ACCESS = 'PID11',

  // Flex Ethernet:
  FLEX_ETHERNET_ENDPOINT = 'PID44',
  LAYER2 = 'PID22',
  LEGACY_FLEX_ETHERNET = 'PID12',

  // Firewall:
  FWAAS = 'PID45',

  // Extensions:
  EXTENSION = 'PID48',

  // Legacy Services:
  OFFICE_FIBER = 'PID21',
  DDOS_PROTECTION = 'PID24',
  CONSULTING = 'PID27',
  POP_AS_A_SERVICE = 'PID29',
  MANAGED_AS = 'PID30',
  IP_SALE_LEASE = 'PID46',
  SE_COLOCATION = 'PID13',
  SE_MANAGED_AS = 'PID14',
  SE_CLOUD_CONNECT = 'PID15',
  SE_DDOS_PROTECTION = 'PID18',
  SE_OFFICE_FIBER = 'PID28',
  EN_COLOCATION = 'PID31',
  EN_ETHERNET = 'PID32',
  EN_CROSS_CONNECT = 'PID35',
  EN_METRO_FIBRE = 'PID39',
  EN_INTERNET = 'PID38',
  EN_LH_WAVE = 'PID40',
}

export enum ProductComponentIdentifiers {
  SETUP = 'PCO1',
  BILLING_GROUP = 'PC04',
  BURST = 'PCO5',
  PORT = 'PCO6',
  VLAN = 'PCO7',
  PREFIX = 'PCO8',
  BGP = 'PCO9',
  LAYER2 = 'PCO17',
  E_LAN = 'PCO27',
  FIREWALL_RULES = 'PCO30',
  IP_BANDWIDTH = 'PCO31',
  IP_BANDWIDTH_BURST = 'PCO32',
  ROUTED_PREFIX = 'PCO33',
  LAG = 'PCO10',
}

export type StatusStyle = 'success' | 'neutral' | 'warning' | 'danger'
