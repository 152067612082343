import { ContractTerm } from '@/pages/ServiceWizard/ServiceWizard.types'
import { contractTerms } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import { classNames } from '@/lib/misc/Utils'

interface WizardContractTermProps {
  inline?: boolean
  contract?: number
  setContractTerm: (term: ContractTerm) => void
  minContractTerm?: number | undefined
}

// @TODO add animation to this component
const WizardContractTerm = ({
  inline = true,
  contract = contractTerms[0].period,
  setContractTerm,
  minContractTerm,
}: WizardContractTermProps) => (
  <div
    className={classNames(
      'flex justify-between flex-wrap',
      !inline && 'flex-col'
    )}
  >
    <h3 className="text-brand-4 tracking-tighter text-heading-byline mr-8 mb-1">
      Select contract term
    </h3>

    <div
      className={classNames(
        'gap-x-3 p-1 border-1 rounded-lg',
        inline
          ? 'flex w-fit border-accent-30'
          : 'grid grid-cols-4 w-full bg-white border-neutral-20 px-2.5 mt-4'
      )}
    >
      {contractTerms.filter((term) => !minContractTerm || minContractTerm <= term.period).map((term) => (
        <div
          key={term.label_short}
          data-cy={`wizard-contract-term-${term.label_short}`}
          onClick={() => setContractTerm(term)}
          className={classNames(
            'rounded-lg py-1 px-2 cursor-pointer text-center font-normal text-xs tracking-tightest uppercase hover:opacity-75 transition-opacity duration-300',
            inline ? 'bg-accent-60' : 'bg-accent-40 py-3',
            contract === term.period
              ? 'text-white'
              : 'text-accent-60 !bg-transparent'
          )}
        >
          {contract === term.period || !inline ? term.label : term.label_short}
        </div>
      ))}
    </div>
  </div>
)

export default WizardContractTerm
