import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'react-query'

import {
  CommittedDataRateOption,
  ELanEndpoint,
  PortSpeedOption,
  LAGProperties,
  ExistingPortItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import ELanBandwidth from '@/pages/elan/partials/ELanBandwidth'
import PortSpeeds from '@/pages/ServiceWizard/partials/PortSpeeds'
import { emptyMrcNrcValue, portSpeedOptions } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import LAGCreateForm from '@/pages/ServiceWizard/partials/LAGCreateForm'
import Button from '@/components/Button'
import {
  FlexEthernetWizardPricesResponse,
  PortTypes,
  ServiceComponentVLANType,
} from '@/lib/definitions/api.types'
import { ServiceWizardContractTerm } from '@/lib/definitions/constants'
import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { generateBandwidthsData } from '@/lib/misc/Utils'
import { FlexEthernetService } from '@/lib/services/FlexEthernetService'
import ChevronRightIcon from '@icons/chevron-right'
import Locations from '@/pages/ServiceWizard/partials/Locations'
import VlanSelection from '@/pages/ServiceWizard/partials/VlanSelection'

interface PortSelectionDialogProps {
  onClose: () => void
  reconfiguringEndpointIndex: number | null
}

enum EndpointSelectionStep {
  LOCATION = 1,
  PORT_SPEED = 2,
  SERVICE_SPEED = 3,
}

const initialValueForEndpoint: ELanEndpoint = {
  selectedLocation: '',
  selectedPortName: null,
  selectedPortType: '',
  selectedServiceSpeed: null,
  vlanId: null,
  vlanType: 'Tagged',
  selectedLocationSpeed: 0,
  selectedLocationType: 'General',
  selectedPortSpeed: undefined,
}

const flexEthernetService = new FlexEthernetService()

const PortSelectionDialog = ({
  onClose,
  reconfiguringEndpointIndex,
}: PortSelectionDialogProps) => {
  const { eLanWizardState, updateELanWizard } = useELanWizardState()
  const [endpointSelectionStep, setEndpointSelectionStep] =
    useState<EndpointSelectionStep>(EndpointSelectionStep.LOCATION)
  const [bandwidthItems, setBandwidthItems] = useState<
    CommittedDataRateOption[]
  >([])
  const [endpoint, setEndpoint] = useState<ELanEndpoint>(
    initialValueForEndpoint
  )
  const [existingPortId, setExistingPortId] = useState<number>()

  const isPortSpeedSetupCostShown = useMemo(() => {
    if (!eLanWizardState.portSpeedItems) return false

    return (
      eLanWizardState.portSpeedItems.filter(
        (item: any) => Number(item.nrc.amount) > 0
      ).length > 0
    )
  }, [eLanWizardState.portSpeedItems])

  const selectPortSpeed = (port: PortSpeedOption) => {
    setEndpoint((endpoint) => ({
      ...endpoint,
      selectedPortName: port.name as PortTypes,
      selectedPortSpeed: port,
      selectedPortType: port.type,
    }))
  }

  const setVlanId = (val: number) => {
    setEndpoint((endpoint) => ({
      ...endpoint,
      vlanId: val,
    }))
  }

  const setVlanType = (val: ServiceComponentVLANType) => {
    setEndpoint((endpoint) => ({
      ...endpoint,
      vlanType: val,
    }))
  }

  const setServiceSpeedSelection = (serviceSpeed: CommittedDataRateOption) => {
    setEndpoint((endpoint) => ({
      ...endpoint,
      selectedServiceSpeed: serviceSpeed.speedInMbps,
    }))
  }

  const setLagInfo = useCallback(
    (lag: LAGProperties | undefined) =>
      setEndpoint((endpoint) => ({
        ...endpoint,
        lag,
      })),
    [setEndpoint]
  )

  const nextStepHandler = () => {
    if (endpointSelectionStep === EndpointSelectionStep.SERVICE_SPEED) {
      if (reconfiguringEndpointIndex !== null) {
        const endpoints = eLanWizardState.endpoints
        endpoints[reconfiguringEndpointIndex] = endpoint
        updateELanWizard({ endpoints: [...endpoints] })
      } else {
        updateELanWizard({
          endpoints: [...eLanWizardState.endpoints, endpoint],
        })
      }

      onClose()
    } else if (
      endpointSelectionStep === EndpointSelectionStep.LOCATION &&
      !!existingPortId
    ) {
      setEndpointSelectionStep(EndpointSelectionStep.SERVICE_SPEED)
    } else {
      setEndpointSelectionStep((prev) => prev + 1)
    }
  }

  const prevStepHandler = () => {
    setEndpointSelectionStep((prev) => prev - 1)
  }

  const loadFlexEthernetWizardPrices = useCallback(() => {
    let portSpeed = endpoint.selectedPortSpeed?.speedInMbps ?? 0

    const lagMemberCount = endpoint.lag?.memberCount ?? 1
    if (lagMemberCount > 1) {
      portSpeed *= lagMemberCount
    }

    return flexEthernetService.getFlexEthernetWizardPrices({
      // @ts-ignore
      port_speed: portSpeed,
      location: endpoint.selectedLocation,
      term: eLanWizardState.contract || ServiceWizardContractTerm,
      currency: 'EUR',
    })
  }, [
    endpoint.selectedPortSpeed,
    endpoint.selectedLocation,
    eLanWizardState.contract,
    endpoint.lag,
  ])

  const { mutate: getEndpointPrices } = useMutation(
    loadFlexEthernetWizardPrices,
    {
      onSuccess: ({ data }: { data: FlexEthernetWizardPricesResponse }) => {
        const { ports, bandwidths } = data
        const portSpeedItems: PortSpeedOption[] = []
        const serviceSpeedItems: CommittedDataRateOption[] = []

        portSpeedOptions.forEach((portSpeed) => {
          const port = ports[portSpeed.speedInMbps]
          if (portSpeed.type === 'LR1' && !data.allow_LR1) {
            return
          }
          if (port) {
            portSpeedItems.push({ ...portSpeed, ...port })
          }
        })

        for (const [key, value] of Object.entries(bandwidths)) {
          serviceSpeedItems.push({
            ...value,
            ...generateBandwidthsData(Number(key)),
          })
        }

        setBandwidthItems(serviceSpeedItems)

        updateELanWizard({
          portSpeedItems,
        })
      },
    }
  )

  const onExistingPortHandler = useCallback(
    (port: ExistingPortItem) => {
      const selectedPortSpeed: PortSpeedOption = {
        name: port.portType,
        speedInMbps: port.portSpeed.speedInMbps,
        id: port.id,
        speed: port.portSpeed.speed,
        unit: port.portSpeed.unit,
        type: port.portSpeed.type,
        isLag: port.isLag,
        nrc: emptyMrcNrcValue,
        mrc: emptyMrcNrcValue,
      }

      setEndpoint({
        ...initialValueForEndpoint,
        selectedLocation: port.location.name,
        selectedPortName: port.portSpeed.name as PortTypes,
        existingPortId: port.id,
        selectedPortSpeed,

        ...(port.isLag && {
          lag: {
            memberCount: port.lagCount,
            name: port.name,
          },
        }),
      })

      setExistingPortId(port.id)
    },
    []
  )

  useEffect(() => {
    if (
      reconfiguringEndpointIndex !== null &&
      eLanWizardState.endpoints[reconfiguringEndpointIndex]
    ) {
      const endpoint = eLanWizardState.endpoints[reconfiguringEndpointIndex]
      setEndpoint(endpoint)
      setExistingPortId(endpoint.existingPortId)
      setEndpointSelectionStep(EndpointSelectionStep.SERVICE_SPEED)
    }
  }, [reconfiguringEndpointIndex, eLanWizardState.endpoints])

  useEffect(() => {
    if (!!endpoint.selectedLocation) {
      getEndpointPrices()
    }
  }, [
    eLanWizardState.contract,
    endpoint.selectedLocation,
    getEndpointPrices,
    endpoint.lag,
    endpoint.selectedPortSpeed,
  ])

  const selectedPortSpeedSpeed = useMemo(() => {
    const portSpeedItems = portSpeedOptions.find(
      (item) => item.name === endpoint.selectedPortName
    )

    return portSpeedItems?.speed
  }, [endpoint])

  const availablePortSpeedItems = useMemo(() => {
    if (!eLanWizardState.portSpeedItems) return []
    if (!endpoint.selectedLocationSpeed) return eLanWizardState.portSpeedItems

    return eLanWizardState.portSpeedItems.filter(
      (speed: PortSpeedOption) =>
        speed.speedInMbps <= endpoint.selectedLocationSpeed
    )
  }, [eLanWizardState.portSpeedItems, endpoint.selectedLocationSpeed])

  const isPrimaryButtonDisabled = useMemo(() => {
    switch (endpointSelectionStep) {
      case EndpointSelectionStep.LOCATION:
        return !endpoint.selectedLocation
      case EndpointSelectionStep.PORT_SPEED:
        return (
          !endpoint.selectedPortName ||
          (endpoint.lag && (!endpoint.lag.memberCount || !endpoint.lag.name))
        )
      case EndpointSelectionStep.SERVICE_SPEED:
        return (
          !endpoint.selectedServiceSpeed ||
          (endpoint.vlanType === 'Tagged' && !endpoint.vlanId)
        )
      default:
        return false
    }
  }, [endpoint, endpointSelectionStep])

  const primaryButtonText = useMemo(() => {
    return endpointSelectionStep === EndpointSelectionStep.SERVICE_SPEED
      ? 'Confirm'
      : 'Next Step'
  }, [endpointSelectionStep])

  return (
    <div className="h-full flex flex-col">
      <div className="overflow-y-scroll flex-1 px-4 md:px-12 pb-3">
        {endpointSelectionStep === EndpointSelectionStep.LOCATION && (
          <Locations
            title="Find your desired location"
            selectedLocation={endpoint.selectedLocationId}
            selectedExistingPort={existingPortId}
            existingPorts={eLanWizardState.existingPorts}
            setSelectedLocation={(location) => {
              setExistingPortId(undefined)
              setEndpoint((endpoint) => ({
                ...endpoint,
                selectedLocation: location.name,
                selectedLocationId: location.id,
                selectedLocationSpeed: location.bandwidth,
                selectedLocationType: location.type,
              }))
              if (eLanWizardState?.locations) {
                updateELanWizard({
                  locations: [...eLanWizardState.locations, location],
                })
              }
            }}
            setExistingPort={onExistingPortHandler}
          />
        )}

        {endpointSelectionStep === EndpointSelectionStep.PORT_SPEED && (
          <>
            <PortSpeeds
              portsClassName="flex-col md:flex-row gap-x-0 md:gap-x-2 gap-y-2 md:gap-y-0 mb-5"
              speeds={availablePortSpeedItems}
              selectPortSpeed={selectPortSpeed}
              selectedPortSpeed={endpoint.selectedPortSpeed}
              isSetupCostShown={isPortSpeedSetupCostShown}
              isExtension={endpoint.selectedLocationType === 'Extension'}
            />

            {endpoint.selectedLocationType === 'General' && (
              <LAGCreateForm values={endpoint.lag} setLagInfo={setLagInfo} />
            )}
          </>
        )}

        {endpointSelectionStep === EndpointSelectionStep.SERVICE_SPEED && (
          <div>
            <div className="text-heading-byline tracking-tightest text-brand-1 mt-0 md:mt-6">
              Select Endpoint Bandwidth
            </div>

            <div className="flex gap-x-2 mt-3">
              {(selectedPortSpeedSpeed || existingPortId) && (
                <ELanBandwidth
                  selectedPortSpeed={selectedPortSpeedSpeed}
                  selectedServiceSpeed={endpoint.selectedServiceSpeed!}
                  bandwidthItems={bandwidthItems}
                  onSelect={setServiceSpeedSelection}
                  existingPortId={existingPortId}
                  lag={endpoint.lag}
                />
              )}
            </div>

            <div className="mt-8">
              <VlanSelection
                onIdChange={(id: number) => setVlanId(id)}
                onTypeChange={(type: ServiceComponentVLANType) =>
                  setVlanType(type)
                }
                vlanId={endpoint.vlanId}
                vlanType={endpoint.vlanType}
              />
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-row justify-end pt-5 px-4 md:px-24">
        {endpointSelectionStep !== EndpointSelectionStep.LOCATION &&
          !existingPortId && (
            <Button
              style="secondary"
              className="mr-4 text-accent-60"
              onClick={prevStepHandler}
              cy="eLan-port-selection-back"
            >
              Previous Step
            </Button>
          )}
        {endpointSelectionStep === EndpointSelectionStep.LOCATION && (
          <Button
            style="secondary"
            className="mr-4 text-accent-60"
            onClick={onClose}
            cy="eLan-port-selection-back"
          >
            Cancel
          </Button>
        )}

        <Button
          style="primary"
          onClick={nextStepHandler}
          cy="eLan-port-selection-confirm"
          iconSuffix={<ChevronRightIcon />}
          disabled={isPrimaryButtonDisabled}
        >
          {primaryButtonText}
        </Button>
      </div>
    </div>
  )
}

export default PortSelectionDialog
