import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import ServiceWizard from '@/pages/ServiceWizard/ServiceWizard'
import {
  ContractTerm,
  ELanEndpoint,
  WizardTabItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import TabELanPorts from '@/pages/elan/TabELanPorts'
import ELanWizardSummary from '@/pages/elan/partials/ELanWizardSummary'

import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { Notify } from '@/lib/misc/Notify'
import {
  notifyExtensionsTermChange,
  parseResponseErrorsAndNotify,
  scrollToTop,
} from '@/lib/misc/Utils'
import { FlexEthernetService } from '@/lib/services/FlexEthernetService'
import { ServiceService } from '@/lib/services/ServiceService'
import { useLoadServiceComponents } from '@/lib/queries/Services.queries'
import {
  ElanEndpoint,
  UserNetworkInterface,
  UserNetworkInterfaceOnExistingPort,
} from '@/lib/definitions/api.types'
import { ProductComponentIdentifiers } from '@/lib/definitions/types'
import { portSpeedOptions } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import { processExistingPorts } from '@/pages/ServiceWizard/PortsConversion'

const tabs: WizardTabItem[] = [
  {
    id: 'ELan',
    label: 'Flex Ethernet',
    headline: 'Configure Your Flex Ethernet',
  },
  { id: 'Summary', label: 'Summary' },
]

export enum ELanTabSteps {
  ELan = 1,
  Summary = 2,
}

export type ELanStep = keyof typeof ELanTabSteps

const flexEthernetService = new FlexEthernetService()
const servicesService = new ServiceService()

const ELan = () => {
  const navigate = useNavigate()
  const { eLanWizardState, updateELanWizard, resetFlexEthernetWizard } =
    useELanWizardState()

  const wrapperRef = useRef<null | HTMLDivElement>(null)
  const [isPreviousStepDisabled, setIsPreviousStepDisabled] = useState(true)
  const [isPreviousHidden, setIsPreviousHidden] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [isUsingExtensions, setIsUsingExtensions] = useState(false)

  const {
    activeTab,
    activeTabIndex,
  }: { activeTab: ELanStep; activeTabIndex: number } = useMemo(() => {
    const currentTab =
      eLanWizardState?.activeTab ?? (ELanTabSteps[1] as ELanStep)

    return {
      activeTab: currentTab,
      activeTabIndex: Number(ELanTabSteps[currentTab]),
    }
  }, [eLanWizardState])

  const startOrder = () => {
    const ports: Array<
      UserNetworkInterface | UserNetworkInterfaceOnExistingPort
    > = []

    eLanWizardState.endpoints.forEach((endpoints: ELanEndpoint) => {
      const selectedLocation = eLanWizardState?.locations?.find(
        (loc) => loc.name === endpoints.selectedLocation
      )

      if (selectedLocation) {
        const existingPort = endpoints.existingPortId
        const base = {
          vlan_type: endpoints.vlanType,
          bandwidth: endpoints.selectedServiceSpeed!,

          ...(endpoints.vlanType === 'Tagged' && {
            vlan_id: Number(endpoints.vlanId),
          }),
        }

        const port = existingPort
          ? {
              ...base,
              id: existingPort,
            }
          : {
              ...base,
              location: selectedLocation.name,
              port_type: endpoints.selectedPortName,
              ...(selectedLocation.type === 'General' && {
                lag_member_count: endpoints.lag?.memberCount,
                lag_name: endpoints.lag?.name,
              }),
            }

        // @ts-ignore
        ports.push(port)
      }
    })

    return flexEthernetService.createFlexEthernetOrder({
      term: eLanWizardState.contract,
      purchase_reference: eLanWizardState.purchaseReference,
      ...(eLanWizardState.virtualNetworkName && {
        virtual_network_name: eLanWizardState.virtualNetworkName.trim(),
      }),
      // @ts-ignore
      ports,
    })
  }

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation(
    startOrder,
    {
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(
          error,
          'An internal error occurred while placing your order'
        )
        captureException(error)
      },
      onSuccess: async ({ data: servicesData }: { data: ElanEndpoint[] }) => {
        if (servicesData) {
          Notify.success('Your order has been placed successfully')

          resetFlexEthernetWizard()

          setTimeout(
            () => navigate(`/services/flex-ethernet/${servicesData[0].id}`),
            1200
          )
        }
      },
    }
  )

  const handleTabChange = (newTabIndex: number) => {
    const newActiveTab: ELanStep = ELanTabSteps[newTabIndex] as ELanStep
    if (newActiveTab) updateELanWizard({ activeTab: newActiveTab })
  }

  const handleNextStep = async () => {
    const stepIndex: number = ELanTabSteps[activeTab]
    if (stepIndex === tabs.length && eLanWizardState.virtualNetworkName) {
      placeOrder()
      return
    }

    handleTabChange(stepIndex + 1)
    scrollToTop(wrapperRef)
  }

  const handlePreviousStep = () => {
    const stepIndex = ELanTabSteps[activeTab]

    if (stepIndex > 1) {
      handleTabChange(stepIndex - 1)

      scrollToTop(wrapperRef)
    }
  }

  const setContractTerm = (term: ContractTerm) =>
    updateELanWizard({ contract: term.period })

  const { data: customerExistingPorts } = useLoadServiceComponents({
    pco: [
      ProductComponentIdentifiers.PORT,
      // ProductComponentIdentifiers.LAG, // disabled for now. API is not ready
    ],
  })

  useQuery(
    ['locations'],
    async () => {
      const response = await servicesService.getLocations()
      if (!response) return null

      updateELanWizard({ locations: response.data })

      return response
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(error)
        captureException(error)
      },
    }
  )

  useEffect(() => resetFlexEthernetWizard(), [resetFlexEthernetWizard])

  useEffect(() => {
    const stepIndex: number = ELanTabSteps[activeTab]

    setIsNextDisabled(
      isPlacingOrder ||
        (ELanTabSteps[activeTab] === ELanTabSteps.ELan &&
          (eLanWizardState?.endpoints.length < 1 ||
            !eLanWizardState?.virtualNetworkName))
    )
    setIsPreviousStepDisabled(stepIndex === 1)
    setIsPreviousHidden(stepIndex === 1)
  }, [activeTab, eLanWizardState, isPlacingOrder])

  useEffect(() => {
    const isExt = !!eLanWizardState.endpoints.filter(
      (e) => e.selectedLocationType === 'Extension'
    ).length
    setIsUsingExtensions(isExt)
    if (eLanWizardState.contract == 1 && isExt) {
      notifyExtensionsTermChange()
      updateELanWizard({ contract: 12 })
    }
  }, [
    eLanWizardState.endpoints.length,
    eLanWizardState.contract,
    eLanWizardState.endpoints,
    updateELanWizard,
  ])

  useEffect(() => {
    const existingPorts = processExistingPorts(
      // @ts-ignore
      customerExistingPorts,
      portSpeedOptions
    )
    updateELanWizard({ existingPorts })
  }, [updateELanWizard, customerExistingPorts])

  if (!eLanWizardState) return <div />

  return (
    <ServiceWizard
      pageTitle="Add Flex Ethernet Service"
      isSummaryStep={ELanTabSteps[activeTab] === ELanTabSteps.Summary}
      contract={eLanWizardState.contract}
      wrapperRef={wrapperRef}
      tabs={tabs}
      activeTabIndex={activeTabIndex}
      handlePreviousStep={handlePreviousStep}
      handleNextStep={handleNextStep}
      isPreviousStepDisabled={isPreviousStepDisabled}
      isNextDisabled={isNextDisabled}
      isPreviousHidden={isPreviousHidden}
      setContractTerm={setContractTerm}
      summarySection={<ELanWizardSummary />}
      nextButtonLabel="Go to checkout"
      backButtonLabel="Edit Flex Ethernet"
      isUsingExtensions={isUsingExtensions}
    >
      {ELanTabSteps[activeTab] === ELanTabSteps.ELan && <TabELanPorts />}
    </ServiceWizard>
  )
}

export default ELan
