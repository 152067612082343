import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import {
  ConfigureFirewallType,
  FirewallCreationSteps,
  FirewallPrices,
  AmountType,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import {
  ElanEndpoint,
  IPTransit,
  Location,
  RulesetMaxLines,
  Service,
} from '@/lib/definitions/api.types'
import { IpTransitWizardState } from '@/lib/hooks/useIpTransitWizardState'
import { ELanWizardState } from '@/lib/hooks/useELanWizardState'
import { IpAccessWizardState } from '@/lib/hooks/useIpAccessWizardState'
import { DDoSProtectionWizardState } from '@/lib/hooks/useDDoSProtectionWizardState'
import { ServiceWizardContractTerm } from '@/lib/definitions/constants'
import { IpAccessTabSteps } from '@/pages/ip-access/IpAccess'
import { IpTransitStep, IpTransitTabSteps } from '@/pages/ip-transit/IpTransit'
import { ELanStep, ELanTabSteps } from '@/pages/elan/ELan'
import {
  DDoSProtectionStep,
  DDoSProtectionTabSteps,
} from '@/pages/ddos-protection/DDoSProtection'
import { defaultDDoSPrefixesList } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'

export const PREV_STEP = 'PREV_STEP'
export const NEXT_STEP = 'NEXT_STEP'
export const SET_STEP = 'SET_STEP'
export const SET_ELANS = 'SET_ELANS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_AMOUNT = 'SET_AMOUNT'
export const REDIRECT_TO_WIZARD = 'REDIRECT_TO_WIZARD'
export const SET_CONFIGURE_FIREWALL = 'SET_CONFIGURE_FIREWALL'
export const SET_PRICES = 'SET_PRICES'
export const IP_TRANSIT_WIZARD = 'IP_TRANSIT_WIZARD'
export const IP_ACCESS_WIZARD = 'IP_ACCESS_WIZARD'
export const FLEX_ETHERNET_WIZARD = 'FLEX_ETHERNET_WIZARD'
export const DDOS_PROTECTION_WIZARD = 'DDOS_PROTECTION_WIZARD'
export const NEW_SERVICE_ORDER = 'NEW_SERVICE_ORDER'
export const SET_IP_ADDRESS = 'SET_IP_ADDRESS'
export const SET_RULESET_SIZES = 'SET_RULESET_SIZES'
export const RESET_CONTEXT = 'RESET_CONTEXT'
export const RESET_IP_ACCESS = 'RESET_IP_ACCESS'
export const RESET_IP_TRANSIT = 'RESET_IP_TRANSIT'
export const RESET_FLEX_ETHERNET = 'RESET_FLEX_ETHERNET'
export const RESET_DDOS_PROTECTION = 'RESET_DDOS_PROTECTION'

export type ServiceWizardState = {
  step: FirewallCreationSteps
  selectedElans: Record<number, Service | ElanEndpoint>
  location?: Location
  amount: AmountType
  redirectToWizard?: string
  configureFirewall: ConfigureFirewallType
  prices: FirewallPrices[]
  ipTransitWizard: IpTransitWizardState
  ipAccessWizard: IpAccessWizardState
  flexEthernetWizard: Partial<ELanWizardState>
  ddosProtectionWizard: Partial<DDoSProtectionWizardState>
  newServiceOrder?: IPTransit
  ipAddresses: Record<number, string>
  rulesetSizes: RulesetMaxLines
}

const initialState: ServiceWizardState = {
  step: FirewallCreationSteps.ConnectElan,
  selectedElans: {},
  location: undefined,
  prices: [],
  amount: {
    totalAmount: null,
    bandwidthAmount: null,
  },
  redirectToWizard: undefined,
  configureFirewall: {
    name: '',
    term: '',
    rule_set: '',
    bandwidth: '',
    reference: '',
  },
  ipTransitWizard: {
    contract: 24,
    activeTab: IpTransitTabSteps[IpTransitTabSteps.Location] as IpTransitStep,
    vlanType: 'Tagged',
  },
  ipAccessWizard: {
    contract: ServiceWizardContractTerm,
    activeTab: IpAccessTabSteps.Location,
    serviceSpeedType: 'committed',
    vlanType: 'Tagged',
  },
  flexEthernetWizard: {
    contract: ServiceWizardContractTerm,
    activeTab: ELanTabSteps[ELanTabSteps.ELan] as ELanStep,
    endpoints: [],
    quotes: [],
  },
  ddosProtectionWizard: {
    prefixes: defaultDDoSPrefixesList,
    activeTab: DDoSProtectionTabSteps[
      DDoSProtectionTabSteps.DDoSProtection
    ] as DDoSProtectionStep,
  },
  ipAddresses: {},
  rulesetSizes: {
    small: 0,
    medium: 0,
    large: 0,
  },
}

export type Action =
  | { type: typeof PREV_STEP }
  | { type: typeof NEXT_STEP }
  | { type: typeof SET_STEP; payload: FirewallCreationSteps }
  | { type: typeof SET_ELANS; payload: Record<number, Service | ElanEndpoint> }
  | { type: typeof SET_LOCATION; payload: Location }
  | { type: typeof SET_AMOUNT; payload: AmountType }
  | {
      type: typeof REDIRECT_TO_WIZARD
      payload?: string
    }
  | {
      type: typeof SET_CONFIGURE_FIREWALL
      payload: Partial<ConfigureFirewallType>
    }
  | {
      type: typeof SET_PRICES
      payload: FirewallPrices[]
    }
  | {
      type: typeof IP_TRANSIT_WIZARD
      payload: IpTransitWizardState
    }
  | {
      type: typeof IP_ACCESS_WIZARD
      payload: IpAccessWizardState
    }
  | {
      type: typeof FLEX_ETHERNET_WIZARD
      payload: Partial<ELanWizardState>
    }
  | {
      type: typeof DDOS_PROTECTION_WIZARD
      payload: Partial<DDoSProtectionWizardState>
    }
  | {
      type: typeof NEW_SERVICE_ORDER
      payload?: IPTransit
    }
  | {
      type: typeof SET_IP_ADDRESS
      payload?: Record<number, string>
    }
  | {
      type: typeof SET_RULESET_SIZES
      payload: RulesetMaxLines
    }
  | {
      type: typeof RESET_CONTEXT
    }
  | {
      type: typeof RESET_IP_ACCESS
    }
  | {
      type: typeof RESET_IP_TRANSIT
    }
  | {
      type: typeof RESET_FLEX_ETHERNET
    }
  | {
      type: typeof RESET_DDOS_PROTECTION
    }

const reducer = (
  state: ServiceWizardState,
  action: Action
): ServiceWizardState => {
  switch (action.type) {
    case PREV_STEP:
      return { ...state, step: state.step - 1 }
    case NEXT_STEP:
      return { ...state, step: state.step + 1 }
    case SET_STEP:
      return { ...state, step: action.payload }
    case SET_ELANS:
      return {
        ...state,
        selectedElans: action.payload,
      }
    case SET_LOCATION:
      return { ...state, location: action.payload }
    case SET_AMOUNT:
      return {
        ...state,
        amount: { ...state.amount, ...action.payload },
      }
    case REDIRECT_TO_WIZARD:
      return {
        ...state,
        redirectToWizard: action.payload,
      }
    case SET_CONFIGURE_FIREWALL:
      return {
        ...state,
        configureFirewall: { ...state.configureFirewall, ...action.payload },
      }
    case SET_PRICES:
      return {
        ...state,
        prices: action.payload,
      }
    case IP_TRANSIT_WIZARD:
      return {
        ...state,
        ipTransitWizard: {
          ...state.ipTransitWizard,
          ...action.payload,
        },
      }
    case IP_ACCESS_WIZARD:
      return {
        ...state,
        ipAccessWizard: {
          ...state.ipAccessWizard,
          ...action.payload,
        },
      }
    case FLEX_ETHERNET_WIZARD:
      return {
        ...state,
        flexEthernetWizard: {
          ...state.flexEthernetWizard,
          ...action.payload,
        },
      }
    case DDOS_PROTECTION_WIZARD:
      return {
        ...state,
        ddosProtectionWizard: {
          ...state.ddosProtectionWizard,
          ...action.payload,
        },
      }
    case NEW_SERVICE_ORDER:
      return {
        ...state,
        newServiceOrder: action.payload,
      }
    case SET_IP_ADDRESS:
      return {
        ...state,
        ipAddresses: { ...state.ipAddresses, ...action.payload },
      }
    case RESET_IP_ACCESS:
      return {
        ...state,
        ipAccessWizard: initialState.ipAccessWizard,
      }
    case RESET_IP_TRANSIT:
      return {
        ...state,
        ipTransitWizard: {
          ...initialState.ipTransitWizard,
          selectedPortSpeed: undefined,
        },
      }
    case RESET_FLEX_ETHERNET:
      return {
        ...state,
        flexEthernetWizard: initialState.flexEthernetWizard,
      }
    case RESET_DDOS_PROTECTION:
      return {
        ...state,
        ddosProtectionWizard: initialState.ddosProtectionWizard,
      }
    case SET_RULESET_SIZES:
      return {
        ...state,
        rulesetSizes: action.payload,
      }
    case RESET_CONTEXT:
      return initialState
    default:
      return state
  }
}

interface ServiceWizardContextType {
  state: ServiceWizardState
  dispatch: React.Dispatch<Action>
}

const ServiceWizardContext = createContext<
  ServiceWizardContextType | undefined
>(undefined)

interface ServiceWizardProviderProps {
  children: ReactNode
}

export const ServiceWizardProvider: React.FC<ServiceWizardProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const ServiceWizardContextValue = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  )

  return (
    <ServiceWizardContext.Provider value={ServiceWizardContextValue}>
      {children}
    </ServiceWizardContext.Provider>
  )
}

export const useServiceWizard = (): ServiceWizardContextType => {
  const context = useContext(ServiceWizardContext)

  if (!context) {
    throw new Error(
      'useServiceWizard must be used within a ServiceWizardProvider'
    )
  }
  return context
}
