import { FC, memo } from 'react'

import useIpAccessWizardState from '@/lib/hooks/useIpAccessWizardState'
import { getAggregatedSpeedPrice, price } from '@/lib/misc/Utils'
import { BillingType } from '@/lib/definitions/api.types'
import { formatContractTerm } from '@/lib/misc/Utils'

const title: Record<BillingType, string> = {
  flat: 'Bandwidth',
  committed: 'Committed Data Rate',
}

const IpAccessSummaryPricing: FC = () => {
  const { ipAccessWizardState } = useIpAccessWizardState()
  const {
    quote,
    contract,
    extensionPrices,
    selectedPortSpeed,
    serviceSpeedType = 'committed',
    lag,
    hasAggregatedBilling,
    aggregatedCommitTotals,
    selectedServiceSpeed
  } = ipAccessWizardState

  let totalMrc = Number(quote?.total?.mrc?.amount)
  let bandwidthMrc = Number(quote?.bandwidth?.mrc?.amount)

  if (hasAggregatedBilling && !!aggregatedCommitTotals) {
    const aggregatedSpeedPrice = getAggregatedSpeedPrice(selectedServiceSpeed?.speedInMbps || 0, aggregatedCommitTotals)
    const bandwidthPriceDelta = bandwidthMrc - aggregatedSpeedPrice
    bandwidthMrc -= bandwidthPriceDelta
    totalMrc -= bandwidthPriceDelta
  }

  return (
    <div className="flex flex-col justify-between p-10 text-white">
      <h3 className="text-2xl font-bold leading-[normal]">
        Payment Information
      </h3>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <h4 className="text-2xl font-normal leading-[normal]">Contract</h4>
          <div className="flex items-center justify-between">
            <div>Contract term</div>
            <div>{!!contract ? formatContractTerm(contract) : '-'}</div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h4 className="text-2xl font-normal leading-[normal]">
            One-time setup fee
          </h4>
          {!!quote?.port?.nrc?.amount && (
            <div className="flex items-center justify-between">
              <div>Port</div>
              <div>{price(Number(quote?.port?.nrc?.amount))}</div>
            </div>
          )}
          {!!quote?.lag?.nrc?.amount && (
            <>
              <div className="flex items-center justify-between">
                <div>Port Count</div>
                <div>{lag?.memberCount}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>Port Total</div>
                <div>{price(Number(quote?.lag?.nrc?.amount))}</div>
              </div>
            </>
          )}
          {extensionPrices?.bandwidths && selectedPortSpeed && (
            <div className="flex items-center justify-between">
              <div>Extension</div>
              <div>
                {price(
                  Number(
                    extensionPrices?.bandwidths[selectedPortSpeed.speedInMbps]
                      ?.nrc?.amount
                  )
                )}
              </div>
            </div>
          )}
          {!!quote?.total?.nrc?.amount && (
            <div className="flex items-center justify-between text-neutral-100 font-semibold">
              <div>Total</div>
              <div>{price(Number(quote?.total?.nrc?.amount))}</div>
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4">
          <h4 className="text-2xl font-normal leading-[normal]">
            Recurring monthly fee
          </h4>
          <div className="flex items-center justify-between">
            <div>{title[serviceSpeedType]}</div>
            <div>{price(bandwidthMrc)}</div>
          </div>
          {!!quote?.port?.mrc?.amount && (
            <div className="flex items-center justify-between">
              <div>Port</div>
              <div>{price(Number(quote?.port?.mrc?.amount))}</div>
            </div>
          )}
          {!!quote?.lag?.mrc?.amount && (
            <>
              <div className="flex items-center justify-between">
                <div>Port Count</div>
                <div>{lag?.memberCount}</div>
              </div>
              <div className="flex items-center justify-between">
                <div>Port Total</div>
                <div>{price(Number(quote?.lag?.mrc?.amount))}</div>
              </div>
            </>
          )}
          {!!quote?.prefix_v4?.mrc?.amount && (
            <div className="flex items-center justify-between">
              <div>Prefix IPv4</div>
              <div>{price(Number(quote?.prefix_v4?.mrc?.amount))}</div>
            </div>
          )}
          {extensionPrices?.bandwidths && selectedPortSpeed && (
            <div className="flex items-center justify-between">
              <div>Extension</div>
              <div>
                {price(
                  Number(
                    extensionPrices?.bandwidths[selectedPortSpeed.speedInMbps]
                      ?.mrc?.amount
                  )
                )}
              </div>
            </div>
          )}
          {!!quote?.total?.mrc?.amount && (
            <div className="flex items-center justify-between text-neutral-100 font-semibold">
              <div>Total</div>
              <div>{price(totalMrc)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(IpAccessSummaryPricing)
