import { RefObject, useEffect } from 'react'

type EventListener = (event: MouseEvent | TouchEvent) => void

export const useOnClickOutside = (
  ref: RefObject<HTMLElement | null>,
  handler: EventListener
): void => {
  useEffect(() => {
    const listener: EventListener = (event) => {
      const element = ref.current
      if (!element || element.contains(event.target as Node)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
