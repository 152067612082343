import axios, { AxiosPromise } from 'axios'

import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'
import {
  IPTransit,
  IPTransitPriceRequest,
  IPTransitPriceResponse,
  IPTransitWizardPricesRequest,
  IPTransitWizardPricesResponse,
  IPTransitCreate,
} from '@/lib/definitions/api.types'

export class IpTransitService {
  getIpTransitWizardPrices(
    data: IPTransitWizardPricesRequest
  ): AxiosPromise<IPTransitWizardPricesResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/ip-transit/wizard'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  requestPriceSummary(
    data: IPTransitPriceRequest
  ): AxiosPromise<IPTransitPriceResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/ip-transit'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  createIpTransitOrder(data: IPTransitCreate): AxiosPromise<IPTransit> {
    return axios.post(
      apiUrl('/api/v1/ip-transit/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }
}
