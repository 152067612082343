import { useMemo } from 'react'

import LocationsMap from '@/components/LocationsMap'
import useELanWizardState from '@/lib/hooks/useELanWizardState'
import SummaryFancyBox from '@/pages/ServiceWizard/partials/SummaryFancyBox'
import { classNames } from '@/lib/misc/Utils'

interface LocationSummaryInterface {
  locationName: string
  cityName: string
  count: number
}

const SummaryLocation = ({ isExtended }: { isExtended: boolean }) => {
  const { eLanWizardState } = useELanWizardState()

  const selectedLocations = useMemo(
    () =>
      eLanWizardState.endpoints.map((endpoint) => endpoint.selectedLocation),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eLanWizardState.endpoints.length]
  )

  const endpointsWithCount: LocationSummaryInterface[] = useMemo(() => {
    if (eLanWizardState.locations && eLanWizardState.endpoints.length) {
      const citiesWithCount: {
        [key: string]: LocationSummaryInterface
      } = {}

      eLanWizardState.endpoints.forEach((endpoint) => {
        const location = eLanWizardState.locations?.find(
          (loc) => loc.name === endpoint.selectedLocation
        )

        if (location) {
          if (!citiesWithCount[endpoint.selectedLocation]) {
            citiesWithCount[endpoint.selectedLocation] = {
              locationName: endpoint.selectedLocation,
              cityName: location.city.name,
              count: 1,
            }
          } else {
            citiesWithCount[endpoint.selectedLocation].count++
          }
        }
      })

      return Object.values(citiesWithCount)
    }

    return []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eLanWizardState.locations, eLanWizardState.endpoints.length])

  return (
    <div
      className={classNames(
        isExtended
          ? 'grid-cols-1 md:grid md:grid-cols-2 gap-x-8 pt-5 pb-9 px-8'
          : 'flex flex-col'
      )}
    >
      <div className="flex flex-col">
        <h3 className="text-white text-heading-byline mb-2">Locations</h3>

        {endpointsWithCount.map((endpoint, index) => (
          <SummaryFancyBox key={index} wrapperClassName="mb-2">
            <div className="text-text-link mr-2">{endpoint.cityName}</div>

            <div className="text-neutral-40">{endpoint.count} endpoints</div>
          </SummaryFancyBox>
        ))}
      </div>

      <SummaryFancyBox className="!p-0" wrapperClassName="my-4 shadow-1">
        <LocationsMap
          selectedLocations={selectedLocations}
          locations={eLanWizardState.locations}
        />
      </SummaryFancyBox>
    </div>
  )
}

export default SummaryLocation
