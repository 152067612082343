import axios, { AxiosPromise } from 'axios'

import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'
import {
  DDOSPriceRequest,
  DDOSPriceResponse,
} from '@/lib/definitions/api.types'

export class DDosProtectionService {
  requestPriceSummary(data: DDOSPriceRequest): AxiosPromise<DDOSPriceResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/ddos'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  /* createDDoSOrder(data: DdosProtectionCreate): AxiosPromise<DdosProtection> {
    return axios.post(
      apiUrl('/api/v1/ddos/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  } */
}
