import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import FullWidthLayout from '@/layouts/FullWidthLayout'

import ChevronRightIcon from '@icons/chevron-right'

const NoCustomerFound = () => {
  const navigate = useNavigate()

  return (
    <FullWidthLayout className="text-center items-center justify-center">
      <h2 className="mb-2 text-heading-2 text-brand-1">Welcome!</h2>
      <p className="mb-2 max-w-sm text-lg text-zinc-500">
        We are glad, that you’re with us. Now to get started, just create a
        company first.
      </p>
      <Button
        onClick={() => {
          localStorage.setItem('redirect', location.pathname)
          navigate('/settings/create-customer')
        }}
        style={'primary'}
        className={'block mx-auto mt-4'}
        iconSuffix={<ChevronRightIcon />}
      >
        Setup Company Profile
      </Button>
    </FullWidthLayout>
  )
}

export default NoCustomerFound
