import { ReactNode } from 'react'

import Footer from '@/layouts/partials/Footer'
import { classNames } from '@/lib/misc/Utils'

interface FullWidthLayoutProps {
  children: ReactNode
  className?: string
}

const FullWidthLayout = ({ children, className }: FullWidthLayoutProps) => {
  return (
    <section className="text-gray-600 body-font flex flex-col h-full relative">
      <div
        className={classNames(
          'flex flex-col flex-1 px-4 mx-2 mb-12 md:mx-12 pt-8',
          className
        )}
      >
        {children}
      </div>

      <Footer className="px-6 md:px-16 py-6" />
    </section>
  )
}

export default FullWidthLayout
