import { useMemo } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Line,
  Marker,
  ProjectionConfig,
} from 'react-simple-maps'

import { Location } from '@/lib/definitions/api.types'
import WorldMap from '@/lib/misc/WorldMap'
import { classNames } from '@/lib/misc/Utils'

interface LocationsMapProps {
  selectedLocations?: string[]
  className?: string
  forFirewall?: boolean
  locations?: Location[]
}

interface MapLine {
  from: [number, number]
  to: [number, number]
}

const projectionConfig: ProjectionConfig = {
  // center: [12, 39], // Europe Center
  // scale: 600,       // Europe Scale
  center: [0, 36],
  scale: 160,
}

const LocationsMap = ({
  selectedLocations,
  className,
  forFirewall,
  locations,
}: LocationsMapProps) => {
  const selectedCities = useMemo(() => {
    const citiesList: Location[] = []

    if (locations) {
      const cities: string[] =
        selectedLocations || locations.map((location) => location.name)

      cities.forEach((cityName) => {
        const city = locations.find((location) => location.name === cityName)
        if (city) citiesList.push(city)
      })
    }

    return citiesList.filter((city) => !!city.latitude && !!city.longitude)
  }, [selectedLocations, locations])

  const mapLines = useMemo(() => {
    const connections: MapLine[] = []

    if (selectedCities.length >= 2) {
      selectedCities.forEach((city, cityIndex) => {
        const nextCity: Location =
          cityIndex === selectedCities.length - 1
            ? selectedCities[0]
            : selectedCities[cityIndex + 1]

        if (
          city.longitude &&
          city.latitude &&
          nextCity.longitude &&
          nextCity.latitude
        ) {
          connections.push({
            from: [city.longitude, city.latitude],
            to: [nextCity.longitude, nextCity.latitude],
          })
        }
      })
    }

    if (selectedCities.length) {
      const firstCity = selectedCities.at(0)

      if (firstCity && firstCity.longitude && firstCity.latitude) {
        projectionConfig.center = [firstCity.longitude, firstCity.latitude]
        projectionConfig.scale = 1000
      }
    }

    return connections
  }, [selectedCities])

  return (
    <div
      id="locations-map"
      className={classNames(
        '!w-full relative rounded-lg overflow-hidden',
        forFirewall
          ? 'flex justify-center first-letter:first-line:marker:h-full'
          : 'max-h-60',
        className
      )}
    >
      <img
        src={
          forFirewall && selectedCities?.length > 0
            ? '/images/maps-firewall-overlay.svg'
            : '/images/maps-shades.svg'
        }
        className="w-full z-30"
      />
      <ComposableMap
        className={classNames(
          'absolute top-0 left-0 h-full',
          forFirewall ? 'w-[calc(100%-4px)] p-4' : 'w-full'
        )}
        projection="geoMercator"
        projectionConfig={projectionConfig}
      >
        <Geographies
          geography={WorldMap}
          fill="#9F73CA"
          stroke="transparent"
          strokeWidth={1.5}
        >
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography key={geo.rsmKey} geography={geo} />
            ))
          }
        </Geographies>

        {mapLines.map((mapLine, mapLineIndex) => (
          <Line
            key={mapLineIndex}
            from={mapLine.from}
            to={mapLine.to}
            stroke="#F00"
            strokeWidth={4}
            strokeLinecap="round"
          />
        ))}

        {selectedCities.map((city, index) => (
          <Marker key={index} coordinates={[city.longitude!, city.latitude!]}>
            <image x={-18} y={-18} href="/images/maps-marker.svg" />
          </Marker>
        ))}
      </ComposableMap>
    </div>
  )
}

export default LocationsMap
