import LocationsMap from '@/components/LocationsMap'
import { Location } from '@/lib/definitions/api.types'
import SummaryFancyBox from '@/pages/ServiceWizard/partials/SummaryFancyBox'

interface SummaryLocationInterface {
  location?: Location
}

const SummaryLocation = ({ location }: SummaryLocationInterface) => (
  <div className="mt-2" data-cy="wizard-summary-location">
    <h3 className="text-white text-heading-byline mb-2">Location</h3>

    {location && (
      <>
        <div className="flex flex-col font-text mb-2">
          <span className="text-white mt-2 mb-1.5">{location.city.name}</span>
          <span className="text-brand-3 leading-4">{location.description}</span>
        </div>

        {!!location.supplier_name && (
          <div className="flex flex-row items-center justify-between font-text mb-2">
            <span className="text-white mr-2">Extension Provider</span>
            <span className="text-brand-3 text-right">
              {location.supplier_name}
            </span>
          </div>
        )}
        {!!location.extension_handover && (
          <div className="flex flex-row items-center justify-between font-text mb-2">
            <span className="text-white mr-2">Edge Location</span>
            <span className="text-brand-3 text-right">
              {location.extension_handover}
            </span>
          </div>
        )}
      </>
    )}

    <SummaryFancyBox className="!p-0" wrapperClassName="my-4 shadow-1">
      <LocationsMap locations={location && [location]} />
    </SummaryFancyBox>
  </div>
)

export default SummaryLocation
