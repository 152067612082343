import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import { price } from '@/lib/misc/Utils'

const SummaryPrefixes = () => {
  const { ipTransitWizardState } = useIpTransitWizardState()
  const { selectedPrefixV4, selectedPrefixV6 } = ipTransitWizardState

  return (
    <div className="mt-4 mb-2">
      <h3 className="text-white text-heading-byline mb-2">Prefixes</h3>

      <div className="flex justify-between mt-2 font-text">
        <div className="flex flex-row">
          <div className="mr-2 text-brand-3">
            <div className="flex flex-row mb-2">
              <span>IPv4</span>
              <span className="mx-3">/{selectedPrefixV4?.id}</span>
            </div>
            <div className="flex flex-row">
              <span>IPv6</span>
              <span className="mx-3">/{selectedPrefixV6?.id}</span>
            </div>
          </div>

          <div className="flex flex-col text-white">
            <span className="mb-2">
              {selectedPrefixV4?.addresses} Addresses
            </span>
            <span>{selectedPrefixV6?.addresses} Addresses</span>
          </div>
        </div>

        <div className="flex flex-col items-end">
          <span className="text-brand-3 mb-2">
            {price(Number(selectedPrefixV4?.mrc.amount))}
          </span>
          <span className="text-brand-3">
            {price(Number(selectedPrefixV6?.mrc.amount))}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SummaryPrefixes
