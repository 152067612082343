import { classNames } from '@/lib/misc/Utils'
import { CircleFlag } from 'react-circle-flags'
import { Location, LocationSearchResult } from '@/lib/definitions/api.types'

interface LocationCardProps {
  locationSearchResult: LocationSearchResult
  locationSearchType: "address" | "city" | "country" | "name" | ""
  selected?: boolean
  onClick?: (location: Location) => void
  cy?: String
  className?: String
}

const LocationCardMinimal = ({
  locationSearchResult,
  locationSearchType,
  selected,
  onClick,
  cy,
  className,
}: LocationCardProps) => {
  return (
    <div
      data-cy={cy}
      onClick={() => !!onClick && onClick(locationSearchResult.location)}
      key={locationSearchResult.location.id}
      className={classNames(
        'rounded-md p-3 relative space-y-2 hover:shadow-lg transition-shadow',
        !!onClick
          ? 'bg-white hover:ring-2 hover:ring-brand-5 cursor-pointer'
          : 'bg-white opacity-80 cursor-not-allowed',
        selected && 'ring-2 ring-brand-5',
        className
      )}
    >
      <div className="mr-10">
        <span className="font-semibold">{locationSearchResult.location.description}</span>
      </div>
      <div className="mr-10">
        <span className="text-brand-5">{locationSearchResult.location.name}</span>
      </div>
      <div className="text-neutral-50">{locationSearchResult.location.city.name}</div>
      <div
        className="text-neutral-40 text-sm ellipsis truncate"
        title={locationSearchResult.location.line1}
      >
        {locationSearchResult.location.line1}
      </div>
      {typeof locationSearchResult.search_point_distance === 'number' && (
        <div
          className="text-neutral-40 text-sm ellipsis truncate"
          data-cy="distance-display"
        >
          Distance: {Math.round(locationSearchResult.search_point_distance / 1000)} km {locationSearchType === "city" && "from the center"}
        </div>
      )}
      <div
        className={classNames(
          'w-8 absolute top-0 right-2',
          !!onClick ? '' : 'opacity-50'
        )}
      >
        <CircleFlag
          countryCode={locationSearchResult.location.city.country.short_name.toLowerCase()}
          height="48"
        />
      </div>
    </div>
  )
}

export default LocationCardMinimal
