import { Link } from 'react-router-dom'

import { classNames, formatDate } from '@/lib/misc/Utils'
import build from '@/build-info.json'

interface FooterPropsInterface {
  className?: string
}

const Footer = ({ className }: FooterPropsInterface) => (
  <div
    className={classNames(
      'flex flex-col sm:flex-row items-center justify-between w-full py-4 text-gray-600',
      className
    )}
  >
    <div>{!!build?.time ? `Build ${formatDate(build.time * 1000)}` : ''}</div>

    <div className="mt-2 sm:mt-0 text-center">
      All prices without VAT
      <span className="mx-2 text-tiny">•</span>
      All rights reserved
      <span className="mx-2 text-tiny">•</span>
      <a
        target="_blank"
        href="https://inter.link/imprint/"
        className="text-link-default"
      >
        Imprint
      </a>
      <span className="mx-2 text-tiny">•</span>
      <a
        target="_blank"
        href="https://25992704.fs1.hubspotusercontent-eu1.net/hubfs/25992704/07032024%203%20Interlink%20SLA.pdf"
        className="text-link-default"
      >
        SLA
      </a>
      <span className="mx-2 text-tiny">•</span>
      <Link target="_blank" to="/legal/privacy" className="text-link-default">
        Privacy
      </Link>
      <span className="mx-2 text-tiny">•</span>
      <Link target="_blank" to="/legal/terms" className="text-link-default">
        Terms
      </Link>
    </div>
  </div>
)

export default Footer
