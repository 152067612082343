import { ExistingPortItem, PortSpeedOption } from '@/pages/ServiceWizard/ServiceWizard.types'

export function processExistingPorts(
  customerExistingPorts: any[],
  portSpeedOptions: PortSpeedOption[]
): ExistingPortItem[] {
  if (!customerExistingPorts) return []

  // @ts-ignore
  return customerExistingPorts
    .filter(
      ({ component_type, port_type, response_type }) =>
        (component_type !== 'Reuse' &&
          !!port_type &&
          response_type === 'Port') ||
        response_type === 'LAG'
    )
    .map((port) => {
      const {
        id,
        name,
        location: locationName,
        response_type: responseType,
      } = port

      let portType = port.port_type
      let serviceSid = port.service_sid
      let virtualNetworkName = port.virtual_network_name

      let portSpeed =
        portSpeedOptions.find((option) => option.name === portType) ||
        portSpeedOptions[0]
      const isLag = responseType === 'LAG'
      let lagCount = 0

      if (isLag && (port.ports?.length ?? 0) > 0) {
        portType = port.ports![0].port_type
        serviceSid = port.ports![0].service_sid
        virtualNetworkName = port.ports![0].virtual_network_name

        lagCount = port.ports?.length || 1
        const speed =
          portSpeedOptions.find((option) => option.name === portType) ||
          portSpeedOptions[0]
        portSpeed = { ...speed }
      }

      return {
        portSpeed,
        id,
        name,
        locationName,
        portType,
        isLag,
        lagCount,
        serviceSid,
        virtualNetworkName,
      }
    })
}
