import Button from '@/components/Button'
import {
  Location,
  LocationSearchResult,
  SearchResultsLocationSearchResult,
} from '@/lib/definitions/api.types'
import { ITEMS_COUNT_TO_SHOW } from '@/lib/definitions/constants'
import LocationCardMinimal from '@/components/LocationCardMinimal'
import { LocationCardProps, LocationSearchTerms } from '@/pages/ServiceWizard/partials/Locations'

interface LocationsCardsProps extends LocationCardProps {
  subtitle: string
  setSelectedLocation: (location: Location) => void
  selectAction?: boolean
  searchTerms?: LocationSearchTerms
  locationsToDisplay: LocationSearchResult[]
  fullSearchResults: SearchResultsLocationSearchResult
}

const getSearchType = (
  searchTerms: LocationSearchTerms
): 'address' | 'city' | 'country' | 'name' => {
  if (!!searchTerms.circle) return 'address'
  if (!!searchTerms.rectangle) return 'city'
  if (!!searchTerms.country) return 'country'

  return 'name'
}

const LocationsCards = ({
  locationsToDisplay,
  fullSearchResults,
  title,
  subtitle,
  itemsToShow,
  selectedLocation,
  selectedExistingPort,
  setItemsToShow,
  setSelectedLocation,
  selectAction = true,
  searchTerms,
}: LocationsCardsProps) => (
  <div className="mt-8">
    <div className="text-brand-4 text-2xl leading-[120%] tracking-[-0.24px] mb-1 md:leading-[normal] md:tracking-[-0.48px]">
      {title}
    </div>
    <div className="text-neutral-40 mb-4">{subtitle}</div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
      {locationsToDisplay?.slice(0, itemsToShow).map((sr) => (
        <LocationCardMinimal
          cy="wizard-location"
          key={sr.location.id}
          locationSearchResult={sr}
          locationSearchType={searchTerms ? getSearchType(searchTerms) : ''}
          {...(selectAction && {
            selected:
              selectedLocation === sr.location.id && !selectedExistingPort,
            onClick: () => setSelectedLocation(sr.location),
          })}
        />
      ))}
    </div>
    {locationsToDisplay.length > itemsToShow && (
      <Button
        style="normal"
        className="mx-auto mt-5"
        onClick={() => setItemsToShow((prev) => prev + ITEMS_COUNT_TO_SHOW)}
      >
        Load more
      </Button>
    )}
    {locationsToDisplay?.length &&
      locationsToDisplay.length <= itemsToShow &&
      locationsToDisplay.length > ITEMS_COUNT_TO_SHOW && (
        <>
          <Button
            style="normal"
            className="mx-auto mt-5"
            onClick={() => setItemsToShow(ITEMS_COUNT_TO_SHOW)}
          >
            Hide
          </Button>
          <div className="mt-8 mb-12">
            There are{' '}
            {fullSearchResults.total_results - fullSearchResults.results.length}{' '}
            additional locations not shown.
            <br />
            You can narrow your search by providing a more specific address.
          </div>
        </>
      )}
  </div>
)

export default LocationsCards
