import React, { Dispatch, FC, memo, useMemo, useState } from 'react'

import { useSearchLocations } from '@/lib/queries/Services.queries'
import { Location, LocationSearchResult } from '@/lib/definitions/api.types'
import {
  ExistingPort,
  ExistingPortItem,
  GeoCircle,
  GeoRectangle,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import useExistingPorts from '@/lib/hooks/useExistingPorts'
import { classNames, useDebounce } from '@/lib/misc/Utils'
import LocationSearchInput from '@/components/LocationSearchInput'
import {
  EXTENSION_ITEMS_COUNT_TO_SHOW,
  ITEMS_COUNT_TO_SHOW,
  PLANNED_ITEMS_COUNT_TO_SHOW,
} from '@/lib/definitions/constants'
import ExistingPortsCards from '@/pages/ServiceWizard/partials/ExistingPortsCards'
import LocationsCards from '@/pages/ServiceWizard/partials/LocationsCards'

function groupSitesByCityAndStatus(
  locations: LocationSearchResult[]
): Record<'interlink' | 'extensions' | 'planned', LocationSearchResult[]> {
  const groupedByCityAndStatus: Record<
    'interlink' | 'extensions' | 'planned',
    LocationSearchResult[]
  > = {
    interlink: [],
    extensions: [],
    planned: [],
  }
  const compareDistance = (
    sr1: LocationSearchResult,
    sr2: LocationSearchResult
  ) => {
    if (sr1.search_point_distance === sr2.search_point_distance) {
      return 0
    }
    return sr1.search_point_distance! < sr2.search_point_distance! ? -1 : 1
  }
  locations
    .sort((a, b) =>
      typeof a.search_point_distance === 'number' &&
      typeof b.search_point_distance === 'number'
        ? compareDistance(a, b)
        : a.location.city.country.name === b.location.city.country.name
          ? a.location.city.name === b.location.city.name
            ? a.location.description.localeCompare(b.location.description)
            : a.location.city.name.localeCompare(b.location.city.name)
          : a.location.city.country.name.localeCompare(
              b.location.city.country.name
            )
    )
    .forEach((searchResult) => {
      const status = searchResult.location.status || 'Unknown'
      if (status === 'Active') {
        if (searchResult.location.type === 'Extension') {
          groupedByCityAndStatus.extensions.push(searchResult)
        } else {
          groupedByCityAndStatus.interlink.push(searchResult)
        }
      } else {
        groupedByCityAndStatus.planned.push(searchResult)
      }
    })

  return groupedByCityAndStatus
}

export interface LocationCardProps {
  title: string
  itemsToShow: number
  selectedLocation?: number | string
  selectedExistingPort?: number
  setItemsToShow: Dispatch<React.SetStateAction<number>>
}

export interface LocationSearchTerms {
  circle?: GeoCircle
  rectangle?: GeoRectangle
  country?: string
  name?: string
}

interface LocationsProps {
  title: string
  subTitle?: string
  selectedLocation?: number | string
  existingPorts?: ExistingPort[]
  selectedExistingPort?: number
  setSelectedLocation: (location: Location) => void
  setExistingPort: (port: ExistingPortItem) => void
}

const Locations: FC<LocationsProps> = ({
  title,
  subTitle,
  selectedLocation,
  existingPorts,
  selectedExistingPort,
  setSelectedLocation,
  setExistingPort,
}) => {
  const [searchLocation] = useState('')
  const [searchTerms, setSearchTerms] = useState<
    LocationSearchTerms | undefined
  >(undefined)
  const debouncedSearchLocation = useDebounce(searchLocation, 300)
  const [activeItemsToShow, setActiveItemsToShow] =
    useState(ITEMS_COUNT_TO_SHOW)
  const [plannedItemsToShow, setPlannedItemsToShow] = useState(
    PLANNED_ITEMS_COUNT_TO_SHOW
  )
  const [existingPortToShow, setExistingPortItemsToShow] = useState(
    EXTENSION_ITEMS_COUNT_TO_SHOW
  )
  const { data: locationSearchResults, isSuccess } = useSearchLocations({
    text: debouncedSearchLocation,
    withinCircle: searchTerms?.circle,
    withinRectangle: searchTerms?.rectangle,
    country: searchTerms?.country,
  })

  const { data: existingPortsLocationsResult } = useSearchLocations({
    name: [...new Set(existingPorts?.map((p) => p.locationName))].join(','),
  }) // TODO issue the call only if existingPorts

  const existingPortItems = useExistingPorts({
    search: debouncedSearchLocation,
    searchCircle: searchTerms?.circle,
    searchRectangle: searchTerms?.rectangle,
    searchCountry: searchTerms?.country,
    existingPortsLocations: existingPortsLocationsResult?.results || [],
    existingPorts,
  })

  const onPlaceSelected = (
    circle?: GeoCircle,
    rectangle?: GeoRectangle,
    country?: string
  ) => setSearchTerms({ circle, rectangle, country })

  const locationCardsCategories = useMemo(() => {
    const categories = []

    if (locationSearchResults?.results) {
      const groupedLocations = groupSitesByCityAndStatus(
        locationSearchResults.results
      )

      const props = {
        searchTerms,
        setSelectedLocation,
        selectedLocation,
        selectedExistingPort,
        fullSearchResults: locationSearchResults,
      }

      if (groupedLocations.interlink.length) {
        categories.push({
          locationsToDisplay: groupedLocations.interlink,
          title: 'Inter.link locations',
          subtitle:
            'Locations with 100/400GE PoP owned and operated by Inter.link. Services are available for immediate provisioning.',
          itemsToShow: activeItemsToShow,
          setItemsToShow: setActiveItemsToShow,
          ...props,
        })
      }

      if (groupedLocations.extensions.length) {
        categories.push({
          locationsToDisplay: groupedLocations.extensions,
          title: 'Extension locations',
          subtitle:
            'Locations that we reach through partner local tails. Services available with lead time of 8-12 weeks.',
          itemsToShow: activeItemsToShow,
          setItemsToShow: setActiveItemsToShow,
          ...props,
        })
      }

      if (groupedLocations.planned.length) {
        categories.push({
          locationsToDisplay: groupedLocations.planned,
          title: 'Coming soon',
          subtitle:
            'Locations where Inter.link will setup a new PoP shortly or is already in the process of building.',
          itemsToShow: plannedItemsToShow,
          setItemsToShow: setPlannedItemsToShow,
          selectAction: false,
          ...props,
        })
      }
    }

    return categories
  }, [
    locationSearchResults,
    activeItemsToShow,
    plannedItemsToShow,
    searchTerms,
    selectedLocation,
    selectedExistingPort,
    setSelectedLocation,
  ])

  return (
    <div>
      <h1 className="text-2xl font-bold leading-[normal] tracking-[-0.24px] capitalize text-brand-1 mb-1 md:mb-2 md:text-3xl md:normal-case">
        {title}
      </h1>
      {!!subTitle && (
        <div className="text-brand-1 mb-10 text-sm md:text-base">
          {subTitle}
        </div>
      )}

      <div className="flex items-start justify-between gap-5 lg:gap-20">
        <div className="grow">
          <div className="mb-4 md:mb-[30px]">
            <LocationSearchInput onPlaceSelected={onPlaceSelected} />
            {!!searchTerms && !!locationSearchResults?.results && (
              <div className="text-right mt-2">
                <span className="font-bold mr-1">
                  {locationSearchResults.total_results}
                </span>
                location{locationSearchResults.total_results !== 1 && 's'} found
              </div>
            )}
          </div>
          <div
            className={classNames(
              'transition-opacity duration-300 delay-75',
              searchLocation !== debouncedSearchLocation && 'opacity-50'
            )}
          >
            {!!existingPortItems.length && (
              <ExistingPortsCards
                existingPorts={existingPortItems}
                title="Your existing ports"
                itemsToShow={existingPortToShow}
                setItemsToShow={setExistingPortItemsToShow}
                setExistingPort={setExistingPort}
                selectedLocation={selectedLocation}
                selectedExistingPort={selectedExistingPort}
              />
            )}

            {!!locationCardsCategories.length &&
              locationCardsCategories.map((cardsCategory, index) => (
                <LocationsCards key={index} {...cardsCategory} />
              ))}
          </div>

          {!locationCardsCategories.length && !!searchLocation && isSuccess && (
            <div className="text-neutral-50 bg-neutral-05/40 text-xl flex justify-center content-center items-center leading-6 mb-4 md:mb-1 rounded-xl p-5 min-h-[240px]">
              <span>
                Sorry, we couldn't find any location matching your search.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(Locations)
