import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import { classNames } from '@/lib/misc/Utils'

const SummaryCommittedDataRate = ({ isExtended }: { isExtended?: boolean }) => {
  const { ipTransitWizardState } = useIpTransitWizardState()
  const { selectedServiceSpeed } = ipTransitWizardState

  return !selectedServiceSpeed ? (
    <div />
  ) : (
    <div className={classNames('mt-8', isExtended && 'col-span-2')}>
      <h3 className="text-accent-40 text-heading-byline mb-2">Bandwidth</h3>

      <div className="flex flex-col">
        <div className="w-full flex flex-col">
          <div className="flex justify-between mb-2">
            <span className="font-text text-white">Committed Data Rate</span>
            <span className="font-text text-brand-3 whitespace-nowrap">
              {selectedServiceSpeed.speed ?? ''}{' '}
              {selectedServiceSpeed.unit ?? ''}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SummaryCommittedDataRate
