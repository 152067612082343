import debounce from 'lodash/debounce'
import { useState } from 'react'

import { Select } from '@/components/SelectInputs'
import Input from '@/components/GenericInput'
import { ApiService } from '@/lib/services/ApiService'
import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import { bgpTypeOptions } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import Tooltip from '@/components/Tooltip'
import InfoIcon from '@icons/information-circle'

const fetchAsnInfo = debounce(
  async (value: number, setState: Function, onChange: Function) => {
    if (!value) {
      return
    }

    onChange(true, false)
    let response = null
    let isValid = false
    try {
      response = await new ApiService().queryAsnNumber(value)

      if (response?.data) {
        const { data } = response
        isValid = true

        setState({
          as_number: value,
          as_set: data.as_set,
          prefixLimitV4: data.prefix_v4,
          prefixLimitV6: data.prefix_v6,
        })
      }
    } finally {
      onChange(false, isValid)
    }
  },
  1000
)

type BgpSessionFormProps = {
  onLoading: (loading: boolean, isValid: boolean) => void
}

const BgpSessionForm = ({ onLoading }: BgpSessionFormProps) => {
  const { ipTransitWizardState, updateTransitWizard } =
    useIpTransitWizardState()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onChangeAsnNumber = (value: number) => {
    updateTransitWizard({ as_number: value })
    fetchAsnInfo(
      value,
      updateTransitWizard,
      (loading: boolean, isValid: boolean) => {
        onLoading(loading, isValid)
        setIsLoading(loading)

        if (isValid && !isLoading) {
          updateTransitWizard({ syncFromPdb: true })
        }
      }
    )
  }

  const handleBgpRoutingSelection = (val: string) => {
    const selectedOption = bgpTypeOptions.find((option) => option.value === val)

    updateTransitWizard({ advertisement_type: selectedOption?.label })
  }

  return (
    <div className="flex flex-col space-between gap-y-4 mt-2">
      <div className="grid grid-cols-2 gap-3">
        <Input
          name="as_number"
          cy="input-as_number"
          label="AS-Number"
          required
          onChange={(e: any) => onChangeAsnNumber(e.target.value)}
          value={ipTransitWizardState.as_number || ''}
          placeholder="eg: 00000"
          loading={isLoading}
        />
        <Input
          name="as_set"
          cy="input-as_set"
          label="AS-SET"
          autoComplete="off"
          onChange={(e: any) => updateTransitWizard({ as_set: e.target.value })}
          value={ipTransitWizardState.as_set || ''}
          placeholder="eg: AS-INTERDEMO"
          disabled={isLoading}
          loading={isLoading}
        />
        <Input
          name="prefixLimitV4"
          cy="input-prefixLimitV4"
          label="Prefix IPv4 Limit"
          required
          type="number"
          onChange={(e: any) =>
            updateTransitWizard({ prefixLimitV4: e.target.value })
          }
          value={ipTransitWizardState.prefixLimitV4 || ''}
          placeholder="eg: 20"
          disabled={isLoading}
          loading={isLoading}
        />
        <Input
          name="prefixLimitV6"
          cy="input-prefixLimitV6"
          label="Prefix IPv6 Limit"
          required
          type="number"
          onChange={(e: any) =>
            updateTransitWizard({ prefixLimitV6: e.target.value })
          }
          value={ipTransitWizardState.prefixLimitV6 || ''}
          placeholder="eg: 20"
          disabled={isLoading}
          loading={isLoading}
        />
        <Input
          name="session_pass"
          cy="input-session_pass"
          label="Session Password"
          onChange={(e: any) =>
            updateTransitWizard({ password: e.target.value })
          }
          value={ipTransitWizardState.password || ''}
        />
        <Select
          cy="select-route-advertisement"
          name="route-advertisement"
          label="Route Advertisement"
          defaultValue={ipTransitWizardState.advertisement_type}
          options={bgpTypeOptions}
          setValue={handleBgpRoutingSelection}
          required
        />
        <div className="col-span-2">
          {/*  sync from pdb */}
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id="sync-from-pdb"
              name="sync-from-pdb"
              checked={ipTransitWizardState.syncFromPdb}
              onChange={(e) =>
                updateTransitWizard({ syncFromPdb: e.target.checked })
              }
            />
            <label htmlFor="sync-from-pdb" className="text-neutral-50 flex">
              Sync BGP prefix limits automatically
              <Tooltip
                className="ml-1"
                wrapperClassName="flex"
                icon={<InfoIcon />}
              >
                Enabling this option will sync the BGP session prefix limits
                automatically from PeeringDB
              </Tooltip>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BgpSessionForm
