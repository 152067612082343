import { useCallback } from 'react'
import {
  CommittedDataRateOption,
  ExistingPort,
  PortSpeedOption,
  PrefixOption,
  LAGProperties,
} from '@/pages/ServiceWizard/ServiceWizard.types'

import {
  AggregatedCommitTotals,
  ExtensionWizardPricesResponse,
  IPTransitPriceResponse,
  Location,
  ServiceComponentVLANType,
  SpeedMbps2,
} from '@/lib/definitions/api.types'
import { IpTransitStep } from '@/pages/ip-transit/IpTransit'
import {
  IP_TRANSIT_WIZARD,
  RESET_IP_TRANSIT,
  useServiceWizard,
} from '@/lib/provider/ServiceWizardProvider'

export interface IpTransitWizardState {
  contract?: number
  activeTab?: IpTransitStep
  locations?: Location[]
  portSpeedItems?: PortSpeedOption[]
  prefixV4Ids?: number[]
  prefixV6Ids?: number[]
  prefixV4Items?: PrefixOption[]
  prefixV6Items?: PrefixOption[]
  prefixLimitV4?: number
  prefixLimitV6?: number
  existingPorts?: ExistingPort[]
  existingPortId?: number
  selectedLocation?: Location
  selectedPortSpeed?: PortSpeedOption
  selectedPortType?: string
  selectedPrefixV4?: PrefixOption
  selectedPrefixV6?: PrefixOption
  selectedServiceSpeed?: CommittedDataRateOption
  advertisement_type?: string
  as_number?: number
  as_set?: string
  vlanId?: number
  vlanType?: ServiceComponentVLANType
  quote?: IPTransitPriceResponse
  bandwidthsItems?: Record<string, CommittedDataRateOption>
  availableServiceBandwidths?: { [k: string]: SpeedMbps2[] }
  password?: string
  purchaseReference?: string
  extensionPrices?: ExtensionWizardPricesResponse
  lag?: LAGProperties
  syncFromPdb?: boolean
  hasAggregatedBilling?: boolean
  aggregatedCommitTotals?: AggregatedCommitTotals
}

const useIpTransitWizardState = () => {
  const { state, dispatch } = useServiceWizard()

  const ipTransitWizardState: IpTransitWizardState = state.ipTransitWizard

  const updateTransitWizard = useCallback(
    (payload: IpTransitWizardState) => {
      dispatch({
        type: IP_TRANSIT_WIZARD,
        payload: {
          ...payload,
        },
      })
    },
    [dispatch]
  )

  const resetIpTransitWizard = useCallback(() => {
    dispatch({
      type: RESET_IP_TRANSIT,
    })
  }, [dispatch])

  return {
    ipTransitWizardState,
    updateTransitWizard,
    resetIpTransitWizard,
  }
}

export default useIpTransitWizardState
