import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectInaccessiblePages = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/', { replace: true })
  }, [navigate])

  return <div />
}

export default RedirectInaccessiblePages
