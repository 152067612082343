import {
  CommittedDataRateOption,
  ELanEndpoint,
  ExistingPort,
  PortSpeedOption,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import { ELanStep } from '@/pages/elan/ELan'

import {
  ExtensionWizardPricesResponse,
  Location,
  FlexEthernetPriceResponse,
} from '@/lib/definitions/api.types'
import {
  FLEX_ETHERNET_WIZARD,
  RESET_FLEX_ETHERNET,
  useServiceWizard,
} from '@/lib/provider/ServiceWizardProvider'
import { useCallback } from "react"

export interface ELanWizardState {
  contract: number
  activeTab: ELanStep
  locations?: Location[]
  portSpeedItems?: PortSpeedOption[]
  endpoints: ELanEndpoint[]
  purchaseReference?: string
  virtualNetworkName?: string
  serviceSpeedItems?: CommittedDataRateOption[]
  existingPorts?: ExistingPort[]
  quotes: FlexEthernetPriceResponse[]
  extensionPrices: ExtensionWizardPricesResponse
}

const useELanWizardState = () => {
  const { state, dispatch } = useServiceWizard()

  const eLanWizardState: ELanWizardState = state.flexEthernetWizard as any

  const updateELanWizard = useCallback((payload: Partial<ELanWizardState>) => {
    dispatch({
      type: FLEX_ETHERNET_WIZARD,
      payload: {
        ...payload,
      },
    })
  }, [dispatch])

  const resetFlexEthernetWizard = useCallback(() => {
    dispatch({
      type: RESET_FLEX_ETHERNET,
    })
  }, [dispatch])

  return {
    eLanWizardState,
    updateELanWizard,
    resetFlexEthernetWizard,
  }
}

export default useELanWizardState
