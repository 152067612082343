import { classNames } from '@/lib/misc/Utils'
import { ServiceComponentVLANType } from '@/lib/definitions/api.types'
import Input from '@/components/GenericInput'
import { useState } from 'react'
import { GenericErrors } from '@/lib/definitions/generic.types'

type VlanSelectionProps = {
  vlanType: ServiceComponentVLANType | undefined
  vlanId: number | undefined | null
  onTypeChange: (type: ServiceComponentVLANType) => void
  onIdChange: (id: number) => void
}
const VlanSelection = ({
  vlanType,
  vlanId,
  onTypeChange,
  onIdChange,
}: VlanSelectionProps) => {
  const [vlanIdError, setVlanIdError] = useState<GenericErrors | undefined>()

  const setVlanId = (val: number) => {
    if (isNaN(val)) {
      setVlanIdError({ vlanId: ['VLAN ID should be a valid number'] })
      return
    } else if (val < 1) {
      setVlanIdError({ vlanId: ['VLAN ID must be greater than 0'] })
      return
    } else if (val > 4094) {
      setVlanIdError({ vlanId: ['VLAN ID can not be greater than 4094'] })
      return
    } else if (vlanIdError) {
      setVlanIdError(undefined)
      return
    }

    onIdChange(val)
  }

  return (
    <div>
      <div className="text-heading-byline tracking-tightest text-brand-1">
        Configure VLAN
      </div>
      <div className="my-2 text-neutral-50">
        Every port supports one untagged and multiple tagged services at the
        same time. If untagged is not available, you have to remove the
        occupying service first. We recommend configuring all services with
        tagged handover on the port to allow for more flexibility.
      </div>

      <div className="flex w-full gap-x-2 my-4 bg-white p-1 rounded-xl border-1 border-neutral-20">
        <div
          className={classNames(
            'rounded-lg w-full uppercase text-center py-3 px-4 cursor-pointer hover:opacity-75',
            vlanType === 'Tagged' ? 'bg-accent-40 text-white' : 'text-accent-60'
          )}
          onClick={() => onTypeChange('Tagged')}
          data-cy="wizard-tagged-vlan-type"
        >
          TAGGED
        </div>
        <div
          className={classNames(
            'rounded-lg w-full uppercase text-center py-3 px-4 cursor-pointer hover:opacity-75',
            vlanType === 'Untagged'
              ? 'bg-accent-40 text-white'
              : 'text-accent-60'
          )}
          onClick={() => onTypeChange('Untagged')}
          data-cy="wizard-untagged-vlan-type"
        >
          UNTAGGED
        </div>
      </div>

      {vlanType === 'Tagged' && (
        <div className="mt-2.5">
          <Input
            type="number"
            name="vlanId"
            label="VLAN ID"
            cy="input-vlanId"
            value={vlanId || ''}
            {...(!!vlanIdError && {
              errors: vlanIdError.vlanId,
            })}
            required
            onChange={(e: any) => setVlanId(e.target.value)}
            placeholder="eg: 1000"
          />
        </div>
      )}
    </div>
  )
}

export default VlanSelection
