import { useMemo } from 'react'
import { GenericObject } from '@/lib/definitions/generic.types'

const useParseFormErrors = (
  errors?: (string | undefined | GenericObject)[],
  name?: string
) => {
  const parsedErrors: string[] = useMemo(() => {
    if (errors?.length) {
      let errs: string[] = []

      errors.forEach((error) => {
        if (!error) {
          return
        } else if (typeof error === 'string') {
          errs.push(error)
        } else if (!!name && !!error[name]) {
          if (Array.isArray(error[name])) {
            errs = errs.concat(error[name])
          } else if (error[name].message) {
            errs.push(error[name].message)
          }
        }
      })

      return errs
    }

    return []
  }, [errors, name])

  return {
    parsedErrors,
  }
}

export default useParseFormErrors
