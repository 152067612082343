import axios, { AxiosPromise } from 'axios'

import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'
import {
  FlexEthernetWizardPricesRequest,
  FlexEthernetWizardPricesResponse,
  ElanCreate,
  ElanEndpoint,
  FlexEthernetPriceRequest,
  FlexEthernetPriceResponse,
} from '@/lib/definitions/api.types'

export class FlexEthernetService {
  getFlexEthernetWizardPrices(
    data: FlexEthernetWizardPricesRequest
  ): AxiosPromise<FlexEthernetWizardPricesResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/flex-ethernet/wizard'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  requestFlexEthernetPriceSummary(
    data: FlexEthernetPriceRequest
  ): AxiosPromise<FlexEthernetPriceResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/flex-ethernet'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  createFlexEthernetOrder(data: ElanCreate): AxiosPromise<ElanEndpoint[]> {
    return axios.post(
      apiUrl('/api/v1/flex-ethernet/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }
}
