import Button, { ButtonStyle } from '@/components/Button'

import ChevronLeftIcon from '@icons/chevron-left'
import ChevronRightIcon from '@icons/chevron-right'

interface WizardPaginationProps {
  previousOnClick: () => void
  nextOnClick: () => void
  previousDisabled?: boolean
  nextDisabled?: boolean
  backHidden?: boolean
  nextButtonlabel: string
  backButtonlabel?: string
  nextButtonStyle?: ButtonStyle
  nextButtonCy?: string
}

const WizardPagination = ({
  previousOnClick,
  nextOnClick,
  previousDisabled,
  nextDisabled,
  backHidden,
  nextButtonlabel,
  backButtonlabel = 'Back',
  nextButtonStyle,
  nextButtonCy,
}: WizardPaginationProps) => (
  <div className="flex justify-between md:justify-end">
    {!backHidden && (
      <Button
        onClick={previousOnClick}
        className="drop-shadow-btn w-full md:w-auto justify-center"
        style="normal"
        icon={backButtonlabel === 'Back' && <ChevronLeftIcon />}
        disabled={previousDisabled}
        cy="wizard-pagination-back-btn"
      >
        {backButtonlabel}
      </Button>
    )}

    <Button
      onClick={nextOnClick}
      className="ml-3 shadow-btn w-full md:w-auto justify-center"
      style={nextButtonStyle ?? 'primary'}
      iconSuffix={nextButtonlabel === 'Next' && <ChevronRightIcon />}
      disabled={nextDisabled}
      cy={nextButtonCy}
    >
      {nextButtonlabel}
    </Button>
  </div>
)

export default WizardPagination
