import { ChangeEvent, useState } from 'react'

import ELanEndpointDetails from '@/pages/elan/partials/ELanEndpointDetails'
import PortSelectionDialog from '@/pages/elan/partials/PortSelectionDialog'

import Button from '@/components/Button'
import Dialog from '@/components/Dialog'
import Input from '@/components/GenericInput'
import useELanWizardState from '@/lib/hooks/useELanWizardState'

import PlusIcon from '@icons/plus'
import { classNames } from '@/lib/misc/Utils'

const TabELanPorts = () => {
  const { eLanWizardState, updateELanWizard } = useELanWizardState()
  const [isAddingEndpoint, setIsAddingEndpoint] = useState(false)
  const [reconfiguringEndpointIndex, setReconfiguringEndpointIndex] = useState<
    number | null
  >(null)

  const handleDeletingEndpoint = (endpointIndex: number) => {
    const { endpoints, quotes } = eLanWizardState

    const updatedEndpoints = [
      ...endpoints.slice(0, endpointIndex),
      ...endpoints.slice(endpointIndex + 1),
    ]
    const updatedQuotes = [
      ...quotes.slice(0, endpointIndex),
      ...quotes.slice(endpointIndex + 1),
    ]

    updateELanWizard({
      endpoints: updatedEndpoints,
      quotes: updatedQuotes,
    })
  }

  const handleReconfiguringEndpoint = (endpointIndex: number) => {
    setReconfiguringEndpointIndex(endpointIndex)
    setIsAddingEndpoint(true)
  }

  const handleOnClose = () => {
    setReconfiguringEndpointIndex(null)
    setIsAddingEndpoint(false)
  }

  return (
    <div className="w-full flex flex-col">
      <h4 className="text-heading-byline tracking-tight text-brand-4 mt-10">
        Add Endpoints
      </h4>
      {eLanWizardState.endpoints.length ? (
        <div className="flex flex-col justify-between items-start select-none mt-3">
          <div className="w-full">
            {eLanWizardState.endpoints.map((endpoint, endpointIndex) => (
              <div className="w-full relative group" key={endpointIndex}>
                <ELanEndpointDetails
                  endpoint={endpoint}
                  onDeleteEndpoint={() => handleDeletingEndpoint(endpointIndex)}
                  onReconfigureEndpoint={() =>
                    handleReconfiguringEndpoint(endpointIndex)
                  }
                />
                <div
                  className={classNames(
                    'absolute box-border border-r-1 border-b-1 group-first:border-t-1 border-brand-4 h-[calc(100%+22px)] group-last:h-[calc(calc(50%+56px))] w-20 top-[50%] -right-3 md:-right-5 -z-50',
                    eLanWizardState.endpoints.length >= 2 &&
                      'group-last:border-t-0'
                  )}
                />
              </div>
            ))}
          </div>
          <Button
            style="light"
            className="h-18 rounded-lg"
            fullWidth
            onClick={() => setIsAddingEndpoint(true)}
          >
            <div className="flex" data-cy="add-another-endpoint">
              <PlusIcon className="mr-2" />
              Add another endpoint
            </div>
          </Button>
        </div>
      ) : (
        <div className="flex flex-col items-center border-2 border-dashed bg-white border-accent-30 rounded-xl py-10 md:py-20 px-4 mt-3 select-none">
          <h3 className="font-medium text-2xl leading-6 text-brand-4 text-center mb-6 md:mb-0">
            Add an endpoint to your Flex Ethernet
          </h3>

          <span className="mt-5 mb-4 w-[85%] lg:w-[70%] text-center text-brand-4 font-normal text-sm leading-4">
            In order to configure your Flex Ethernet, you need to add endpoints
            that will be connected with each other. Go a head and add an
            endpoint!
          </span>

          <Button
            style="primary"
            onClick={() => setIsAddingEndpoint(true)}
            cy="add-endpoint"
          >
            Add an endpoint
          </Button>
        </div>
      )}

      <div className="space-between mt-8">
        <Input
          name="purchaseReference"
          cy="input-purchaseReference"
          label="Purchase Reference"
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            updateELanWizard({
              purchaseReference: evt.target.value.trim().substring(0, 255),
            })
          }
          value={eLanWizardState.purchaseReference || ''}
          placeholder="eg: PO-1"
        />

        <Input
          name="virtualNetworkName"
          cy="input-virtualNetworkName"
          label="Virtual Network Name"
          placeholder="Virtual Network"
          className="mt-8"
          required
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            updateELanWizard({
              virtualNetworkName: evt.target.value.substring(0, 179),
            })
          }
          value={eLanWizardState.virtualNetworkName || ''}
        />
      </div>

      {isAddingEndpoint && (
        <Dialog
          onDismiss={handleOnClose}
          onConfirm={handleOnClose}
          hasButtons={false}
          wrapperClassName="!px-1 py-10 md:py-12 !bg-accent-05 md:min-h-[500px] md:min-w-[760px]"
        >
          <PortSelectionDialog
            onClose={handleOnClose}
            reconfiguringEndpointIndex={reconfiguringEndpointIndex}
          />
        </Dialog>
      )}
    </div>
  )
}

export default TabELanPorts
