import {
  FC,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
  memo,
} from 'react'

import { classNames } from '@/lib/misc/Utils'

import CheckIcon from '@icons/check/mini'

const containerClasses = {
  base: 'flex justify-center items-center h-4 w-4 rounded border cursor-pointer transition-all duration-200 peer-checked:bg-brand-5 peer-checked:border-brand-5 bg-white border-neutral-20 hover:border-neutral-40 peer-checked:[&>*:first-child]:block',
  disabled: '!bg-neutral-10 !cursor-not-allowed hover:!border-neutral-20',
  error: 'border-rose-500',
} as const

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  children: ReactNode
  cy?: string
  error?: boolean
}

const Checkbox: FC<CheckboxProps> = forwardRef(
  (
    { className, cy, error, children, ...props },
    ref: Ref<HTMLInputElement>
  ) => {
    const combinedContainerClasses = classNames(
      containerClasses.base,
      className,
      error && containerClasses.error,
      props.disabled && containerClasses.disabled
    )

    const checkIconClasses = classNames(
      'text-white text-3 transition-all duration-200 hidden'
    )

    return (
      <label className="flex items-center">
        <input
          className="peer"
          data-cy={cy}
          hidden
          type="checkbox"
          ref={ref}
          {...props}
        />
        <div className={combinedContainerClasses}>
          <CheckIcon className={checkIconClasses} />
        </div>
        {children}
      </label>
    )
  }
)

export default memo(Checkbox)
