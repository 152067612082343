import { ReactNode } from 'react'

import { classNames } from '@/lib/misc/Utils'

interface TooltipProps {
  children: ReactNode
  icon?: ReactNode
  label?: ReactNode
  className?: string
  wrapperClassName?: string
  placement?: 'left-arrow' | 'right-arrow' | 'top-arrow' | 'bottom-arrow'
  inline?: boolean
}

const Tooltip = ({
  children,
  icon,
  label,
  className,
  wrapperClassName,
  placement = 'top-arrow',
  inline = false,
}: TooltipProps) => (
  <div className={classNames(wrapperClassName)}>
    <span
      className={classNames('group relative inline-block min-h-min', className)}
    >
      {!!icon && <span>{icon}</span>}
      {!!label && !inline && <span>{label}</span>}
      {inline && children}
      <span
        className={classNames('tooltip-content group-hover:visible', placement)}
      >
        {!inline && children}
        {inline && !!label && <span>{label}</span>}
      </span>
    </span>
  </div>
)

export default Tooltip
