import {
  FWaaSRuleSet,
  Location,
  LocationType,
  Money,
  MrcNrc,
  PortTypes,
  ServiceComponentVLANType,
} from '@/lib/definitions/api.types'

export interface City {
  locations: Location[]
  name: string
  country: string
  activeLocations: number
}

export interface PortSpeed {
  id: number
  speedInMbps: number
  speed: number
  unit: string
  type: string
  name: string
  isLag?: boolean
}

export interface PortSpeedOption extends PortSpeed, MrcNrc {
  attribute_id?: number
}

export interface Prefix {
  id: number
  addresses: number
  isRecommended?: boolean
  note?: string
}

export interface PrefixOption extends Prefix, MrcNrc {}

export interface CommittedDataRateOption {
  speedInMbps: number
  speed: number
  unit: 'Mbps' | 'Gbps'
  nrc?: Money
  mrc?: Money
}

export interface FieldTypes {
  key: string
  label: string
  type?: any
  defaultValue?: Function
}

export interface WizardTabItem {
  id: string
  label: string
  headline?: string
}

export interface ContractTerm {
  label: string
  label_short: string
  period: number
}

export interface BandwidthOptions {
  [id: number]: number[]
}

export interface ELanEndpoint {
  selectedLocation: string
  selectedLocationId?: number
  selectedLocationSpeed: number
  selectedPortSpeed?: PortSpeedOption
  selectedPortType?: string
  selectedPortName: PortTypes | null
  selectedServiceSpeed: number | null
  selectedLocationType: LocationType
  vlanId: number | null
  vlanType: ServiceComponentVLANType | undefined
  existingPortId?: number
  lag?: LAGProperties
}

export interface ExistingPort {
  id: number
  name: string
  locationName: string
  portType: string
  isLag: boolean
  lagCount: number
  portSpeed: PortSpeed
  serviceSid?: number
  virtualNetworkName?: string
}

export interface ExistingPortItem extends ExistingPort {
  location: Location
}

export interface PortDetail {
  key: string
  label: string
  value?: string | number
  isNrc?: boolean
}

export interface LAGProperties {
  memberCount: number
  name: string
}

export interface GeoCoordinates {
  lat: number
  lng: number
}

export interface GeoCircle {
  center: GeoCoordinates
  radius: number
}

export interface GeoRectangle {
  southWest: GeoCoordinates
  northEast: GeoCoordinates
}

export interface FirewallPrices {
  amount: string
  currency: string
  display: string
  speed: number
  speedInMbps: number
  unit: string
}

export type ConfigureFirewallType = {
  name: string
  term: string
  rule_set: FWaaSRuleSet | ''
  bandwidth: string
  reference: string
}

export enum FirewallCreationSteps {
  ConnectElan = 1,
  ChooseLocation = 2,
  ConfigureFirewall = 3,
  Checkout = 4,
}

export enum DDoSPrefixType {
  IPv4 = 'IPv4 /24',
  IPv6 = 'IPv6 /48',
}

export enum DDoSPrefixSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type DDoSPrefix = {
  count: number
  type: DDoSPrefixType
  size: DDoSPrefixSize
  term: string
}

export interface DDoSProtectionPricingMrc extends DDoSPrefix {
  cost: number
}

export interface DDoSProtectionPricing {
  mrc: DDoSProtectionPricingMrc[]
  nrc: number
  total: number
}

export type IpServices = 'ip-access' | 'ip-transit'

export type AmountType = {
  totalAmount: number | null
  bandwidthAmount: number | null
}
