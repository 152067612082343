import { FC, memo, useMemo } from 'react'

import { CommittedDataRateOption } from '@/pages/ServiceWizard/ServiceWizard.types'
import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import CommittedDataRateSelector from '@/pages/ServiceWizard/partials/CommittedDataRateSelector'

const CommittedDataRateSection: FC = () => {
  const { ipTransitWizardState, updateTransitWizard } =
    useIpTransitWizardState()
  const {
    bandwidthsItems,
    availableServiceBandwidths,
    selectedServiceSpeed,
    selectedPortSpeed,
    extensionPrices,
    lag,
    aggregatedCommitTotals,
  } = ipTransitWizardState

  const selectServiceSpeed = (serviceSpeed: CommittedDataRateOption) => {
    updateTransitWizard({
      selectedServiceSpeed: serviceSpeed,
    })
  }

  const serviceSpeeds = useMemo(() => {
    if (selectedPortSpeed?.isLag || lag !== undefined) {
      return Object.keys(bandwidthsItems || {}).map((key) => Number(key))
    }

    return availableServiceBandwidths?.[Number(selectedPortSpeed?.speed)]
  }, [selectedPortSpeed, bandwidthsItems, availableServiceBandwidths, lag])

  return (
    <CommittedDataRateSelector
      serviceSpeeds={serviceSpeeds}
      bandwidthsItems={bandwidthsItems}
      aggregatedCommitTotals={aggregatedCommitTotals}
      selectedPortSpeed={selectedPortSpeed}
      selectedServiceSpeed={selectedServiceSpeed}
      extensionPrices={extensionPrices}
      hasAggregatedBilling={ipTransitWizardState.hasAggregatedBilling}
      selectServiceSpeed={(serviceSpeed) => selectServiceSpeed(serviceSpeed)}
    />
  )
}

export default memo(CommittedDataRateSection)
