import axios, { AxiosPromise } from 'axios'

import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'
import {
  IPAccess,
  IPAccessPriceRequest,
  IPAccessPriceResponse,
  IPAccessWizardPricesRequest,
  IPAccessWizardPricesResponse,
  IpAccessCreate,
} from '@/lib/definitions/api.types'

export class IpAccessService {
  getIpAccessWizardPrices(
    data: IPAccessWizardPricesRequest
  ): AxiosPromise<IPAccessWizardPricesResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/ip-access/wizard'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  requestIpAccessPriceSummary(
    data: IPAccessPriceRequest
  ): AxiosPromise<IPAccessPriceResponse> {
    return axios.post(
      apiUrl('/api/v1/pricing/ip-access'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }

  createIpAccessOrder(data: IpAccessCreate): AxiosPromise<IPAccess> {
    return axios.post(
      apiUrl('/api/v1/ip-access/'),
      data,
      getAxiosRequestConfigWithHeaders()
    )
  }
}
