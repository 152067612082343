import { numberFormat, price } from '@/lib/misc/Utils'
import {
  CommittedDataRateOption,
  LAGProperties,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import { BandwidthValues } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import OptionBlock from '@/pages/ServiceWizard/partials/OptionBlock'
import { useMemo } from 'react'

interface ELanBandwidthProps {
  selectedPortSpeed: number | undefined
  selectedServiceSpeed: number
  bandwidthItems: CommittedDataRateOption[]
  onSelect: (serviceSpeed: CommittedDataRateOption) => void
  existingPortId?: number
  lag?: LAGProperties
}

const ELanBandwidth = ({
  selectedPortSpeed,
  selectedServiceSpeed,
  onSelect,
  bandwidthItems,
  existingPortId,
  lag,
}: ELanBandwidthProps) => {
  const setCommittedDataRate = (rate: CommittedDataRateOption) => onSelect(rate)

  const serviceSpeeds: CommittedDataRateOption[] = useMemo(() => {
    if (existingPortId || lag !== undefined) {
      return bandwidthItems
    } else if (bandwidthItems && selectedPortSpeed) {
      return bandwidthItems.filter((item: CommittedDataRateOption) =>
        BandwidthValues[selectedPortSpeed].some(
          (elem) => elem === item.speedInMbps
        )
      )
    } else {
      return []
    }
  }, [bandwidthItems, selectedPortSpeed, lag, existingPortId])

  return (
    <div className="flex flex-col md:flex-row w-full space-between gap-x-0 md:gap-x-2 gap-y-2 md:gap-y-0 mt-2 max-w-full">
      {serviceSpeeds.map((rate: CommittedDataRateOption) => (
        <OptionBlock
          key={rate.speedInMbps}
          onClick={() => setCommittedDataRate(rate)}
          isActive={rate.speedInMbps === selectedServiceSpeed}
          cy="eLan-endpoint-bandwidth"
        >
          <div className="flex flex-row md:flex-col items-center">
            <div className="text-strong md:text-heading-4 text-brand-4 mb-0.5 mr-1 md:mr-0">
              {numberFormat(rate.speed, { decimals: 0 })}
            </div>
            <div className="text-strong text-brand-4">{rate.unit}</div>
          </div>

          <div className="flex flex-row md:flex-col justify-between md:justify-center">
            <div className="text-neutral-40 text-fineprint mb-0 md:mb-0.5">
              Monthly Cost
            </div>
            <div className="text-strong text-brand-5">
              {price(Number(rate.mrc?.amount))}
            </div>
          </div>
        </OptionBlock>
      ))}
    </div>
  )
}

export default ELanBandwidth
