import { FC } from 'react'

import {
  CommittedDataRateOption,
  PortSpeedOption,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import OptionBlock from '@/pages/ServiceWizard/partials/OptionBlock'
import {
  AggregatedCommitTotals,
  ExtensionWizardPricesResponse,
  SpeedMbps2,
} from '@/lib/definitions/api.types'
import { price, generateBandwidthsData } from '@/lib/misc/Utils'


interface CommittedDataRateSelectorProps {
  serviceSpeeds?: SpeedMbps2[]
  bandwidthsItems?: Record<string, CommittedDataRateOption>
  aggregatedCommitTotals?: AggregatedCommitTotals
  selectedPortSpeed?: PortSpeedOption
  selectedServiceSpeed?: CommittedDataRateOption
  extensionPrices?: ExtensionWizardPricesResponse
  hasAggregatedBilling?: boolean
  selectServiceSpeed: (serviceSpeed: CommittedDataRateOption) => void
}


const CommittedDataRateSelector: FC<CommittedDataRateSelectorProps> = (
  {
    serviceSpeeds,
    selectServiceSpeed,
    bandwidthsItems,
    aggregatedCommitTotals,
    selectedPortSpeed,
    extensionPrices,
    hasAggregatedBilling,
    selectedServiceSpeed
  }) => {
  return (
    <div className="grid grid-cols-1 gap-2.5 md:grid-cols-4 md:gap-4">
      {serviceSpeeds?.map((serviceSpeed) => {
        const aggregatedSpeedInMbps = (bandwidthsItems?.[serviceSpeed].speedInMbps || 0) + (aggregatedCommitTotals?.speed || 0)
        const aggregatedSpeed = generateBandwidthsData(aggregatedSpeedInMbps)
        const aggregatedMrc = Number(aggregatedCommitTotals?.bandwidths[aggregatedSpeedInMbps].mrc.amount || 0)

        // mrc is used only if no aggregation is selected, aggregation is not allowed with extension
        let mrc = Number(bandwidthsItems?.[serviceSpeed].mrc?.amount)
        if (extensionPrices?.bandwidths && selectedPortSpeed?.speedInMbps) {
          mrc += Number(extensionPrices?.bandwidths[selectedPortSpeed?.speedInMbps]?.mrc?.amount)
        }

        return (
          <OptionBlock
            key={serviceSpeed}
            onClick={() =>
              // @ts-ignore
              selectServiceSpeed(bandwidthsItems?.[serviceSpeed])
            }
            isActive={
              selectedServiceSpeed?.speed ===
              bandwidthsItems?.[serviceSpeed].speed
            }
            cy="service-speed-block"
            isFluid
            className="text-center"
          >
            <span className="text-brand-4 text-heading-4 mt-auto mb-2.5 md:mb-8">
              {hasAggregatedBilling && !!aggregatedCommitTotals && "+"}
              {bandwidthsItems?.[serviceSpeed].speed}{' '}
              {bandwidthsItems?.[serviceSpeed].unit}{' '}
              {hasAggregatedBilling && !!aggregatedCommitTotals && (
                <p>(Total: {aggregatedSpeed.speed} {aggregatedSpeed.unit})</p>
              )}
            </span>
            <span className="text-brand-5 text-strong mt-1 mb-1 md:mb-4">
              {hasAggregatedBilling && !!aggregatedCommitTotals ? (
                <>{price(aggregatedMrc - (Number(aggregatedCommitTotals?.mrc.amount || 0)))}</>
              ) : (
                price(mrc)
              )}
              {hasAggregatedBilling && !!aggregatedCommitTotals &&
                <p>(Total: {price(aggregatedMrc)})</p>
              }
              {' '}
              MRC
            </span>
          </OptionBlock>
        )
      })}
    </div>
  )
}

export default CommittedDataRateSelector
