import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import ServiceWizard from '@/pages/ServiceWizard/ServiceWizard'
import { processExistingPorts } from '@/pages/ServiceWizard/PortsConversion'
import {
  CommittedDataRateOption,
  ContractTerm,
  ExistingPortItem,
  PortSpeedOption,
  PrefixOption,
  WizardTabItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import IpTransitWizardSummary from '@/pages/ip-transit/IpTransitWizardSummary'
import TabIpTransit from '@/pages/ip-transit/TabIpTransit'
import TabIpTransitPorts from '@/pages/ip-transit/TabIpTransitPorts'
import {
  emptyMrcNrcValue,
  portSpeedOptions,
  prefixV4,
  prefixV6,
} from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import FullPageSpinner from '@/components/FullPageSpinner'
import {
  IPTransit,
  PortTypes,
  Location,
  IPTransitWizardPricesRequest,
  IPTransitWizardPricesResponse,
  BgpAdvertisementTypes,
  UserNetworkInterface,
  UserNetworkInterfaceOnExistingPort,
  UserNetworkInterfaceOnExistingLAG,
} from '@/lib/definitions/api.types'
import { ServiceWizardContractTerm } from '@/lib/definitions/constants'
import useIpTransitWizardState, {
  IpTransitWizardState,
} from '@/lib/hooks/useIpTransitWizardState'
import {
  fieldsAreSet,
  generateBandwidthsData,
  parseResponseErrorsAndNotify,
  scrollToTop,
  notifyExtensionsTermChange,
} from '@/lib/misc/Utils'
import { useApp } from '@/lib/provider/AppProvider'
import { ApiService } from '@/lib/services/ApiService'
import { NEW_SERVICE_ORDER } from '@/lib/provider/ServiceWizardProvider'
import { useLoadServiceComponents } from '@/lib/queries/Services.queries'
import Locations from '@/pages/ServiceWizard/partials/Locations'
import { IpTransitService } from '@/lib/services/IpTransitService'
import { ProductComponentIdentifiers } from '@/lib/definitions/types'
import { useAuth } from '@/lib/provider/AuthProvider'

const tabs: WizardTabItem[] = [
  { id: 'Location', label: 'Location' },
  { id: 'Ports', label: 'Ports' },
  { id: 'IpTransit', label: 'IP-Transit' },
  { id: 'Summary', label: 'Summary' },
]

export enum IpTransitTabSteps {
  Location = 1,
  Ports = 2,
  IpTransit = 3,
  Summary = 4,
}

export type IpTransitStep = keyof typeof IpTransitTabSteps

const apiService = new ApiService()
const ipTransit = new IpTransitService()

const IpTransit = () => {
  const [appState, dispatch] = useApp()
  const [auth] = useAuth()
  const navigate = useNavigate()
  const { ipTransitWizardState, updateTransitWizard, resetIpTransitWizard } =
    useIpTransitWizardState()

  const wrapperRef = useRef<null | HTMLDivElement>(null)
  const [isPreviousStepDisabled, setIsPreviousStepDisabled] = useState(true)
  const [isPreviousHidden, setIsPreviousHidden] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [wizardPrices, setWizardPrices] =
    useState<IPTransitWizardPricesResponse>()
  const [isUsingExtensions, setIsUsingExtensions] = useState(false)

  const {
    activeTab,
    activeTabIndex,
  }: { activeTab: IpTransitStep; activeTabIndex: number } = useMemo(() => {
    const currentTab =
      ipTransitWizardState?.activeTab ?? (IpTransitTabSteps[1] as IpTransitStep)

    return {
      activeTab: currentTab,
      activeTabIndex: Number(IpTransitTabSteps[currentTab]),
    }
  }, [ipTransitWizardState])

  const startOrder = () => {
    let port:
      | UserNetworkInterface
      | UserNetworkInterfaceOnExistingPort
      | UserNetworkInterfaceOnExistingLAG

    const portCommonProps = {
      bandwidth: Number(ipTransitWizardState.selectedServiceSpeed?.speedInMbps),
      vlan_type: ipTransitWizardState.vlanType ?? 'Tagged',
      ...((ipTransitWizardState.vlanType === 'Tagged' && {
        vlan_id: Number(ipTransitWizardState.vlanId),
      }) ||
        {}),
    }

    if (ipTransitWizardState.selectedPortSpeed?.isLag) {
      port = {
        lag_id: ipTransitWizardState.existingPortId!,
        ...portCommonProps,
      }
    } else if (ipTransitWizardState.existingPortId) {
      port = {
        id: ipTransitWizardState.existingPortId,
        ...portCommonProps,
      }
    } else {
      port = {
        port_type: ipTransitWizardState.selectedPortSpeed?.name as PortTypes,
        location: ipTransitWizardState.selectedLocation!.name,
        ...(ipTransitWizardState.selectedLocation?.type === 'General' && {
          lag_member_count: ipTransitWizardState.lag?.memberCount,
          lag_name: ipTransitWizardState.lag?.name,
        }),
        ...portCommonProps,
      }
    }

    return ipTransit.createIpTransitOrder({
      term: ipTransitWizardState.contract!,
      port,
      bgpsession_asn: Number(ipTransitWizardState.as_number),
      bgpsession_as_set:
        ipTransitWizardState.as_set || `AS${ipTransitWizardState.as_number}`,
      bgpsession_password: ipTransitWizardState.password ?? '',
      bgpsession_prefix_limit_v4: Number(ipTransitWizardState.prefixLimitV4),
      bgpsession_prefix_limit_v6: Number(ipTransitWizardState.prefixLimitV6),
      prefix_v4_size: Number(ipTransitWizardState.selectedPrefixV4?.id),
      prefix_v6_size: Number(ipTransitWizardState.selectedPrefixV6?.id),
      purchase_reference: ipTransitWizardState.purchaseReference ?? '',
      outbound_advertisement:
        ipTransitWizardState.advertisement_type as BgpAdvertisementTypes,
      sync_from_pdb: !!ipTransitWizardState.syncFromPdb,
      aggregated_billing: ipTransitWizardState.hasAggregatedBilling,
    })
  }

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation(
    startOrder,
    {
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(
          error,
          'An internal error occurred while placing your order'
        )
        captureException(error)
      },
      onSuccess: async ({ data: serviceData }: { data: IPTransit }) => {
        if (serviceData) {
          dispatch({
            type: NEW_SERVICE_ORDER,
            payload: {
              ...serviceData,
            },
          })

          resetIpTransitWizard()
          navigate(`/services/ip-transit/${serviceData.id}`)
        }
      },
    }
  )

  const { mutate: getWizardPrices } = useMutation(
    (data: IPTransitWizardPricesRequest) => {
      return ipTransit.getIpTransitWizardPrices(data)
    },
    {
      onError: (error: any) => parseResponseErrorsAndNotify(error),
      onSuccess: ({ data }) => setWizardPrices(data),
    }
  )

  useEffect(() => {
    if (wizardPrices) {
      const portSpeedItems: PortSpeedOption[] = []
      const prefixV4Items: PrefixOption[] = []
      const prefixV6Items: PrefixOption[] = []

      const bandwidthsItems: Record<string, CommittedDataRateOption> =
        Object.keys(wizardPrices.bandwidths).reduce(
          (result: Record<string, CommittedDataRateOption>, key) => {
            result[key] = {
              ...wizardPrices.bandwidths[key],
              ...generateBandwidthsData(Number(key)),
            }
            return result
          },
          {}
        )
      portSpeedOptions.map((port) => {
        const newPort = wizardPrices.ports[port.speedInMbps]
        if (port.type !== 'LR1' || wizardPrices.allow_LR1) {
          const portToAdd = { ...port, ...newPort }
          portSpeedItems.push(portToAdd)
        }
      })

      Object.keys(wizardPrices.prefixes).map((prefix) => {
        if (ipTransitWizardState.prefixV4Ids?.includes(Number(prefix))) {
          const mockItem = prefixV4.find((item) => item.id === Number(prefix))

          if (!mockItem) return

          prefixV4Items.push({
            ...mockItem,
            ...wizardPrices.prefixes[prefix],
          })
        } else if (ipTransitWizardState.prefixV6Ids?.includes(Number(prefix))) {
          const mockItem = prefixV6.find((item) => item.id === Number(prefix))

          if (!mockItem) return

          prefixV6Items.push({
            ...mockItem,
            ...wizardPrices.prefixes[prefix],
          })
        }
      })

      updateTransitWizard({
        portSpeedItems,
        prefixV4Items,
        prefixV6Items,
        bandwidthsItems,
        availableServiceBandwidths: wizardPrices.available_service_bandwidths,
        selectedPrefixV4: prefixV4Items?.find((el) => el.id === 31),
        selectedPrefixV6: prefixV6Items?.find((el) => el.id === 127),
        aggregatedCommitTotals: wizardPrices.aggregated_commit_totals,
      })
    }
  }, [
    updateTransitWizard,
    wizardPrices,
    ipTransitWizardState.existingPortId,
    ipTransitWizardState.existingPorts,
    ipTransitWizardState.prefixV4Ids,
    ipTransitWizardState.prefixV6Ids,
    ipTransitWizardState.selectedPortSpeed,
  ])

  useEffect(() => {
    let portSpeed = ipTransitWizardState.selectedPortSpeed?.speedInMbps ?? 0

    const lagMemberCount = ipTransitWizardState.lag?.memberCount ?? 1
    if (lagMemberCount > 1) {
      portSpeed *= lagMemberCount
    }

    getWizardPrices({
      port_speed: portSpeed,
      currency: 'EUR',
      location: ipTransitWizardState.selectedLocation?.name,
      terms: ipTransitWizardState.contract || ServiceWizardContractTerm,
    })
  }, [
    getWizardPrices,
    ipTransitWizardState.selectedLocation,
    ipTransitWizardState.contract,
    ipTransitWizardState.selectedPortSpeed,
    ipTransitWizardState.lag,
  ])

  const {
    isLoading: isLoadingIpTransitOptions,
    isError: ipTransitOptionsLoadingError,
  } = useQuery(
    ['ipTransitOptions'],
    async () => {
      const response = await apiService.getIpTransitWizardOptions()
      if (!response) return null

      const {
        locations,
        v4prefix,
        v6prefix,
        customer_peeringdb: peeringdbInfo,
      } = response.data

      let updatedStateFields: IpTransitWizardState = {
        locations,
        prefixV4Ids: v4prefix,
        prefixV6Ids: v6prefix,
      }

      if (peeringdbInfo[0] && !ipTransitWizardState.as_number) {
        updatedStateFields = {
          ...updatedStateFields,
          as_number: peeringdbInfo[0].asn,
          as_set: auth.user.company?.as_set ?? peeringdbInfo[0].as_set,
          prefixLimitV4: peeringdbInfo[0].prefix_v4,
          prefixLimitV6: peeringdbInfo[0].prefix_v6,
          syncFromPdb: !!peeringdbInfo[0].asn,
        }
      }

      updateTransitWizard(updatedStateFields)
      return response
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(error)
        captureException(error)
      },
    }
  )

  const { data: customerExistingPorts } = useLoadServiceComponents({
    pco: [ProductComponentIdentifiers.LAG, ProductComponentIdentifiers.PORT],
  })

  const validateStepRequiredFields = useCallback(() => {
    const fields = ['selectedLocation']

    if (IpTransitTabSteps[activeTab] === IpTransitTabSteps.Ports) {
      fields.push(
        'selectedPrefixV4',
        'selectedPrefixV6',
        'selectedPortSpeed',
        'vlanType'
      )
      if (ipTransitWizardState.vlanType === 'Tagged') {
        fields.push('vlanId')
      }
      if (ipTransitWizardState.lag) {
        fields.push('lag.memberCount', 'lag.name')
      }
    }

    if (IpTransitTabSteps[activeTab] === IpTransitTabSteps.IpTransit) {
      fields.push(
        'as_number',
        'advertisement_type',
        'prefixLimitV4',
        'prefixLimitV6',
        'selectedServiceSpeed'
      )
    }

    return fieldsAreSet(ipTransitWizardState, fields)
  }, [activeTab, ipTransitWizardState])

  const handleTabChange = (newTabIndex: number) => {
    const newActiveTab: IpTransitStep = IpTransitTabSteps[
      newTabIndex
    ] as IpTransitStep
    if (newActiveTab) updateTransitWizard({ activeTab: newActiveTab })
  }

  const handleNextStep = async () => {
    const stepIndex: number = IpTransitTabSteps[activeTab]

    if (stepIndex === tabs.length) {
      placeOrder()
      return
    }

    handleTabChange(stepIndex + 1)
    scrollToTop(wrapperRef)
  }

  const handlePreviousStep = () => {
    const stepIndex = IpTransitTabSteps[activeTab]

    if (stepIndex > 1) {
      handleTabChange(stepIndex - 1)

      scrollToTop(wrapperRef)
    }
  }

  const handleSelectedLocation = (location: Location | undefined) => {
    const isExt = location?.type === 'Extension'
    const newState: IpTransitWizardState = {
      selectedLocation: location,
      existingPortId: undefined,
    }
    setIsUsingExtensions(isExt)
    if (ipTransitWizardState?.contract == 1 && isExt) {
      notifyExtensionsTermChange()
      newState.contract = 12
    }
    updateTransitWizard(newState)
  }

  useEffect(() => resetIpTransitWizard(), [resetIpTransitWizard])

  useEffect(() => {
    const existingPorts = processExistingPorts(
      // @ts-ignore
      customerExistingPorts,
      portSpeedOptions
    )
    updateTransitWizard({ existingPorts })
  }, [updateTransitWizard, customerExistingPorts])

  useEffect(() => {
    const stepIndex: number = IpTransitTabSteps[activeTab]

    setIsNextDisabled(
      isPlacingOrder ||
        !validateStepRequiredFields() ||
        (IpTransitTabSteps[activeTab] === IpTransitTabSteps.Summary &&
          !ipTransitWizardState.quote)
    )
    setIsPreviousStepDisabled(stepIndex === 1)
    setIsPreviousHidden(stepIndex === 1)
  }, [
    activeTab,
    ipTransitWizardState,
    isPlacingOrder,
    validateStepRequiredFields,
  ])

  const onExistingPortHandler = useCallback(
    (port: ExistingPortItem | undefined) => {
      if (!port) {
        updateTransitWizard({
          existingPortId: undefined,
          selectedLocation: undefined,
          selectedPortSpeed: undefined,
        })
        return
      }

      const selectedPortSpeed: PortSpeedOption = {
        name: port.portType,
        speedInMbps: port.portSpeed.speedInMbps,
        id: port.id,
        speed: port.portSpeed.speed,
        unit: port.portSpeed.unit,
        type: port.portSpeed.type,
        isLag: port.isLag,
        nrc: emptyMrcNrcValue,
        mrc: emptyMrcNrcValue,
      }

      updateTransitWizard({
        existingPortId: port.id,
        selectedLocation: port.location,
        selectedPortSpeed,

        ...(port.isLag && {
          lag: {
            memberCount: port.lagCount,
            name: port.name,
          },
        }),
      })
    },
    [updateTransitWizard]
  )

  const setContractTerm = (term: ContractTerm) =>
    updateTransitWizard({ contract: term.period })

  if (isLoadingIpTransitOptions || !appState.waffleFlags)
    return <FullPageSpinner />

  if (ipTransitOptionsLoadingError) return <div>An error occurred</div>

  if (!ipTransitWizardState) return <div />

  return (
    <ServiceWizard
      pageTitle="Add an IP Transit Service"
      isSummaryStep={IpTransitTabSteps[activeTab] === IpTransitTabSteps.Summary}
      contract={ipTransitWizardState.contract}
      wrapperRef={wrapperRef}
      tabs={tabs}
      activeTabIndex={activeTabIndex}
      handlePreviousStep={handlePreviousStep}
      handleNextStep={handleNextStep}
      isPreviousStepDisabled={isPreviousStepDisabled}
      isNextDisabled={isNextDisabled}
      isPreviousHidden={isPreviousHidden}
      setContractTerm={setContractTerm}
      summarySection={<IpTransitWizardSummary />}
      isUsingExtensions={isUsingExtensions}
    >
      {IpTransitTabSteps[activeTab] === IpTransitTabSteps.Location && (
        <Locations
          title="Find your desired location"
          selectedLocation={ipTransitWizardState.selectedLocation?.id}
          existingPorts={ipTransitWizardState.existingPorts}
          selectedExistingPort={ipTransitWizardState.existingPortId}
          setSelectedLocation={handleSelectedLocation}
          setExistingPort={onExistingPortHandler}
        />
      )}
      {IpTransitTabSteps[activeTab] === IpTransitTabSteps.Ports && (
        <TabIpTransitPorts />
      )}
      {IpTransitTabSteps[activeTab] === IpTransitTabSteps.IpTransit && (
        <TabIpTransit />
      )}
    </ServiceWizard>
  )
}

export default IpTransit
