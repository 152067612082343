import axios, { AxiosPromise } from 'axios'

import { apiUrl, getAxiosRequestConfigWithHeaders } from '@/lib/misc/Utils'

interface LoginRequest {
  email: string
  password: string
  two_factor_auth_code?: string
  use_recovery_code?: boolean
}

interface SignUpRequest {
  name: string
  email: string
  password1: string
  password2: string
}

export interface LoginResponse {
  access_token: string
  refresh_token: string
  two_factor_auth: boolean
  user: {
    pk: string
    username: string
    email: string
    first_name: string
    last_name: string
  }
}

export class AuthService {
  getRefreshToken(): string | null {
    return localStorage.getItem('rekey')
  }

  login(data: LoginRequest) {
    return axios.post(apiUrl('/api/v1/auth/login'), data)
  }

  signup(data: SignUpRequest): AxiosPromise {
    return axios.post(apiUrl('/auth/registration/'), data)
  }

  logout(): AxiosPromise {
    localStorage.removeItem('customerId')
    return axios.post(apiUrl('/auth/logout/'))
  }

  clear() {
    localStorage.clear()
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken()

    return axios.post(apiUrl('/api/v1/auth/refresh'), {
      refresh: refreshToken,
    })
  }

  getUser(): AxiosPromise | null {
    const configs = getAxiosRequestConfigWithHeaders()

    return Object.entries(configs).length
      ? axios.get(apiUrl('/api/v1/user/me'), configs)
      : null
  }
}
