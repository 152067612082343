import { formatContractTerm } from '@/lib/misc/Utils'

interface SummaryContractTermProps {
  term?: number
}

const SummaryContractTerm = ({ term = 24 }: SummaryContractTermProps) => (
  <div className="flex flex-row items-center justify-between mt-4">
    <h3 className="text-white text-heading-byline">Contract term</h3>
    <span className="font-text text-brand-3 whitespace-nowrap">
      {formatContractTerm(term)}
    </span>
  </div>
)

export default SummaryContractTerm
