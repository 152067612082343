import { useCallback } from 'react'
import {
  CommittedDataRateOption,
  ExistingPort,
  PortSpeedOption,
  PrefixOption,
  LAGProperties,
} from '@/pages/ServiceWizard/ServiceWizard.types'

import {
  AggregatedCommitTotals,
  BillingType,
  ExtensionWizardPricesResponse,
  IPAccessPriceResponse,
  Location,
  ServiceComponentVLANType,
  SpeedMbps2,
} from '@/lib/definitions/api.types'
import { IpAccessTabSteps } from '@/pages/ip-access/IpAccess'
import {
  IP_ACCESS_WIZARD,
  RESET_IP_ACCESS,
  useServiceWizard,
} from '@/lib/provider/ServiceWizardProvider'

export interface IpAccessWizardState {
  contract?: number
  activeTab?: IpAccessTabSteps
  locations?: Location[]
  portSpeedItems?: PortSpeedOption[]
  prefixV4Items?: PrefixOption[]
  bandwidthsItems?: Record<string, CommittedDataRateOption>
  existingPorts?: ExistingPort[]
  existingPortId?: number
  selectedLocation?: Location
  selectedPortSpeed?: PortSpeedOption
  selectedPortType?: string
  selectedPrefixV4?: number
  selectedServiceSpeed?: CommittedDataRateOption
  availableServiceBandwidths?: { [k: string]: SpeedMbps2[] }
  vlanId?: number
  vlanType?: ServiceComponentVLANType
  quote?: IPAccessPriceResponse
  purchaseReference?: string
  serviceSpeedType?: BillingType
  extensionPrices?: ExtensionWizardPricesResponse
  lag?: LAGProperties
  hasAggregatedBilling?: boolean
  aggregatedCommitTotals?: AggregatedCommitTotals
}

const useIpAccessWizardState = () => {
  const { state, dispatch } = useServiceWizard()

  const ipAccessWizardState: IpAccessWizardState = state.ipAccessWizard

  const updateIpAccessWizard = useCallback((payload: IpAccessWizardState) => {
    dispatch({
      type: IP_ACCESS_WIZARD,
      payload: {
        ...payload,
      },
    })
  }, [dispatch])

  const resetIpAccessWizard = useCallback(() => {
    dispatch({
      type: RESET_IP_ACCESS,
    })
  }, [dispatch])

  return {
    updateIpAccessWizard,
    ipAccessWizardState,
    resetIpAccessWizard,
  }
}

export default useIpAccessWizardState
