import { captureException } from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { useMutation } from 'react-query'

import { ELanEndpoint } from '@/pages/ServiceWizard/ServiceWizard.types'
import { ELanTabSteps } from '@/pages/elan/ELan'
import { GenericErrors } from '@/lib/definitions/generic.types'
import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { Notify } from '@/lib/misc/Notify'
import { FlexEthernetService } from '@/lib/services/FlexEthernetService'
import ServiceWizardSummary from '@/pages/ServiceWizard/ServiceWizardSummary'
import SummaryLocation from '@/pages/elan/partials/SummaryLocation'
import SummaryContractTerm from '@/pages/ServiceWizard/partials/SummaryContractTerm'
import SummaryEndpoints from '@/pages/elan/partials/SummaryEndpoints'
import ELanSummaryPricing from '@/pages/elan/partials/ELanSummaryPricing'

const flexEthernetService = new FlexEthernetService()

const ELanWizardSummary = () => {
  const { eLanWizardState, updateELanWizard } = useELanWizardState()

  const isExtended: boolean = useMemo(() => {
    if (!eLanWizardState.activeTab) return false

    return (
      Number(ELanTabSteps[eLanWizardState.activeTab]) === ELanTabSteps.Summary
    )
  }, [eLanWizardState.activeTab])

  const requestPrice = ({
    endpoint,
  }: {
    endpoint: ELanEndpoint
    endpointIndex: number
  }) =>
    flexEthernetService.requestFlexEthernetPriceSummary({
      location: endpoint.selectedLocation,
      bandwidth: endpoint.selectedServiceSpeed!, // @TODO remove the !
      // @ts-ignore
      port: endpoint.existingPortId ? undefined : endpoint.selectedPortName,
      term: eLanWizardState.contract,
      lag_member_count: endpoint.lag?.memberCount,
    })

  const { mutate: requestPriceQuote } = useMutation(requestPrice, {
    onError: (error: GenericErrors) => {
      Notify.error(error?.message ?? 'An error occurred')
      captureException(error)
    },
    onSuccess: async ({ data }, { endpointIndex }) => {
      if (!data) {
        Notify.error('An error occurred while getting your order quote')
        return
      }

      const quotes = eLanWizardState.quotes
      quotes[endpointIndex] = data

      updateELanWizard({ quotes })
    },
  })

  useEffect(() => {
    if (eLanWizardState.endpoints.length) {
      eLanWizardState.endpoints.forEach((endpoint, endpointIndex) =>
        requestPriceQuote({ endpoint, endpointIndex })
      )
    }
  }, [requestPriceQuote, eLanWizardState.endpoints, eLanWizardState.contract])

  return (
    <ServiceWizardSummary
      isExtended={isExtended}
      title="Flex Ethernet"
      summaryBody={
        <>
          <div className="flex flex-col">
            <SummaryContractTerm term={eLanWizardState?.contract} />
          </div>

          <SummaryLocation isExtended={false} />
        </>
      }
      extendedSummaryBody={
        <>
          <div className="flex flex-col px-7">
            <SummaryContractTerm term={eLanWizardState?.contract} />
          </div>

          <SummaryLocation isExtended />

          <div className="text-white text-heading-byline mb-2 -mt-4 px-8">
            Endpoints
          </div>
          {!!eLanWizardState.endpoints.length && <SummaryEndpoints />}
        </>
      }
      extendedSummarySidebar={<ELanSummaryPricing />}
    />
  )
}

export default ELanWizardSummary
