import { OptionsType } from '@/components/GenericOption'
import { Money } from "@/lib/definitions/api.types"
import {
  DDoSPrefix,
  DDoSPrefixSize,
  DDoSPrefixType,
  BandwidthOptions,
  PortSpeed,
  Prefix,
  ContractTerm,
} from '@/pages/ServiceWizard/ServiceWizard.types'

export const portSpeedOptions: PortSpeed[] = [
  {
    id: 1,
    speedInMbps: 1000,
    speed: 1,
    unit: 'Gbps',
    type: 'SFP',
    name: '1G-LX (SFP)',
  },
  {
    id: 2,
    speedInMbps: 10000,
    speed: 10,
    unit: 'Gbps',
    type: 'SFP+',
    name: '10G-LR (SFP+)',
  },
  {
    id: 3,
    speedInMbps: 25000,
    speed: 25,
    unit: 'Gbps',
    type: 'SFP28',
    name: '25G-LR (SFP28)',
  },
  {
    id: 4,
    speedInMbps: 100000,
    speed: 100,
    unit: 'Gbps',
    type: 'QSFP28',
    name: '100G-LR4 (QSFP28)',
  },
  {
    id: 5,
    speedInMbps: 100000,
    speed: 100,
    unit: 'Gbps',
    type: 'LR1',
    name: '100G-LR1 (QSFP28)',
  },
  {
    id: 6,
    speedInMbps: 400000,
    speed: 400,
    unit: 'Gbps',
    type: 'OSFP/QSFP-DD',
    name: '400G-LR4 (OSFP/QSFP-DD)',
  },
]

export const prefixV4: Prefix[] = [
  {
    id: 31,
    addresses: 2,
    isRecommended: true,
    note: 'Special case according to RFC 3021',
  },
  {
    id: 30,
    addresses: 4,
  },
  { id: 29, addresses: 8 },
  { id: 28, addresses: 16 },
  { id: 27, addresses: 32 },
  { id: 26, addresses: 64 },
  { id: 25, addresses: 128 },
]

export const prefixV6: Prefix[] = [
  {
    id: 127,
    addresses: 2,
    isRecommended: true,
    note: 'Special case according to RFC 3021',
  },
  {
    id: 126,
    addresses: 4,
  },
  { id: 125, addresses: 8 },
  { id: 124, addresses: 16 },
  { id: 123, addresses: 32 },
  { id: 122, addresses: 64 },
  { id: 121, addresses: 128 },
  { id: 120, addresses: 256 },
]

export const BandwidthValues: BandwidthOptions = {
  1: [100, 200, 300, 400, 600, 800, 1000],
  10: [1000, 2000, 3000, 4000, 6000, 8000, 10000],
  25: [2500, 5000, 7500, 10000, 15000, 20000, 25000],
  100: [10000, 20000, 30000, 40000, 60000, 80000, 100000],
  400: [40000, 80000, 120000, 160000, 240000, 320000, 400000],
}

export const contractTerms: ContractTerm[] = [
  { label: '1 Month', label_short: '1M', period: 1 },
  { label: '12 Months', label_short: '12M', period: 12 },
  { label: '24 Months', label_short: '24M', period: 24 },
  { label: '36 Months', label_short: '36M', period: 36 },
]

export const bgpTypeOptions: OptionsType[] = [
  { value: 'FULL_TABLE', label: 'Full Routing Table' },
  { value: 'DEFAULT_ROUTE', label: 'Default Route' },
  { value: 'FT_DR', label: 'Full Routing Table and Default Route' },
]

export const emptyMrcNrcValue: Money = {
  amount: '0',
  currency: 'EUR',
  display: '0.00 EUR',
}

export const defaultDDoSPrefixesList: DDoSPrefix[] = [
  {
    count: 4,
    type: DDoSPrefixType.IPv4,
    size: DDoSPrefixSize.SMALL,
    term: '12M',
  },
  {
    count: 1,
    type: DDoSPrefixType.IPv6,
    size: DDoSPrefixSize.MEDIUM,
    term: '24M',
  },
]

export const ddosProtectionSizesPlans = [
  {
    size: DDoSPrefixSize.SMALL,
    benefits: [
      'Lorem ipsum dolor sit amet',
      'Consectetur adipiscing elit',
      'Sed do eiusmod tempor',
    ],
  },
  {
    size: DDoSPrefixSize.MEDIUM,
    benefits: [
      'Lorem ipsum dolor sit amet',
      'Consectetur adipiscing elit',
      'Sed do eiusmod tempor',
      'Gives you wings',
    ],
  },
  {
    size: DDoSPrefixSize.LARGE,
    benefits: [
      'Lorem ipsum dolor sit amet',
      'Consectetur adipiscing elit',
      'Sed do eiusmod tempor',
      'Incididunt ut labore et dolore',
      'Hoch die Hände, Wochenende',
    ],
  },
]
