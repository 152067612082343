import Button from '@/components/Button'
import { Select } from '@/components/SelectInputs'
import useDDoSProtectionWizardState from '@/lib/hooks/useDDoSProtectionWizardState'
import {
  contractTerms,
  defaultDDoSPrefixesList,
} from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import InputNumber from '@/components/InputNumber'
import { DDoSPrefixSize, DDoSPrefixType } from '@/pages/ServiceWizard/ServiceWizard.types'
import DDoSSizesFeatures from '@/pages/ddos-protection/partials/DDoSSizesPlans'
import DeleteIcon from '@icons/trash'
import { classNames } from '@/lib/misc/Utils'
import { useLoadAsSetSize } from '@/lib/queries/Services.queries'
import { useEffect, useState } from 'react'
import { useLoadPeeringDBInfo } from '@/lib/queries/CompanyProfile.queries'

const TabPrefixes = () => {
  const { ddosProtectionWizardState, updateDDoSProtectionWizard } =
    useDDoSProtectionWizardState()
  const [asSet, setAsSet] = useState<string>('')

  const { data: peeringDBData } = useLoadPeeringDBInfo()
  const { data: asSetSize, refetch: loadAsSetSize } = useLoadAsSetSize([asSet])

  const addPrefix = () => {
    updateDDoSProtectionWizard({
      prefixes: [
        ...ddosProtectionWizardState.prefixes,
        {
          count: 1,
          type: DDoSPrefixType.IPv4,
          size: DDoSPrefixSize.MEDIUM,
          term: '12M',
        },
      ],
    })
  }

  const updateDDoSPrefixes = (
    index: number,
    param: {
      [key: string]: any
    }
  ) => {
    updateDDoSProtectionWizard({
      prefixes: ddosProtectionWizardState.prefixes.map((prefix, prefixIndex) =>
        prefixIndex === index ? { ...prefix, ...param } : prefix
      ),
    })
  }

  const handleSelection = (
    index: number,
    field: 'term' | 'size' | 'type' | 'count',
    value: string | number
  ) => updateDDoSPrefixes(index, { [field]: value })

  const handleCountChange = (
    index: number,
    count: number,
    isIncrementing: boolean
  ) => {
    if (count <= 1 && !isIncrementing) {
      updateDDoSPrefixes(index, { count: 1 })
      return
    }

    updateDDoSPrefixes(index, { count: isIncrementing ? count + 1 : count - 1 })
  }

  const populatePrefixCountsFromAsSet = () => {
    const asSetSizeInfo = asSetSize?.[asSet]

    if (asSetSizeInfo) {
      updateDDoSProtectionWizard({
        prefixes: defaultDDoSPrefixesList.map((prefix) => ({
          ...prefix,
          count:
            DDoSPrefixType.IPv4 === prefix.type
              ? asSetSizeInfo.ipv4
              : asSetSizeInfo.ipv6,
        })),
      })
    }
  }

  const handlePrefixDeletion = (prefixIndex: number) => {
    if (ddosProtectionWizardState.prefixes.length > 2) {
      updateDDoSProtectionWizard({
        prefixes: ddosProtectionWizardState.prefixes.toSpliced(prefixIndex, 1),
      })
    }
  }

  useEffect(() => {
    if (!!asSet) loadAsSetSize()
  }, [asSet, loadAsSetSize])

  useEffect(() => {
    if (!!peeringDBData?.as_set) setAsSet(peeringDBData.as_set)
  }, [peeringDBData, setAsSet])

  return (
    <div>
      <h2 className="text-brand-4 text-2xl leading-[120%] tracking-[-0.24px] mb-1 md:leading-[normal] md:tracking-[-0.48px]">
        Prefixes to Protect
      </h2>

      <div className="flex flex-col">
        {ddosProtectionWizardState.prefixes.map((prefix, index) => (
          <div
            className="flex flex-row justify-between items-center gap-4 mt-4"
            key={index}
          >
            <InputNumber
              className="w-full"
              cy="select-count"
              name="count"
              label="Count"
              value={prefix.count}
              onChange={(evt) =>
                handleSelection(index, 'count', Number(evt.target.value))
              }
              onIncrement={() => handleCountChange(index, prefix.count, true)}
              onDecrement={() => handleCountChange(index, prefix.count, false)}
            />
            <Select
              className="w-full"
              cy="select-type"
              name="type"
              label="Type"
              value={prefix.type}
              options={Object.values(DDoSPrefixType).map((type) => ({
                value: type,
                label: type,
              }))}
              setValue={(type) => handleSelection(index, 'type', type)}
              readOnly
              required
            />
            <Select
              className="w-full capitalize"
              cy="select-protection-size"
              name="protection-size"
              label="Size"
              value={prefix.size}
              options={Object.values(DDoSPrefixSize).map((size) => ({
                value: size,
                label: size,
              }))}
              setValue={(size) => handleSelection(index, 'size', size)}
              readOnly
              required
            />
            <Select
              className="w-full"
              cy="select-contract-term"
              name="contract-term"
              label="Contract Term"
              value={prefix.term}
              options={contractTerms.map((term) => ({
                value: term.label_short,
                label: term.label,
              }))}
              setValue={(term) => handleSelection(index, 'term', term)}
              readOnly
              required
            />

            <div
              className={classNames(
                'w-10 h-10',
                ddosProtectionWizardState.prefixes.length > 2
                  ? 'cursor-pointer'
                  : 'opacity-0'
              )}
            >
              <div
                className="hover:bg-red-100 rounded-full w-10 h-10 flex justify-center items-center"
                onClick={() => handlePrefixDeletion(index)}
              >
                <DeleteIcon className="text-red-700 text-lg" />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex mt-8 mb-4">
        <Button style="primary" onClick={addPrefix}>
          Add more prefixes
        </Button>
        <Button
          style="secondary"
          className="ml-6"
          onClick={populatePrefixCountsFromAsSet}
          disabled={!asSetSize?.[asSet]}
        >
          Populate prefixes from AS-SET
        </Button>
      </div>

      <DDoSSizesFeatures />
    </div>
  )
}

export default TabPrefixes
