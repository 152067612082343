import { useCallback, useMemo } from 'react'

import { IpTransitTabSteps } from '@/pages/ip-transit/IpTransit'
import { FieldTypes } from '@/pages/ServiceWizard/ServiceWizard.types'

import useIpTransitWizardState, {
  IpTransitWizardState,
} from '@/lib/hooks/useIpTransitWizardState'
import { classNames, dot } from '@/lib/misc/Utils'

import InformationCircleIcon from '@icons/information-circle'
import Tooltip from '@/components/Tooltip'

const bgpSessionFields: FieldTypes[] = [
  { key: 'as_number', label: 'AS-Number' },
  {
    key: 'as_set',
    label: 'AS-SET',
    defaultValue: (data: IpTransitWizardState) => {
      return data.as_set || `AS${data.as_number}`
    },
  },
  { key: 'advertisement_type', label: 'Route Advertisement' },
  { key: 'password', label: 'Session Password' },
  { key: 'prefixLimitV4', label: 'Prefix limit IPv4' },
  { key: 'prefixLimitV6', label: 'Prefix limit IPv6' },
]

const fieldsAreSet = (
  ipTransitWizardState: IpTransitWizardState,
  fields: FieldTypes[]
) => {
  return fields.some((field) => {
    const value = dot(ipTransitWizardState, field.key)
    return value !== undefined && value !== null && value !== ''
  })
}

const SummaryBgpSession = () => {
  const { ipTransitWizardState } = useIpTransitWizardState()

  const isExtended: boolean = useMemo(() => {
    if (!ipTransitWizardState.activeTab) return false

    return (
      Number(IpTransitTabSteps[ipTransitWizardState.activeTab]) ===
      IpTransitTabSteps.Summary
    )
  }, [ipTransitWizardState])

  const getFieldValue = useCallback(
    (field: FieldTypes) => {
      const value = dot(ipTransitWizardState, field.key)
      if (!value) {
        return !!field.defaultValue
          ? field.defaultValue(ipTransitWizardState)
          : undefined
      }

      if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No'
      }

      return value
    },
    [ipTransitWizardState]
  )

  return fieldsAreSet(ipTransitWizardState, bgpSessionFields) ? (
    <div className={classNames(!isExtended && 'mt-10')}>
      <h3 className="text-accent-40 text-heading-byline mb-2">BGP Session</h3>

      <div className="w-full flex flex-col">
        {bgpSessionFields.map((field) => {
          const val = getFieldValue(field)

          return !!val ? (
            <div className="flex justify-between mb-2" key={field.key}>
              <span className="font-text text-white">{field.label}</span>
              <span className="font-text text-brand-3 flex space-x-2">
                <span className="whitespace-nowrap">{val}</span>

                {field.key === 'as_set' && !ipTransitWizardState.as_set && (
                  <Tooltip
                    wrapperClassName="flex"
                    placement="right-arrow"
                    icon={<InformationCircleIcon />}
                  >
                    Using {val} because AS-SET is not provided
                  </Tooltip>
                )}
              </span>
            </div>
          ) : (
            <div key={field.key} />
          )
        })}
      </div>
    </div>
  ) : (
    <div />
  )
}

export default SummaryBgpSession
