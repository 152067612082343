import {
  InputHTMLAttributes,
  ReactElement,
  forwardRef,
  memo,
  useRef,
  useState,
} from 'react'

import Input, { BasicInputProps } from '@/components/GenericInput'
import GenericOption, { OptionsType } from '@/components/GenericOption'
import { useOnClickOutside } from '@/lib/hooks/useOnClickOutside'
import { classNames } from '@/lib/misc/Utils'
import InputSearch from '@/components/InputSearch'
import ChevronDownIcon from '@icons/chevron-down'

interface SelectInputProps
  extends BasicInputProps,
    InputHTMLAttributes<HTMLInputElement> {
  options: OptionsType[]
  setValue: (val: string, option?: OptionsType) => void
  onReset?: () => void
  icon?: ReactElement
  isSmall?: boolean
}

interface GenericSelectProps extends SelectInputProps {
  inputType?: 'standard' | 'search'
}

const GenericSelect = forwardRef<HTMLInputElement, GenericSelectProps>(
  (
    {
      cy,
      className,
      options,
      inputType = 'standard',
      setValue,
      onReset,
      icon,
      isSmall,
      ...props
    },
    ref
  ) => {
    const [isOpened, setIsOpened] = useState(false)
    const containerRef = useRef(null)

    useOnClickOutside(containerRef, () => {
      setIsOpened(false)
    })

    const onSelectOptionHandler = (val: string, option?: OptionsType) => {
      setValue(val, option)
      setIsOpened(false)
    }

    return (
      <div className={classNames('relative', className)} ref={containerRef}>
        {inputType === 'standard' ? (
          <Input
            {...props}
            cy={cy}
            ref={ref}
            onFocus={() => setIsOpened(true)}
            onClick={() => setIsOpened(true)}
            autoComplete="off"
            style={props.readOnly ? { cursor: 'default' } : {}}
            icon={icon}
            isSmall={isSmall}
          />
        ) : (
          <InputSearch
            {...props}
            cy={cy}
            ref={ref}
            showSearchIcon={!options?.length}
            onFocus={() => setIsOpened(true)}
            onClick={() => setIsOpened(true)}
            onReset={() => {
              if (onReset) {
                onReset()
              }
            }}
          />
        )}
        {!!options?.length && (inputType !== 'search' || !props.value) && (
          <div className="absolute -translate-y-2/4 right-3 top-2/4 z-20">
            <ChevronDownIcon
              onClick={() => setIsOpened(!isOpened)}
              className={classNames(
                'text-neutral-40 transition-transform duration-[0.2s] ease-[ease-in-out] rotate-0',
                isOpened && '!-rotate-180'
              )}
            />
          </div>
        )}
        {isOpened && !!options?.length && (
          <GenericOption
            className="max-h-[170px] overflow-auto absolute right-0 left-0 top-[110%] z-30 w-full shadow-xl bg-white border rounded-lg border-solid border-neutral-20"
            cy={cy}
            options={options}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            onSelectOption={onSelectOptionHandler}
          />
        )}
      </div>
    )
  }
)

const Select = memo(
  forwardRef<HTMLInputElement, SelectInputProps>((props, ref) => {
    return <GenericSelect {...props} readOnly={true} ref={ref} />
  })
)

const Autocomplete = memo(
  forwardRef<HTMLInputElement, SelectInputProps>((props, ref) => {
    return <GenericSelect {...props} ref={ref} />
  })
)

const SearchInput = memo(
  forwardRef<HTMLInputElement, SelectInputProps>((props, ref) => {
    return <GenericSelect {...props} inputType="search" ref={ref} />
  })
)

export { Select, Autocomplete, SearchInput }
export type { GenericSelectProps }
