import { FC, memo, useCallback, useMemo } from 'react'

import PortSpeeds from '@/pages/ServiceWizard/partials/PortSpeeds'
import {
  PortSpeedOption,
  LAGProperties,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import LAGCreateForm from '@/pages/ServiceWizard/partials/LAGCreateForm'
import useIpAccessWizardState from '@/lib/hooks/useIpAccessWizardState'

const TabIpAccessPorts: FC = () => {
  const { ipAccessWizardState, updateIpAccessWizard } = useIpAccessWizardState()

  const { portSpeedItems, selectedPortSpeed, selectedLocation, lag } =
    ipAccessWizardState

  const selectPortSpeed = (port: any, portType: string) => {
    updateIpAccessWizard({
      selectedPortSpeed: port,
      selectedServiceSpeed: undefined,
      selectedPortType: portType,
    })
  }

  const setLagInfo = useCallback(
    (lag: LAGProperties | undefined) =>
      updateIpAccessWizard({
        lag,
      }),
    [updateIpAccessWizard]
  )

  const isPortSpeedSetupCostShown = useMemo(() => {
    if (!portSpeedItems) return false

    return (
      portSpeedItems.filter((item: any) => Number(item.nrc.amount) > 0).length >
      0
    )
  }, [portSpeedItems])

  const availablePortSpeedItems = useMemo(() => {
    if (!portSpeedItems) return []
    if (!selectedLocation) return portSpeedItems

    return portSpeedItems.filter(
      (speed: PortSpeedOption) =>
        speed.speedInMbps <= selectedLocation.bandwidth
    )
  }, [portSpeedItems, selectedLocation])

  return (
    <div className="w-full gap-y-10 flex flex-col">
      <h1 className="text-heading-4 tracking-tightest text-brand-1">
        Configure your port
      </h1>

      <PortSpeeds
        portsClassName="gap-2 flex-wrap"
        speeds={availablePortSpeedItems}
        selectPortSpeed={selectPortSpeed}
        selectedPortSpeed={selectedPortSpeed}
        isSetupCostShown={isPortSpeedSetupCostShown}
        isExtension={selectedLocation?.type === 'Extension'}
      />

      {selectedLocation?.type === 'General' && (
        <LAGCreateForm values={lag} setLagInfo={setLagInfo} />
      )}
    </div>
  )
}

export default memo(TabIpAccessPorts)
