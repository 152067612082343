import {
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  forwardRef,
  memo,
} from 'react'

import FormError from '@/components/FormError'
import { classNames } from '@/lib/misc/Utils'
import LoadingSpinner from '@/components/LoadingSpinner'
import { GenericObject } from '@/lib/definitions/generic.types'
import useParseFormErrors from '@/lib/hooks/useParseFormErrors'

const boxClasses = {
  base: 'h-full w-full px-3 border bg-white rounded-lg border-solid border-neutral-70 peer-focus:border-2 peer-focus:border-brand-5 peer-hover:border-neutral-40 hover:border-neutral-40 peer-disabled:bg-neutral-10 peer-disabled:border-neutral-70 peer-disabled:pointer-events-none',
  error:
    '!border-brand-6 focus:border-brand-6 focus:border-2 hover:border-brand-6',
} as const

const inputClasses = {
  base: 'peer appearance-none text-auxiliary-3 text-base focus:outline-none absolute bottom-2 left-3 right-3 autofill:bg-inherit w-[-moz-available]',
  center: '-translate-y-2/4 top-2/4',
} as const

export interface BasicInputProps {
  className?: string
  cy?: string
  label?: string
  errors?: (string | undefined | GenericObject)[]
  icon?: ReactElement
}

export interface InputProps
  extends BasicInputProps,
    InputHTMLAttributes<HTMLInputElement> {
  classicLabel?: string
  children?: ReactNode
  loading?: boolean
  isSmall?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      classicLabel,
      cy,
      errors,
      children,
      loading,
      type = 'text',
      name,
      icon,
      isSmall,
      ...props
    },
    ref
  ) => {
    const { parsedErrors } = useParseFormErrors(errors, name)

    return (
      <div className={className}>
        {!!classicLabel && (
          <div className="text-neutral-40 mb-1">{classicLabel}</div>
        )}
        {!!icon && (
          <div className="absolute -translate-y-2/4 pl-2 top-2/4 z-20">
            {icon}
          </div>
        )}
        <label
          className={classNames(
            'relative flex justify-center items-center',
            isSmall ? 'h-[40px]' : 'h-[50px]'
          )}
        >
          <input
            data-cy={cy ? cy : !!name && `input-${name}`}
            className={classNames(
              inputClasses.base,
              !!icon && 'pl-6',
              (type === 'search' || !!classicLabel || !label) &&
                inputClasses.center,
              !!props.value && !!label && '-translate-y-[1px]'
            )}
            placeholder={props.placeholder || ' '}
            ref={ref}
            type={type}
            name={name}
            {...props}
          />
          <div
            className={classNames(
              boxClasses.base,
              !!parsedErrors.length && boxClasses.error
            )}
          />
          {!!label && (
            <span
              className={classNames(
                'absolute duration-300 text-neutral-50 text-xs left-3 -translate-y-2/4 top-3 peer-placeholder-shown:text-base peer-placeholder-shown:top-2/4 peer-focus:top-3 peer-focus:text-xs peer-autofill:text-xs peer-autofill:top-3',
                !!props.placeholder && '!text-xs !top-3'
              )}
            >
              {label}
              {!!props.required && !label.includes('*') && '*'}
            </span>
          )}
          {children}
          {loading && (
            <div className="absolute -translate-y-2/4 right-3 top-2/4 z-20">
              <LoadingSpinner className="h-5 w-5 text-accent-40" />
            </div>
          )}
        </label>
        {parsedErrors.map(
          (error, index) =>
            !!error && (
              <FormError
                cy={
                  cy
                    ? `form-errors-${cy}`
                    : !!name
                      ? `form-errors-${name}`
                      : undefined
                }
                key={index}
              >
                {error}
              </FormError>
            )
        )}
      </div>
    )
  }
)

export default memo(Input)
