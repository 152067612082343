import { ReactNode } from 'react'

import { classNames } from '@/lib/misc/Utils'

interface OptionBlockProps {
  children: ReactNode
  isActive: boolean
  isFluid?: boolean
  className?: string
  onClick: () => void
  cy?: string
}

const OptionBlock = ({
  children,
  isActive,
  isFluid,
  onClick,
  className,
  cy,
}: OptionBlockProps) => (
  <div
    data-cy={cy}
    onClick={onClick}
    className={classNames(
      'relative border bg-white transition-all duration-300 flex flex-col gap-y-0 cursor-pointer rounded-lg box-border',
      isFluid
        ? 'p-3 md:gap-y-2'
        : 'w-full md:w-32 text-center px-2.5 py-5 md:gap-y-4',
      isActive
        ? 'border-brand-5 ring-2 ring-brand-5 shadow-block shadow-auxiliary-3/10'
        : 'border-neutral-20 hover:shadow-block',
      className
    )}
  >
    {children}
  </div>
)

export default OptionBlock
