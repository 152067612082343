import React, {
  Dispatch,
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { classNames } from '@/lib/misc/Utils'

export type OptionsType = {
  value: string
  label: string
  [key: string]: string | boolean
}

interface GenericOptionProps {
  cy?: string
  className?: string
  options: OptionsType[]
  isOpened: boolean
  setIsOpened: Dispatch<React.SetStateAction<boolean>>
  onSelectOption: (val: string, option?: OptionsType) => void
}

const GenericOption: FC<GenericOptionProps> = ({
  cy,
  className,
  isOpened,
  options,
  setIsOpened,
  onSelectOption,
}) => {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(-1)
  const selectedOptionRef = useRef<HTMLLIElement>(null)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isOpened) return

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault()
          setSelectedOptionIndex((prevIndex) =>
            prevIndex > 0 && prevIndex !== 0 ? prevIndex - 1 : 0
          )
          if (selectedOptionRef.current) {
            selectedOptionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            })
          }
          break
        case 'ArrowDown':
          event.preventDefault()
          setSelectedOptionIndex((prevIndex) =>
            prevIndex < options.length - 1 ? prevIndex + 1 : options.length - 1
          )
          if (selectedOptionRef.current) {
            selectedOptionRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
          break
        case 'Enter':
          event.preventDefault()
          if (options[selectedOptionIndex]) {
            onSelectOption(
              options[selectedOptionIndex].value,
              options[selectedOptionIndex]
            )
          }
          setIsOpened(false)
          break
        case 'Escape':
          event.preventDefault()
          setIsOpened(false)
          break
        default:
          break
      }
    },
    [isOpened, options, selectedOptionIndex, onSelectOption, setIsOpened]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [handleKeyDown])

  return (
    <ul className={className}>
      {options.map((el, index) => (
        <li
          data-cy={`${cy}-${el.value}`}
          key={el.value}
          ref={index === selectedOptionIndex ? selectedOptionRef : null}
          onClick={() => onSelectOption(el.value, el)}
          className={classNames(
            'py-2 px-3 first:pt-4 last:pb-4 hover:bg-neutral-10 cursor-pointer',
            index === selectedOptionIndex && 'bg-neutral-10'
          )}
        >
          {el.label}
        </li>
      ))}
    </ul>
  )
}

export default memo(GenericOption)
