import { Suspense, lazy, useEffect, useMemo } from 'react'
import { Route, RouteObject, Routes } from 'react-router-dom'

import '@/App.css'
import FullPageSpinner from '@/components/FullPageSpinner'
import NoCustomerFound from '@/components/NoCustomerFound'
import PageTitle from '@/components/PageTitle'
import RedirectInaccessiblePages from '@/components/RedirectInaccessiblePages'
import ErrorBoundary from '@/lib/misc/ErrorBoundary'
import { useApp } from '@/lib/provider/AppProvider'
import { useAuth } from '@/lib/provider/AuthProvider'

const CreatePassword = lazy(() => import('@/pages/auth/CreatePassword'))
const ForgotPassword = lazy(() => import('@/pages/auth/ForgotPassword'))
const Login = lazy(() => import('@/pages/auth/Login'))
const ResetPassword = lazy(() => import('@/pages/auth/ResetPassword'))
const VerifyEmail = lazy(() => import('@/pages/auth/VerifyEmail'))
const Dashboard = lazy(() => import('@/pages/dashboard/Dashboard'))
const AddUser = lazy(() => import('@/pages/settings/AddUser'))
const CreateCustomer = lazy(() => import('@/pages/settings/CreateCustomer'))
const EditUser = lazy(() => import('@/pages/settings/EditUser'))
const AddTicket = lazy(() => import('@/pages/tickets/AddTicket'))
const EditTicket = lazy(() => import('@/pages/tickets/EditTicket'))
const Tickets = lazy(() => import('@/pages/tickets/Tickets'))
const NotFound = lazy(() => import('@/pages/NotFound'))
const Support = lazy(() => import('@/pages/Support'))
const Logout = lazy(() => import('@/pages/auth/Logout'))
const SignUp = lazy(() => import('@/pages/auth/SignUp'))
const IpTransit = lazy(() => import('@/pages/ip-transit/IpTransit'))
const ELan = lazy(() => import('@/pages/elan/ELan'))
const IpAccess = lazy(() => import('@/pages/ip-access/IpAccess'))
const DDoSProtection = lazy(() => import('@/pages/ddos-protection/DDoSProtection'))
const Privacy = lazy(() => import('@/pages/legal/Privacy'))
const Terms = lazy(() => import('@/pages/legal/Terms'))
const Billing = lazy(() => import('@/pages/settings/Billing'))
const CompanyProfile = lazy(() => import('@/pages/settings/CompanyProfile'))
const UserProfile = lazy(() => import('@/pages/settings/UserProfile'))
const Users = lazy(() => import('@/pages/settings/Users'))
const EditFirewall = lazy(() => import('@/pages/firewall/EditFirewall'))
const Firewall = lazy(() => import('@/pages/firewall/Firewall'))

const MemberLayout = lazy(() => import('@/layouts/MemberLayout'))
const NoLayout = lazy(() => import('@/layouts/NoLayout'))

//Tenants components
const Customers = lazy(() => import('@/pages/customers/Customers'))
const Customer = lazy(() => import('@/pages/customers/Customer'))
const NewCustomer = lazy(() => import('@/pages/customers/NewCustomer'))

const IpTransitServiceDetails = lazy(
  () => import('@/pages/ip-transit/IpTransitServiceDetails')
)
const IpAccessServiceDetails = lazy(
  () => import('@/pages/ip-access/IpAccessServiceDetails')
)
const ELanServiceDetails = lazy(() => import('@/pages/elan/ElanServiceDetails'))
const FirewallServiceDetails = lazy(
  () => import('@/pages/firewall/FirewallServiceDetails')
)
const ExtensionServiceDetails = lazy(
  () => import('@/pages/extensions/ExtensionServiceDetails')
)
const ServiceComponentDetails = lazy(
  () => import('@/pages/service-component/ServiceComponentDetails')
)
const DDoSProtectionServiceDetails = lazy(
  () => import('@/pages/ddos-protection/DDoSProtectionServiceDetails')
)

const GlobalRoutes: RouteObject[] = [
  { path: 'legal/privacy', element: <Privacy /> },
  { path: 'legal/terms', element: <Terms /> },
]

const UnauthenticatedRoutes: RouteObject[] = [
  { path: '/', element: <Login /> },
  { path: '/login', element: <Login /> },
  { path: '/sign-up', element: <SignUp /> },
  { path: '/sign-up/verify/:confirmationToken', element: <VerifyEmail /> },
  { path: '/reset-password/:uid/:token', element: <ResetPassword /> },
  { path: '/create-password/:uid/:token', element: <CreatePassword /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '*', element: <NotFound /> },
]

const AuthenticatedRoutes: RouteObject[] = [
  { path: '/', element: <Dashboard /> },
  { path: '/sign-up/verify/:confirmationToken', element: <VerifyEmail /> },
  { path: 'settings/create-customer', element: <CreateCustomer /> },
  { path: 'user-profile', element: <UserProfile /> },
  { path: 'tickets', element: <Tickets /> },
  { path: 'tickets/:ticketId/edit', element: <EditTicket /> },
  { path: 'tickets/new', element: <AddTicket /> },
  { path: 'support', element: <Support /> },
  { path: 'logout', element: <Logout /> },
  { path: 'customers', element: <Customers /> },
  { path: 'customers/:customer', element: <Customer /> },
  { path: 'customers/new', element: <NewCustomer /> },
  { path: '*', element: <NotFound /> },
]

const CustomerOnlyRoutes: RouteObject[] = [
  { path: 'settings/users', element: <Users /> },
  { path: 'settings/users/:userId/edit', element: <EditUser /> },
  { path: 'settings/users/new', element: <AddUser /> },
  { path: 'settings/billing', element: <Billing /> },
  { path: 'settings/company-profile', element: <CompanyProfile /> },
  // Create new service
  { path: 'services/ip-transit', element: <IpTransit /> },
  { path: 'services/flex-ethernet', element: <ELan /> },
  { path: 'services/ip-access', element: <IpAccess /> },
  { path: 'services/firewall', element: <Firewall /> },
  { path: 'services/ddos-protection', element: <DDoSProtection /> },
  // Service detail pages
  {
    path: 'services/flex-ethernet/:serviceId',
    element: <ELanServiceDetails />,
  },
  {
    path: 'services/ip-transit/:serviceId',
    element: <IpTransitServiceDetails />,
  },
  {
    path: 'services/ip-access/:serviceId',
    element: <IpAccessServiceDetails />,
  },
  {
    path: 'services/firewall/:firewallId',
    element: <FirewallServiceDetails />,
  },
  { path: 'services/firewall/:firewallId/edit', element: <EditFirewall /> },
  {
    path: 'services/extensions/:serviceId',
    element: <ExtensionServiceDetails />,
  },
  {
    path: 'services/port/:serviceComponentId',
    element: <ServiceComponentDetails serviceType="PORT" />,
  },
  {
    path: 'services/lag/:serviceComponentId',
    element: <ServiceComponentDetails serviceType="LAG" />,
  },
  {
    path: 'services/ddos-protection/:serviceId',
    element: <DDoSProtectionServiceDetails />,
  },
]

function App() {
  const [, dispatch] = useApp()
  const [authState] = useAuth()

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.waffle?.FLAGS) {
        const enabledFlags: string[] = []

        Object.entries(window.waffle.FLAGS).forEach(([key, value]) => {
          if (!!value) enabledFlags.push(key)
        })

        dispatch({
          type: 'waffleFlags',
          payload: enabledFlags,
        })

        clearInterval(interval)
      }
    }, 1)

    return () => clearInterval(interval)
  }, [dispatch])

  const CustomerRoutes: RouteObject[] = useMemo(() => {
    if (!authState.user) {
      return [
        ...UnauthenticatedRoutes,
        ...[...AuthenticatedRoutes, ...CustomerOnlyRoutes].map((route) => ({
          ...route,
          element: <RedirectInaccessiblePages />,
        })),
      ]
    }

    const customerRoutes = authState.user.customer_id
      ? CustomerOnlyRoutes
      : CustomerOnlyRoutes.map((route) => ({
          ...route,
          element: <NoCustomerFound />,
        }))

    return [
      ...customerRoutes,
      ...AuthenticatedRoutes,
      ...UnauthenticatedRoutes.map((route) => ({
        ...route,
        element: <RedirectInaccessiblePages />,
      })),
    ]
  }, [authState.user])

  return (
    <ErrorBoundary>
      <PageTitle />

      <Suspense fallback={<FullPageSpinner />}>
        <Routes>
          <Route
            path="/"
            element={authState.user ? <MemberLayout /> : <NoLayout />}
          >
            {GlobalRoutes?.map((el) => (
              <Route key={el.path} path={el.path} element={el.element} />
            ))}
            {CustomerRoutes?.map((el) => (
              <Route key={el.path} path={el.path} element={el.element} />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
