import { WizardTabItem } from '@/pages/ServiceWizard/ServiceWizard.types'

import { classNames } from '@/lib/misc/Utils'

import ChevronRightIcon from '@icons/chevron-right/outline'

interface WizardTabsProps {
  activeTabIndex: any
  tabs: WizardTabItem[]
}

const WizardTabs = ({ activeTabIndex, tabs }: WizardTabsProps) => (
  <nav className="flex" aria-label="Breadcrumb">
    <ul className="inline-flex items-center mt-14 mb-3.5">
      {tabs.map((item, tabItemIndex) => (
        <div key={tabItemIndex} className="inline-flex items-center">
          {tabItemIndex >= 1 && tabItemIndex <= tabs.length && (
            <div className="flex justify-center items-center mx-4">
              <ChevronRightIcon className="text-tiny text-slate-400" />
            </div>
          )}

          <span
            className={classNames(
              'font-text',
              activeTabIndex > tabItemIndex + 1 && 'text-green-500',
              activeTabIndex === tabItemIndex + 1 && 'text-brand-4',
              activeTabIndex < tabItemIndex + 1 && 'text-neutral-40'
            )}
          >
            {item.label}
          </span>
        </div>
      ))}
    </ul>
  </nav>
)

export default WizardTabs
