import { FC, memo } from 'react'

import OptionBlock from '@/pages/ServiceWizard/partials/OptionBlock'
import { PrefixOption } from '@/pages/ServiceWizard/ServiceWizard.types'
import Tooltip from '@/components/Tooltip'
import { price } from '@/lib/misc/Utils'
import StarIcon from '@icons/star/solid'
import InfoIcon from '@icons/information-circle'
import Tag from '@/components/Tag'

interface WidePrefixProps {
  prefix: PrefixOption
  onClick: Function
  isActive: boolean
  prefixType: 'IPv4' | 'IPv6'
}

const WidePrefix: FC<WidePrefixProps> = ({
  prefix,
  onClick,
  isActive,
  prefixType,
}) => (
  <OptionBlock
    onClick={() => onClick(prefix)}
    isActive={isActive}
    isFluid
    cy={`prefix-${prefixType}-block`}
    className="mb-2.5 md:mb-0"
  >
    <div className="flex w-full justify-between items-center">
      <div className="flex">
        <div className="text-heading-4 text-brand-4">/{prefix?.id}</div>
        {prefix.isRecommended && (
          <div className="absolute -top-2.5">
            <Tag
              style="success"
              text="Recommended"
              icon={<StarIcon className="h-3/4" />}
            />
          </div>
        )}
      </div>

      <div className="text-strong text-brand-5">
        {price(Number(prefix?.mrc?.amount))}
      </div>
    </div>

    <div className="text-neutral-40 text-fineprint flex items-center">
      {prefix?.addresses} addresses
      {!!prefix?.note && (
        <Tooltip className="ml-1" wrapperClassName="flex" icon={<InfoIcon />}>
          {prefix?.note}
        </Tooltip>
      )}
    </div>
  </OptionBlock>
)

export default memo(WidePrefix)
