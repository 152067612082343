import { useCallback, useMemo } from 'react'

import PortSpeeds from '@/pages/ServiceWizard/partials/PortSpeeds'
import {
  LAGProperties,
  PortSpeedOption,
  PrefixOption,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import Prefix from '@/pages/ServiceWizard/partials/Prefix'
import LAGCreateForm from '@/pages/ServiceWizard/partials/LAGCreateForm'
import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import { classNames } from '@/lib/misc/Utils'
import VlanSelection from '@/pages/ServiceWizard/partials/VlanSelection'
import { ServiceComponentVLANType } from '@/lib/definitions/api.types'

const TabIpTransitPorts = () => {
  const { ipTransitWizardState, updateTransitWizard } =
    useIpTransitWizardState()

  const {
    portSpeedItems,
    prefixV4Items,
    prefixV6Items,
    selectedPortSpeed,
    selectedPrefixV4,
    selectedPrefixV6,
    vlanId,
    vlanType,
    selectedLocation,
    lag,
    existingPortId,
  } = ipTransitWizardState

  const selectPortSpeed = (portSpeed: PortSpeedOption, portType: string) =>
    updateTransitWizard({
      selectedPortSpeed: portSpeed,
      selectedPortType: portType,
    })

  const selectPrefixV4 = (prefix: any) =>
    updateTransitWizard({
      selectedPrefixV4: prefix,
    })

  const selectPrefixV6 = (prefix: any) =>
    updateTransitWizard({
      selectedPrefixV6: prefix,
    })

  const setLagInfo = useCallback(
    (lag: LAGProperties | undefined) =>
      updateTransitWizard({
        lag,
      }),
    [updateTransitWizard]
  )

  const isPortSpeedSetupCostShown = useMemo(() => {
    if (!portSpeedItems) return false

    return (
      portSpeedItems.filter((item: any) => Number(item.nrc?.amount ?? 0) > 0)
        .length > 0
    )
  }, [portSpeedItems])

  const isIpv4SetupCostShown = useMemo(() => {
    if (!prefixV4Items) return false

    return (
      prefixV4Items.filter((item: any) => Number(item.nrc?.amount ?? 0) > 0)
        .length > 0
    )
  }, [prefixV4Items])

  const isIpv6SetupCostShown = useMemo(() => {
    if (!prefixV6Items) return false

    return (
      prefixV6Items.filter((item: any) => Number(item.nrc?.amount ?? 0) > 0)
        .length > 0
    )
  }, [prefixV6Items])

  const availablePortSpeedItems = useMemo(() => {
    if (!portSpeedItems) return []
    if (!selectedLocation) return portSpeedItems

    return portSpeedItems.filter(
      (speed: PortSpeedOption) =>
        speed.speedInMbps <= selectedLocation.bandwidth
    )
  }, [portSpeedItems, selectedLocation])

  return (
    <div className="w-full gap-y-10 flex flex-col">
      <h1 className="text-heading-4 tracking-tightest text-brand-1">
        Configure your port
      </h1>

      {!existingPortId && (
        <PortSpeeds
          portsClassName="gap-2 flex-wrap"
          speeds={availablePortSpeedItems}
          selectPortSpeed={selectPortSpeed}
          selectedPortSpeed={selectedPortSpeed}
          isSetupCostShown={isPortSpeedSetupCostShown}
          isExtension={selectedLocation?.type === 'Extension'}
        />
      )}

      {selectedLocation?.type === 'General' && (
        <LAGCreateForm values={lag} setLagInfo={setLagInfo} />
      )}

      {/*Prefixes*/}
      <div
        className={classNames(
          'flex justify-between gap-2 flex-wrap',
          (prefixV4Items?.length ?? 0) + (prefixV6Items?.length ?? 0) > 6
            ? 'flex-col space-y-6'
            : ''
        )}
      >
        <div>
          <div className="text-heading-byline tracking-tightest text-brand-1">
            Select IPv4 prefix size
          </div>
          <div className="flex space-between gap-x-2 mt-3">
            {prefixV4Items?.map((prefix: PrefixOption) => (
              <Prefix
                prefixType="IPv4"
                key={prefix.id}
                prefix={prefix}
                onClick={selectPrefixV4}
                isActive={prefix.id === selectedPrefixV4?.id}
                isSetupCostShown={isIpv4SetupCostShown}
              />
            ))}
          </div>
        </div>

        <div>
          <div className="text-heading-byline tracking-tightest text-brand-1">
            Select IPv6 prefix size
          </div>

          <div className="flex space-between gap-x-2 mt-3">
            {prefixV6Items?.map((prefix: PrefixOption) => (
              <Prefix
                prefixType="IPv6"
                key={prefix.id}
                prefix={prefix}
                onClick={selectPrefixV6}
                isActive={prefix.id === selectedPrefixV6?.id}
                isSetupCostShown={isIpv6SetupCostShown}
              />
            ))}
          </div>
        </div>
      </div>

      <VlanSelection
        onIdChange={(id: number) => updateTransitWizard({ vlanId: id })}
        onTypeChange={(type: ServiceComponentVLANType) =>
          updateTransitWizard({ vlanType: type })
        }
        vlanId={vlanId}
        vlanType={vlanType}
      />
    </div>
  )
}

export default TabIpTransitPorts
