import Checkbox from '@/components/Checkbox'
import { classNames } from '@/lib/misc/Utils'
import { useApp } from '@/lib/provider/AppProvider'

interface AggregatedBillingProps {
  isDisabled?: boolean
  isChecked?: boolean
  toggleAggregatedBilling: (isChecked: boolean) => void
}

const AggregatedBilling = ({
  isDisabled,
  isChecked,
  toggleAggregatedBilling,
}: AggregatedBillingProps) => {
  const [{ waffleFlags }] = useApp()

  return (
    waffleFlags.includes('aggregated_billing') && (
      <div>
        <Checkbox
          cy="aggregated-billing-toggle"
          checked={isChecked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            toggleAggregatedBilling(event.target.checked)
          }
          className="size-5"
          disabled={isDisabled}
        >
          <div
            className={classNames(
              'text-heading-byline tracking-tightest text-brand-1 ml-2 cursor-pointer',
              isDisabled && 'text-disabled'
            )}
          >
            Aggregated Billing
          </div>
        </Checkbox>
        <div
          className={classNames(
            'mt-2 text-neutral-50',
            isDisabled && 'text-disabled'
          )}
        >
          Data usage of this service is consolidated with the data usage of all
          other IP-Access services, which have aggregated billing enabled. The
          usage and billing of these services is handled cumulatively.
        </div>
      </div>
    )
  )
}

export default AggregatedBilling
