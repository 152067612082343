import { DDoSProtectionPricingMrc } from '@/pages/ServiceWizard/ServiceWizard.types'
import useDDoSProtectionWizardState from '@/lib/hooks/useDDoSProtectionWizardState'
import { classNames, price } from '@/lib/misc/Utils'

const DDoSProtectionQuote = () => {
  const {
    ddosProtectionWizardState: { pricing },
  } = useDDoSProtectionWizardState()

  return (
    pricing && (
      <div>
        <div className="pt-5 pb-8">
          <h4 className="text-white text-heading-byline mb-2">
            One-time setup fee
          </h4>

          <div className="flex justify-between w-full text-base">
            <span className="text-white">Setup</span>
            <span className="text-brand-3">{price(pricing.nrc)}</span>
          </div>
        </div>

        <div className="pb-5">
          <h4 className="text-white text-heading-byline mb-2">
            Recurring monthly fee
          </h4>

          {pricing.mrc.map(
            (pricingItem: DDoSProtectionPricingMrc, index: number) => (
              <div
                key={index}
                className={classNames(
                  'flex justify-between w-full mb-2 capitalize text-base',
                  !pricingItem.count && 'hidden'
                )}
              >
                <span className="text-white">{`${pricingItem.count}x ${pricingItem.term} ${pricingItem.size}`}</span>
                <span className="text-brand-3">{price(pricingItem.cost)}</span>
              </div>
            )
          )}
        </div>

        <div className="mt-3 pb-5">
          <div className="flex justify-between w-full text-lg">
            <span className="text-white">Total</span>
            <span className="text-brand-3">{price(pricing.total)}</span>
          </div>
        </div>
      </div>
    )
  )
}

export default DDoSProtectionQuote
