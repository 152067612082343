import { useState, TouchEvent } from 'react'

export enum SwipeDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  ANY = 'ANY',
}

interface TouchPoints {
  startX: number
  startY: number
  endX: number
  endY: number
}

interface SwipeProps {
  onFinished: () => void
  swipeDirection?: SwipeDirection
}

const TravelDistance = 50

const useSwipe = ({
  onFinished,
  swipeDirection = SwipeDirection.ANY,
}: SwipeProps) => {
  const [touchPoints, setTouchPoints] = useState<TouchPoints>({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  })

  const onTouchStart = (evt: TouchEvent<HTMLDivElement>) => {
    const touchEvent = evt.targetTouches[0]
    setTouchPoints({
      startX: touchEvent.clientX,
      startY: touchEvent.clientY,
      endX: 0,
      endY: 0,
    })
  }

  const onTouchMove = (evt: TouchEvent<HTMLDivElement>) => {
    const touchEvent = evt.targetTouches[0]

    setTouchPoints((points) => ({
      ...points,
      endX: touchEvent.clientX,
      endY: touchEvent.clientY,
    }))
  }

  const onTouchEnd = () => {
    const horizontalDistance = touchPoints.startX - touchPoints.endX
    const verticalDistance = touchPoints.startY - touchPoints.endY

    const isLeftSwipe = horizontalDistance > TravelDistance
    const isRightSwipe = horizontalDistance < -TravelDistance
    const isUpSwipe = verticalDistance > TravelDistance
    const isDownSwipe = verticalDistance < -TravelDistance

    if (swipeDirection === SwipeDirection.LEFT && isLeftSwipe) {
      onFinished()
    } else if (swipeDirection === SwipeDirection.RIGHT && isRightSwipe) {
      onFinished()
    } else if (
      swipeDirection === SwipeDirection.HORIZONTAL &&
      (isRightSwipe || isLeftSwipe)
    ) {
      onFinished()
    } else if (swipeDirection === SwipeDirection.UP && isUpSwipe) {
      onFinished()
    } else if (swipeDirection === SwipeDirection.DOWN && isDownSwipe) {
      onFinished()
    } else if (
      swipeDirection === SwipeDirection.VERTICAL &&
      (isDownSwipe || isUpSwipe)
    ) {
      onFinished()
    } else if (
      swipeDirection === SwipeDirection.ANY &&
      (isRightSwipe || isLeftSwipe || isDownSwipe || isUpSwipe)
    ) {
      onFinished()
    }
  }

  return {
    onTouchStart,
    onTouchEnd,
    onTouchMove,
  }
}

export default useSwipe
