import { captureException } from '@sentry/react'
import { useEffect, useMemo } from 'react'
import { useMutation } from 'react-query'

import { IpTransitTabSteps } from '@/pages/ip-transit/IpTransit'
import SummaryBgpSession from '@/pages/ip-transit/partials/SummaryBgpSession'
import SummaryCommittedDataRate from '@/pages/ip-transit/partials/SummaryCommittedDataRate'
import SummaryLocation from '@/pages/ip-transit/partials/SummaryLocation'
import SummaryPortSpeed from '@/pages/ip-transit/partials/SummaryPortSpeed'
import SummaryPrefixes from '@/pages/ip-transit/partials/SummaryPrefixes'
import SummaryContractTerm from '@/pages/ServiceWizard/partials/SummaryContractTerm'
import ServiceWizardSummary from '@/pages/ServiceWizard/ServiceWizardSummary'

import {
  IPTransitPriceRequest,
  IPTransitPriceResponse,
  PortTypes,
} from '@/lib/definitions/api.types'
import { GenericErrors } from '@/lib/definitions/generic.types'
import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import { Notify } from '@/lib/misc/Notify'
import { renderLagMaxSpeedValue, price } from '@/lib/misc/Utils'
import { IpTransitService } from '@/lib/services/IpTransitService'
import IpTransitSummaryPricing from '@/pages/ip-transit/partials/IpTransitSummaryPricing'
import { PortDetail } from '@/pages/ServiceWizard/ServiceWizard.types'

const ipTransit = new IpTransitService()

const IpTransitWizardSummary = () => {
  const { ipTransitWizardState, updateTransitWizard } =
    useIpTransitWizardState()

  const requestPrice = () => {
    const {
      portSpeedItems,
      selectedPortSpeed,
      selectedPortType,
      selectedPrefixV4,
      contract,
      selectedLocation,
      selectedServiceSpeed,
      lag,
      existingPortId,
    } = ipTransitWizardState

    const selectedPortSpeedName = portSpeedItems?.find(
      (portSpeed) =>
        portSpeed.speedInMbps === selectedPortSpeed?.speedInMbps &&
        portSpeed.type === selectedPortType
    )?.name

    const pricingRequest: IPTransitPriceRequest = {
      location: String(selectedLocation?.name),
      term: Number(contract),
      bandwidth: Number(selectedServiceSpeed?.speedInMbps),
      prefix_v4: Number(selectedPrefixV4?.id),
      port: existingPortId ? undefined : (selectedPortSpeedName as PortTypes),
      lag_member_count: lag?.memberCount,
    }

    return ipTransit.requestPriceSummary(pricingRequest)
  }

  const { mutate: requestPriceQuote } = useMutation(requestPrice, {
    onError: (error: GenericErrors) => {
      Notify.error(error?.message ?? 'An error occurred')
      captureException(error)
    },
    onSuccess: async ({ data }: { data: IPTransitPriceResponse }) => {
      if (!data) {
        Notify.error('An error occurred while getting your order quote')
        updateTransitWizard({ quote: undefined })

        return
      }

      updateTransitWizard({ quote: data })
    },
  })

  const isExtended: boolean = useMemo(() => {
    if (!ipTransitWizardState?.activeTab) return false
    return (
      Number(IpTransitTabSteps[ipTransitWizardState.activeTab]) ===
      IpTransitTabSteps.Summary
    )
  }, [ipTransitWizardState])

  const currentStep: number = useMemo(() => {
    if (!ipTransitWizardState?.activeTab) return 1

    return Number(IpTransitTabSteps[ipTransitWizardState.activeTab])
  }, [ipTransitWizardState])

  const selectedPortSpeedItem = useMemo(
    () =>
      ipTransitWizardState.portSpeedItems?.find(
        (portSpeed) =>
          portSpeed.speedInMbps ===
          ipTransitWizardState.selectedPortSpeed?.speedInMbps
      ),
    [ipTransitWizardState]
  )

  const portDetails: PortDetail[] = useMemo(() => {
    const portSpeedLabel = selectedPortSpeedItem
      ? `${selectedPortSpeedItem.speed} ${selectedPortSpeedItem.unit}`
      : ''

    const data: PortDetail[] = [
      {
        key: 'selected-port-speed-speed',
        label: 'Port Speed',
        value: portSpeedLabel,
      },
      {
        key: 'selected-port-speed-fees',
        label: 'One Time Fee',
        value: price(Number(selectedPortSpeedItem?.nrc.amount)),
        isNrc: true,
      },
    ]

    if (ipTransitWizardState.lag) {
      data.push({
        key: 'lag_member_count',
        label: 'LAG members',
        value: ipTransitWizardState.lag.memberCount,
      })

      if (
        selectedPortSpeedItem?.speed &&
        ipTransitWizardState.lag.memberCount
      ) {
        data.push({
          key: 'lag_maximum_speed',
          label: 'LAG maximum speed',
          value: renderLagMaxSpeedValue(
            selectedPortSpeedItem.speed,
            selectedPortSpeedItem.unit,
            ipTransitWizardState.lag.memberCount
          ),
        })
      }
    }

    if (ipTransitWizardState.vlanType === 'Tagged') {
      data.push({
        key: 'vlanId',
        label: 'VLAN ID',
        value: ipTransitWizardState.vlanId,
      })
    } else {
      data.push({
        key: 'vlanType',
        label: 'VLAN',
        value: ipTransitWizardState.vlanType,
      })
    }
    return data
  }, [
    ipTransitWizardState.vlanId,
    ipTransitWizardState.vlanType,
    selectedPortSpeedItem,
    ipTransitWizardState.lag,
  ])

  useEffect(() => {
    if (isExtended) {
      requestPriceQuote()
    }
  }, [isExtended, ipTransitWizardState?.contract, requestPriceQuote])

  return (
    <ServiceWizardSummary
      isExtended={isExtended}
      title="IP Transit"
      summaryBody={
        <div className="flex flex-col">
          <SummaryLocation location={ipTransitWizardState.selectedLocation} />
          {currentStep !== IpTransitTabSteps.Location && (
            <SummaryPortSpeed
              isExtended={isExtended}
              portDetails={portDetails}
            />
          )}

          {currentStep === IpTransitTabSteps.IpTransit && (
            <>
              <SummaryBgpSession />
              <SummaryCommittedDataRate />
            </>
          )}

          {currentStep !== IpTransitTabSteps.Location &&
            currentStep !== IpTransitTabSteps.Summary &&
            (ipTransitWizardState.selectedPrefixV4 ||
              ipTransitWizardState.selectedPrefixV6) && <SummaryPrefixes />}

          <SummaryContractTerm term={ipTransitWizardState.contract} />

          {/* !isExtended && currentStep !== IpTransitTabSteps.Location && <SummaryFees /> */}
        </div>
      }
      extendedSummaryBody={
        <div className="flex flex-col md:grid md:grid-cols-2">
          <div className="flex flex-col md:pr-7">
            <SummaryLocation location={ipTransitWizardState.selectedLocation} />
            <div className="mt-8 mb-4 col-span-2">
              <SummaryPortSpeed isExtended portDetails={portDetails} />
            </div>
          </div>

          <div className="pt-2 pb-9">
            <div className="flex flex-col md:px-7">
              <SummaryBgpSession />
              <SummaryCommittedDataRate />
            </div>
          </div>
        </div>
      }
      extendedSummarySidebar={<IpTransitSummaryPricing />}
    />
  )
}

export default IpTransitWizardSummary
