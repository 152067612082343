import { AxiosRequestConfig } from 'axios'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'

import { PortalDefaultTitle } from '@/lib/definitions/constants'
import { GenericErrors, GenericObject } from '@/lib/definitions/generic.types'
import { Notify } from '@/lib/misc/Notify'
import { CommittedDataRateOption } from '@/pages/ServiceWizard/ServiceWizard.types'
import { AggregatedCommitTotals } from '@/lib/definitions/api.types'

export const useFormInit = (dataObject: Object) => {
  const [form, setForm] = useState(dataObject)
  const [errors, setErrors] = useState<GenericErrors>({})

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget

    setForm((data: Object) => ({
      ...data,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const updateFormFields = useCallback(
    (fields: Object) => setForm((formFields) => ({ ...formFields, ...fields })),
    []
  )

  return {
    handleChange,
    form,
    setForm,
    errors,
    setErrors,
    updateFormFields,
  }
}

export function classNames(...classes: any[]): string {
  return classes
    .map((item) => {
      if (!item) {
        return false
      }
      if (typeof item === 'string') {
        return item
      }
      if (typeof item === 'object') {
        return Object.keys(item)
          .filter((key) => item[key])
          .join(' ')
      }
    })
    .filter(Boolean)
    .join(' ')
}

export function apiUrl(path: string): string {
  const host = import.meta.env.VITE_PORTAL_API_HOST ?? ''
  return `${host}${path}`
}

export function dot(data: GenericObject, key?: string): any {
  if (!key || !data) {
    return data
  }

  if (!key.includes('.') && typeof data === 'object') {
    return data[key]
  }

  const keys: string[] = key.split('.')
  let nwObj = data

  for (let i = 0; i < keys.length; i++) {
    if (typeof nwObj === 'undefined' || !nwObj) {
      return null
    }
    nwObj = nwObj[keys[i]]
  }

  return nwObj
}

export function getToken(): string | null {
  return localStorage.getItem('skey')
}

export const getAxiosRequestConfigWithHeaders = (
  headers?: Record<string, string>,
  options?: AxiosRequestConfig,
  tenant: boolean = true
): AxiosRequestConfig => {
  const token = getToken()

  if (!token) return {}

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
    ...(localStorage.getItem('customerId') &&
      tenant && {
        'AS-TENANT': localStorage.getItem('customerId'),
      }),
    ...headers,
  }

  return {
    headers: defaultHeaders,
    ...options,
  }
}

export function getCookies(): GenericObject {
  const localCookies = document.cookie.split(';')
  const cookies: GenericObject = {}

  localCookies.forEach((localCookie) => {
    const [key, value] = localCookie.split('=')
    cookies[key] = value
  })

  return cookies
}

export function numberFormat(
  number: number,
  { decimals = 2, decimalSeparator = ',', thousandSeparator = '.' } = {}
) {
  const n = !isFinite(+number) ? 0 : +number,
    precision = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    toFixedFix = function (n: number, prec: number) {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      const k = Math.pow(10, prec)
      return Math.round(n * k) / k
    },
    s = (precision ? toFixedFix(n, precision) : Math.round(n))
      .toString()
      .split('.')

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandSeparator)
  }

  if ((s[1] || '').length < precision) {
    s[1] = s[1] || ''
    s[1] += new Array(precision - s[1].length + 1).join('0')
  }

  return s.join(decimalSeparator)
}

export function price(amount: number): string {
  return `${numberFormat(amount)}€`
}

export function formatContractTerm(numberOfMonths: number): string {
  return `${numberOfMonths} ${numberOfMonths === 1 ? 'month' : 'months'}`
}

export const formatDate = (date: number | string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return new Date(date).toLocaleDateString('en-US', options)
}

export const formatAddress = (address: {
  line1: string
  postal_code: string
  city: string
  country: string
}): string =>
  `${address.line1}, ${address.postal_code} ${address.city}, ${address.country}`

export const shortenText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text

export const parseResponseErrorsAndNotify = (
  error: GenericObject,
  serverErrorMessage?: string
) => {
  if (!error?.response) return

  const { data: responseData, headers: responseHeaders } = error.response
  const errorData = responseData.hasOwnProperty('detail')
    ? responseData.detail
    : responseData
  const errorStatus = error.response.status

  if (errorStatus >= 500) {
    Notify.error(serverErrorMessage ?? 'Server Error!')
    return errorData
  }

  if (
    typeof errorData === 'string' &&
    !responseHeaders['content-type'].includes('text/html')
  ) {
    Notify.error(errorData)
    return errorData
  }

  if (typeof errorData === 'object' && Object.values(errorData).length) {
    Object.values(errorData).forEach((err: any) => {
      if (typeof err === 'string') Notify.error(err)
      if (Array.isArray(err)) Notify.error(err.join(', '))
    })
    return errorData
  }

  Notify.error("We couldn't process your request")
  return errorData
}

export const fieldsAreSet = (state: any, fields: string[]) => {
  return (
    fields
      .map((field) =>
        field
          .split('|')
          .map((optioalField) => {
            const value = dot(state, optioalField)
            return value !== undefined && value !== null && value !== '' && value !== 0
          })
          .includes(true)
      )
      .filter((item) => item).length === fields.length
  )
}

export const areAllFieldsFilled = (state: any, fields: string[]) =>
  fields.some((field) => {
    const value = dot(state, field)
    return value !== undefined && value !== null && value !== ''
  })

export const alphanumericSortingFn = (key: string, a: any, b: any) =>
  a.getValue(key).label > b.getValue(key).label ? -1 : 1

export const timeBreakdown = (timeInSeconds: number) => {
  const days = Math.floor(timeInSeconds / 86400)
  let remainingSeconds = timeInSeconds % 86400

  const hours = Math.floor(remainingSeconds / 3600)
  remainingSeconds = remainingSeconds % 3600

  const minutes = Math.floor(remainingSeconds / 60)
  const seconds = remainingSeconds % 60

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export const generatePortalTitle = (title?: string): string => {
  return title ? `${title} - ${PortalDefaultTitle}` : PortalDefaultTitle
}

export const encodeQueryParams = (params: GenericObject): string =>
  Object.entries(params)
    .map((param) => param.join('='))
    .join('&')

export const scrollToTop = (wrapperRef: any) =>
  wrapperRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

export const validateVLanId = (val: number) => {
  if (isNaN(val)) {
    return 'VLAN ID should be a number'
  } else if (val < 1) {
    return 'VLAN ID can not be a smaller than 1'
  } else if (val > 4094) {
    return 'VLAN ID can not be greater than 4096'
  }

  return
}

export const addBaseUrl = (path: string) => {
  return apiUrl(`/api/v1/${path}`)
}

export const formatSpeed = (mbps: number): string => {
  return mbps < 1000 ? `${mbps}Mbps` : `${mbps / 1000}Gbps`
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  // bytes, kilobytes, megabytes, gigabytes, terabytes, petabytes, exabytes, zettabytes, yottabytes
  const sizes = [
    'b/s',
    'kb/s',
    'Mb/s',
    'Gb/s',
    'Tb/s',
    'Pb/s',
    'Eb/s',
    'Zb/s',
    'Yb/s',
  ]
  if (bytes === 0) return '0 b/s'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${sizes[i]}`
}

export const generateBandwidthsData = (
  speedInMbps: number
): CommittedDataRateOption => ({
  speedInMbps,
  speed: speedInMbps >= 1000 ? speedInMbps / 1000 : speedInMbps,
  unit: speedInMbps >= 1000 ? 'Gbps' : 'Mbps',
})

export const useDebounce = (value: any, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const timerRef = useRef()

  useEffect(() => {
    // @ts-ignore
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [value, delay])

  return debouncedValue
}

export const haversineDistance = (
    lat1: number, lon1: number,
    lat2: number, lon2: number
): number => {
  const degToRad = (deg: number): number => deg * (Math.PI / 180)
  const R = 6371000 // Hearth radius
  const dLat = degToRad(lat2 - lat1)
  const dLon = degToRad(lon2 - lon1)
  const a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export const notifyExtensionsTermChange = () => {
  Notify.warn("The 1 month term is not available for Exstenions. Reverting to 12 months term")
}

export const getAggregatedSpeedPrice = (speed: number, aggregatedCommitTotals: AggregatedCommitTotals): number => {
  const aggregatedSpeedInMbps = speed + (aggregatedCommitTotals?.speed || 0)
  const aggregatedMrc = Number(aggregatedCommitTotals?.bandwidths[aggregatedSpeedInMbps].mrc.amount || 0)
  return aggregatedMrc - Number(aggregatedCommitTotals?.mrc.amount || 0)
}

export const renderLagMaxSpeedValue = (
  speed: number,
  unit: string,
  memberCount: number
): string =>
  `${speed * memberCount} ${unit} (${memberCount} x ${speed} ${unit})`
