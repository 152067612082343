import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import ServiceWizard from '@/pages/ServiceWizard/ServiceWizard'
import { processExistingPorts } from '@/pages/ServiceWizard/PortsConversion'
import {
  CommittedDataRateOption,
  ContractTerm,
  ExistingPortItem,
  PortSpeedOption,
  PrefixOption,
  WizardTabItem,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import TabIpAccess from '@/pages/ip-access/TabIpAccess'
import TabIpAccessPorts from '@/pages/ip-access/TabIpAccessPorts'
import {
  emptyMrcNrcValue,
  portSpeedOptions,
  prefixV4,
} from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'

import {
  BillingType,
  IPAccess,
  IPAccessWizardPricesRequest,
  PortTypes,
  UserNetworkInterface,
  UserNetworkInterfaceOnExistingLAG,
  UserNetworkInterfaceOnExistingPort,
} from '@/lib/definitions/api.types'
import { ServiceWizardContractTerm } from '@/lib/definitions/constants'
import { ProductComponentIdentifiers } from '@/lib/definitions/types'

import useIpAccessWizardState from '@/lib/hooks/useIpAccessWizardState'
import {
  fieldsAreSet,
  generateBandwidthsData,
  parseResponseErrorsAndNotify,
  scrollToTop,
} from '@/lib/misc/Utils'
import { useApp } from '@/lib/provider/AppProvider'
import { IpAccessService } from '@/lib/services/IpAccessService'
import { NEW_SERVICE_ORDER } from '@/lib/provider/ServiceWizardProvider'
import { useLoadServiceComponents } from '@/lib/queries/Services.queries'
import IpAccessWizardSummary from '@/pages/ip-access/IpAccessWizardSummary'
import Locations from '@/pages/ServiceWizard/partials/Locations'

const tabs: WizardTabItem[] = [
  { id: 'Location', label: 'Location' },
  { id: 'Ports', label: 'Ports' },
  { id: 'IpAccess', label: 'IP-Access' },
  { id: 'Summary', label: 'Summary' },
]

export enum IpAccessTabSteps {
  Location = 1,
  Ports = 2,
  IpAccess = 3,
  Summary = 4,
}

export type IpAccessStep = keyof typeof IpAccessTabSteps

const ipAccess = new IpAccessService()

const IpAccess: FC = () => {
  const [, dispatch] = useApp()
  const navigate = useNavigate()
  const { ipAccessWizardState, updateIpAccessWizard, resetIpAccessWizard } =
    useIpAccessWizardState()
  const wrapperRef = useRef<null | HTMLDivElement>(null)
  const [isPreviousStepDisabled, setIsPreviousStepDisabled] = useState(true)
  const [isPreviousHidden, setIsPreviousHidden] = useState(true)
  const [isNextDisabled, setIsNextDisabled] = useState(true)

  const startOrder = () => {
    let port:
      | UserNetworkInterface
      | UserNetworkInterfaceOnExistingPort
      | UserNetworkInterfaceOnExistingLAG

    const portCommonProps = {
      bandwidth: Number(ipAccessWizardState.selectedServiceSpeed?.speedInMbps),
      vlan_type: ipAccessWizardState.vlanType ?? 'Tagged',
      ...((ipAccessWizardState.vlanType === 'Tagged' && {
        vlan_id: Number(ipAccessWizardState.vlanId),
      }) ||
        {}),
    }

    if (ipAccessWizardState.selectedPortSpeed?.isLag) {
      port = {
        lag_id: ipAccessWizardState.existingPortId!,
        ...portCommonProps,
      }
    } else if (ipAccessWizardState.existingPortId) {
      port = {
        id: ipAccessWizardState.existingPortId,
        ...portCommonProps,
      }
    } else {
      port = {
        port_type: ipAccessWizardState.selectedPortSpeed?.name as PortTypes,
        location: ipAccessWizardState.selectedLocation!.name,
        ...(ipAccessWizardState.selectedLocation?.type === 'General' && {
          lag_member_count: ipAccessWizardState.lag?.memberCount,
          lag_name: ipAccessWizardState.lag?.name,
        }),
        ...portCommonProps,
      }
    }

    return ipAccess.createIpAccessOrder({
      term: Number(ipAccessWizardState.contract),
      prefix_v4_size: ipAccessWizardState.selectedPrefixV4!,
      prefix_v6_size: 56,
      purchase_reference: ipAccessWizardState.purchaseReference,
      port,
      billing_type: ipAccessWizardState.serviceSpeedType,
      aggregated_billing: ipAccessWizardState.hasAggregatedBilling,
    })
  }

  const { mutate: placeOrder, isLoading: isPlacingOrder } = useMutation(
    startOrder,
    {
      onError: (error: AxiosError) => {
        parseResponseErrorsAndNotify(
          error,
          'An internal error occurred while placing your order'
        )
        captureException(error)
      },
      onSuccess: async ({ data: serviceData }: { data: IPAccess }) => {
        if (serviceData) {
          dispatch({
            type: NEW_SERVICE_ORDER,
            payload: {
              ...serviceData,
            },
          })
          resetIpAccessWizard()
          navigate(`/services/ip-access/${serviceData.id}`)
        }
      },
    }
  )

  const { mutate: getWizardPrices } = useMutation(
    (data: IPAccessWizardPricesRequest) => {
      return ipAccess.getIpAccessWizardPrices(data)
    },
    {
      onError: (error: any) => {
        parseResponseErrorsAndNotify(error)
      },
      onSuccess({ data }) {
        const portSpeedItems: PortSpeedOption[] = []
        const prefixV4Items: PrefixOption[] = []

        const bandwidthsItems: Record<string, CommittedDataRateOption> =
          Object.keys(data.bandwidths).reduce(
            (result: Record<string, CommittedDataRateOption>, key) => {
              result[key] = {
                ...data.bandwidths[key],
                ...generateBandwidthsData(Number(key)),
              }
              return result
            },
            {}
          )
        portSpeedOptions.map((port) => {
          const newPort = data.ports[port.speedInMbps]
          if (port.type !== 'LR1' || data.allow_LR1) {
            const portToAdd = { ...port, ...newPort }
            portSpeedItems.push(portToAdd)
          }
        })

        prefixV4.map((prefix) => {
          prefixV4Items.push({ ...prefix, ...data.prefixes[prefix.id] })
        })

        updateIpAccessWizard({
          portSpeedItems,
          prefixV4Items,
          bandwidthsItems,
          availableServiceBandwidths: data.available_service_bandwidths,
          aggregatedCommitTotals: data.aggregated_commit_totals,
        })
      },
    }
  )

  const getPricesHandler = useCallback(
    (billing: BillingType) => {
      let portSpeed = ipAccessWizardState.selectedPortSpeed?.speedInMbps ?? 0

      const lagMemberCount = ipAccessWizardState.lag?.memberCount ?? 1
      if (lagMemberCount > 1) {
        portSpeed *= lagMemberCount
      }

      getWizardPrices({
        // @ts-ignore
        port_speed: portSpeed,
        currency: 'EUR',
        location: ipAccessWizardState.selectedLocation?.name,
        billing,
        terms: ipAccessWizardState.contract || ServiceWizardContractTerm,
      })
    },
    [
      getWizardPrices,
      ipAccessWizardState.selectedLocation,
      ipAccessWizardState.contract,
      ipAccessWizardState.selectedPortSpeed,
      ipAccessWizardState.lag,
    ]
  )

  const { data: customerExistingPorts } = useLoadServiceComponents({
    pco: [ProductComponentIdentifiers.LAG, ProductComponentIdentifiers.PORT],
  })

  const validateStepRequiredFields = useCallback(() => {
    const fields = ['selectedLocation|existingPortId']

    if (Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.Ports) {
      fields.push('selectedPortSpeed')
    }

    if (Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.IpAccess) {
      fields.push('vlanType', 'selectedPrefixV4', 'selectedServiceSpeed')

      if (ipAccessWizardState.vlanType === 'Tagged') {
        fields.push('vlanId')
      }
    }

    if (ipAccessWizardState.lag) {
      fields.push('lag.memberCount', 'lag.name')
    }

    return fieldsAreSet(ipAccessWizardState, fields)
  }, [ipAccessWizardState])

  const handleTabChange = (newTabIndex: number) => {
    if (newTabIndex) updateIpAccessWizard({ activeTab: newTabIndex })
  }

  const handleNextStep = () => {
    const stepIndex: number = Number(ipAccessWizardState.activeTab)

    if (stepIndex === tabs.length) {
      placeOrder()
      return
    }

    if (
      !!ipAccessWizardState.existingPortId &&
      ipAccessWizardState.activeTab === IpAccessTabSteps.Location
    ) {
      handleTabChange(stepIndex + 2)
    } else {
      handleTabChange(stepIndex + 1)
    }
    scrollToTop(wrapperRef)
  }

  const handlePreviousStep = () => {
    const stepIndex = Number(ipAccessWizardState.activeTab)

    if (
      !!ipAccessWizardState.existingPortId &&
      ipAccessWizardState.activeTab === IpAccessTabSteps.IpAccess
    ) {
      handleTabChange(stepIndex - 2)
    } else {
      handleTabChange(stepIndex - 1)
    }
    scrollToTop(wrapperRef)
  }

  useEffect(() => resetIpAccessWizard(), [resetIpAccessWizard])

  useEffect(() => {
    const stepIndex: number = Number(ipAccessWizardState.activeTab)

    setIsNextDisabled(
      isPlacingOrder ||
        !validateStepRequiredFields() ||
        (Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.Summary &&
          !ipAccessWizardState.quote)
    )
    setIsPreviousStepDisabled(stepIndex === 1)
    setIsPreviousHidden(stepIndex === 1)
  }, [ipAccessWizardState, isPlacingOrder, validateStepRequiredFields])

  useEffect(() => {
    if (!!ipAccessWizardState.selectedLocation) {
      getPricesHandler('committed')
    }
  }, [
    getPricesHandler,
    ipAccessWizardState.selectedLocation,
    ipAccessWizardState.contract,
  ])

  useEffect(() => {
    const existingPorts = processExistingPorts(
      // @ts-ignore
      customerExistingPorts,
      portSpeedOptions
    )
    updateIpAccessWizard({ existingPorts })
  }, [updateIpAccessWizard, customerExistingPorts])

  const setContractTerm = (term: ContractTerm) =>
    updateIpAccessWizard({ contract: term.period })

  const onExistingPortHandler = useCallback(
    (port: ExistingPortItem | undefined) => {
      if (!port) {
        updateIpAccessWizard({
          existingPortId: undefined,
          selectedLocation: undefined,
          selectedPortSpeed: undefined,
        })
        return
      }

      const selectedPortSpeed: PortSpeedOption = {
        name: port.portType,
        speedInMbps: port.portSpeed.speedInMbps,
        id: port.id,
        speed: port.portSpeed.speed,
        unit: port.portSpeed.unit,
        type: port.portSpeed.type,
        isLag: port.isLag,
        nrc: emptyMrcNrcValue,
        mrc: emptyMrcNrcValue,
      }

      updateIpAccessWizard({
        existingPortId: port.id,
        selectedLocation: port.location,
        selectedPortSpeed,

        ...(port.isLag && {
          lag: {
            memberCount: port.lagCount,
            name: port.name,
          },
        }),
      })
    },
    [updateIpAccessWizard]
  )

  if (!ipAccessWizardState) return <div />

  return (
    <ServiceWizard
      pageTitle="Add an IP Access Service"
      isSummaryStep={
        Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.Summary
      }
      contract={ipAccessWizardState.contract}
      wrapperRef={wrapperRef}
      tabs={tabs}
      activeTabIndex={Number(ipAccessWizardState.activeTab)}
      handlePreviousStep={handlePreviousStep}
      handleNextStep={handleNextStep}
      isPreviousStepDisabled={isPreviousStepDisabled}
      isNextDisabled={isNextDisabled}
      isPreviousHidden={isPreviousHidden}
      setContractTerm={setContractTerm}
      summarySection={<IpAccessWizardSummary />}
      hideContractTermSelector
    >
      {Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.Location && (
        <Locations
          title="Find your desired location"
          existingPorts={ipAccessWizardState.existingPorts}
          selectedLocation={ipAccessWizardState.selectedLocation?.id}
          selectedExistingPort={ipAccessWizardState.existingPortId}
          setSelectedLocation={(location) => {
            updateIpAccessWizard({
              selectedLocation: location,
              existingPortId: undefined,
            })
          }}
          setExistingPort={onExistingPortHandler}
        />
      )}
      {Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.Ports && (
        <TabIpAccessPorts />
      )}
      {Number(ipAccessWizardState.activeTab) === IpAccessTabSteps.IpAccess && (
        <TabIpAccess getPrices={getPricesHandler} />
      )}
    </ServiceWizard>
  )
}

export default memo(IpAccess)
