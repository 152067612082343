import { useMemo } from 'react'

import {
  ExistingPort,
  ExistingPortItem,
  GeoCircle,
  GeoCoordinates,
  GeoRectangle,
} from '@/pages/ServiceWizard/ServiceWizard.types'
import { LocationSearchResult } from '@/lib/definitions/api.types'
import { haversineDistance } from '@/lib/misc/Utils'

function isPointWithinRectangle(
  rect: GeoRectangle,
  point: GeoCoordinates
): boolean {
  return (
    point.lat <= rect.northEast.lat &&
    point.lat >= rect.southWest.lat &&
    point.lng >= rect.southWest.lng &&
    point.lng <= rect.northEast.lng
  )
}

interface ExistingPortsParams {
  search?: string
  searchCircle?: GeoCircle
  searchRectangle?: GeoRectangle
  searchCountry?: string
  existingPorts?: ExistingPort[]
  existingPortsLocations?: LocationSearchResult[]
}

const useExistingPorts = ({
  search,
  searchCircle,
  searchRectangle,
  searchCountry,
  existingPorts,
  existingPortsLocations,
}: ExistingPortsParams): ExistingPortItem[] => {
  return useMemo(() => {
    if (!existingPortsLocations || !existingPorts) return []
    let items: ExistingPortItem[] = []
    existingPorts.forEach((port) => {
      const location = existingPortsLocations
        ?.map((sr) => sr.location)
        .find((loc) => loc.name === port.locationName)

      if (location) {
        items.push({ location, ...port })
      }
    })

    if (!!search) {
      const regex = new RegExp(search, 'i')
      items = items.filter(
        (item) =>
          (item.location.description &&
            item.location.description.search(regex) > -1) ||
          item.location.city.name.search(regex) > -1 ||
          item.location.city.country.name.search(regex) > -1 ||
          item.locationName.search(regex) > -1 ||
          item.portType.search(regex) > -1
      )
    }

    if (!!searchCircle) {
      items = items.filter(
        (item) =>
          haversineDistance(
            searchCircle.center.lat,
            searchCircle.center.lng,
            item.location.latitude!,
            item.location.longitude!
          ) <= 5000
      )
    }

    if (!!searchRectangle) {
      items = items.filter((item) =>
        isPointWithinRectangle(searchRectangle, {
          lat: item.location.latitude!,
          lng: item.location.longitude!,
        })
      )
    }

    if (!!searchCountry) {
      items = items.filter(
        (item) => item.location.city.country.short_name === searchCountry
      )
    }

    return items.sort(
      (a: ExistingPortItem, b: ExistingPortItem) =>
        a.locationName.localeCompare(b.locationName) ||
        b.name.localeCompare(a.name)
    )
  }, [
    search,
    existingPorts,
    existingPortsLocations,
    searchCircle,
    searchRectangle,
    searchCountry,
  ])
}

export default useExistingPorts
