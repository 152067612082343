import { ddosProtectionSizesPlans } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'

const DDoSSizesPlans = () => {
  return (
    <div className="mt-16 mb-6">
      <h2 className="mb-6 text-center text-brand-4 text-heading-4">DDoS Protection Plans</h2>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 justify-between">
        {ddosProtectionSizesPlans.map((plan, planIndex) => (
          <div
            key={planIndex}
            className="flex flex-col border-brand-4 border-1 rounded-md px-4 py-4"
          >
            <h4 className="text-brand-4 text-heading-byline capitalize">
              {plan.size} Bundle
            </h4>

            <ul className="mt-2 ml-4 list-disc">
              {plan.benefits.map((benefit, benefitIndex) => (
                <li key={benefitIndex} className="pt-1">
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DDoSSizesPlans
