import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { generateBandwidthsData, price } from '@/lib/misc/Utils'
import { ELanEndpoint, PortSpeed } from '@/pages/ServiceWizard/ServiceWizard.types'
import { portSpeedOptions } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import SummaryFancyBox from '@/pages/ServiceWizard/partials/SummaryFancyBox'
import { useCallback } from 'react'

const SummaryEndpoints = () => {
  const { eLanWizardState } = useELanWizardState()

  const getEndpointDetails = useCallback(
    (endpoint: ELanEndpoint, endpointIndex: number) => {
      let serviceSpeed
      const location = eLanWizardState.locations?.find(
        (loc) => loc.name === endpoint.selectedLocation
      )

      const portSpeed = portSpeedOptions.find(
        (item: PortSpeed) => item.name === endpoint.selectedPortName
      )

      if (endpoint.selectedServiceSpeed)
        serviceSpeed = generateBandwidthsData(endpoint.selectedServiceSpeed)

      const serviceSpeedQuote = eLanWizardState.quotes[endpointIndex]?.bandwidth
      const portSpeedQuote = eLanWizardState.quotes[endpointIndex]?.port
      const extensionQuote = eLanWizardState.quotes[endpointIndex]?.extension
      const lagQuote = eLanWizardState.quotes[endpointIndex]?.lag

      return {
        location,
        portSpeed,
        serviceSpeed,
        lag: endpoint.lag,
        serviceSpeedQuote,
        portSpeedQuote,
        extensionQuote,
        lagQuote,
      }
    },
    [eLanWizardState.locations, eLanWizardState.quotes]
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 px-8 mb-4">
      {eLanWizardState.endpoints.map((endpoint, endpointIndex) => {
        const {
          location,
          portSpeed,
          serviceSpeed,
          lag,
          serviceSpeedQuote,
          portSpeedQuote,
          extensionQuote,
          lagQuote,
        } = getEndpointDetails(endpoint, endpointIndex)

        return (
          <SummaryFancyBox
            key={endpointIndex}
            className="flex flex-col justify-start p-4 text-white"
            wrapperClassName="mb-2"
          >
            <div className="w-full h-full">
              <h3 className="w-full flex justify-between items-center">
                <span className="text-xl leading-6 font-medium">
                  {location?.city.name}
                </span>
                <span className="text-fineprint">
                  {endpoint.vlanType === 'Tagged' && (
                    <span className="mr-2">
                      <b>Tagged</b> VLAN-ID: {endpoint.vlanId}
                    </span>
                  )}
                  {endpoint.vlanType === 'Untagged' && (
                    <span>
                      <b>Untagged</b> VLAN
                    </span>
                  )}
                </span>
              </h3>

              {!!location && (
                <div className="flex flex-col justify-between mt-1">
                  <div className="text-text-link text-fineprint">{location.description}</div>

                  <div className="flex flex-row justify-between items-center mt-1">
                    <span className="text-neutral-40 text-fineprint">
                      {location.name}
                    </span>
                    {endpoint.existingPortId && <span>Existing Port</span>}
                  </div>
                </div>
              )}

              {!!location?.supplier_name && (
                <div className="flex flex-row items-center justify-between text-fineprint mt-2">
                  <span className="text-white">Extension Provider</span>
                  <span className="text-text-link">
                    {location.supplier_name}
                  </span>
                </div>
              )}

              {!!location?.extension_handover && (
                <div className="flex flex-row items-center justify-between text-fineprint mt-2">
                  <span className="text-white">Edge Location</span>
                  <span className="text-text-link">
                    {location.extension_handover}
                  </span>
                </div>
              )}

              <div className="w-full flex flex-wrap justify-between text-fineprint mb-1 mt-2 !leading-5">
                {portSpeed && (
                  <div className="mr-2">
                    Port Speed: {`${portSpeed.speed} ${portSpeed.unit}`}
                  </div>
                )}
                {portSpeedQuote && (
                  <div className="flex items-center mb-1">
                    <span>{price(Number(portSpeedQuote.nrc.amount))} once</span>
                    <span className="inline-block w-1 h-1 mx-2 mt-1 rounded-full bg-white" />
                    <span>
                      {price(Number(portSpeedQuote.mrc.amount))} monthly
                    </span>
                  </div>
                )}
              </div>

              {!!lag && (
                <>
                  <div className="w-full flex flex-wrap items-center justify-between text-fineprint">
                    {serviceSpeed && (
                      <div className="mb-1 mr-2">
                        LAG members: {lag.memberCount}
                      </div>
                    )}
                    {lagQuote && (
                      <div className="flex items-center mb-1">
                        <span>{price(Number(lagQuote.nrc.amount))} once</span>
                        <span className="inline-block w-1 h-1 mx-2 mt-1 rounded-full bg-white" />
                        <span>
                          {price(Number(lagQuote.mrc.amount))} monthly
                        </span>
                      </div>
                    )}
                  </div>

                  {portSpeed && (
                    <div className="w-full flex flex-wrap items-center justify-between text-fineprint mt-1.5 mb-2">
                      LAG maximum speed: {portSpeed.speed * lag.memberCount}{' '}
                      {portSpeed.unit} ({lag.memberCount} x {portSpeed.speed}{' '}
                      {portSpeed.unit})
                    </div>
                  )}
                </>
              )}

              <div className="w-full flex flex-wrap items-center justify-between text-fineprint mt-1">
                {serviceSpeed && (
                  <div className="mb-1 mr-2">
                    Service Speed: {serviceSpeed.speed} {serviceSpeed.unit}
                  </div>
                )}

                {serviceSpeedQuote && (
                  <div className="flex items-center mb-1">
                    <span>
                      {price(Number(serviceSpeedQuote.nrc.amount))} once
                    </span>
                    <span className="inline-block w-1 h-1 mx-2 mt-1 rounded-full bg-white" />
                    <span>
                      {price(Number(serviceSpeedQuote.mrc.amount))} monthly
                    </span>
                  </div>
                )}
              </div>

              {endpoint.selectedLocationType === 'Extension' &&
                endpoint.selectedServiceSpeed && (
                  <div className="w-full flex flex-wrap items-center justify-between text-fineprint">
                    <div className="mb-1 mr-2">Extension:</div>
                    {serviceSpeedQuote && (
                      <div className="flex items-center mb-1">
                        <span>
                          {price(Number(extensionQuote?.nrc.amount))} once
                        </span>
                        <span className="inline-block w-1 h-1 mx-2 mt-1 rounded-full bg-white" />
                        <span>
                          {price(Number(extensionQuote?.mrc.amount))} monthly
                        </span>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </SummaryFancyBox>
        )
      })}
    </div>
  )
}

export default SummaryEndpoints
