import { useCallback } from 'react'

import {
  REDIRECT_TO_WIZARD,
  useServiceWizard,
} from '@/lib/provider/ServiceWizardProvider'

const useTransferToService = () => {
  const { dispatch } = useServiceWizard()

  const redirectToWizard = useCallback(
    (service?: string) => {
      dispatch({
        type: REDIRECT_TO_WIZARD,
        payload: service,
      })
    },
    [dispatch]
  )

  return {
    redirectToWizard,
  }
}

export default useTransferToService
