import { FC } from 'react'

import { classNames } from '@/lib/misc/Utils'

interface FormErrorProps {
  className?: string
  children: string
  cy?: string
}

const FormError: FC<FormErrorProps> = ({ className, children, cy }) => {
  return (
    <div
      className={classNames('text-brand-6 text-right text-xs', className)}
      {...(cy && { 'data-cy': cy })}
    >
      {children}
    </div>
  )
}

export default FormError
