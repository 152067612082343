import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { AppProvider } from '@/lib/provider/AppProvider'
import { AuthProvider } from '@/lib/provider/AuthProvider'
import { ApiService } from '@/lib/services/ApiService'
import { TenantProvider } from '@/lib/provider/TenantProvider'
import { ServiceWizardProvider } from '@/lib/provider/ServiceWizardProvider'

import App from './App'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
})
const apiService = new ApiService()

function SafeHydrate({ children }: any) {
  useEffect(() => {
    apiService.getSentrySettings().then(({ data }) => {
      if (!!data.environment && data.environment !== 'development') {
        Sentry.init({
          dsn: 'https://f57ad312907e4bd3bed2f4d225851dd9@sentry.as5405.net/6464729',
          integrations: [new BrowserTracing()],
          tracesSampleRate: 1.0,
          environment: data.environment,
          release: 'release-1',
        })
      }
    })
  }, [])

  return (
    <div className={'h-full'} suppressHydrationWarning>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </div>
  )
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <SafeHydrate>
      <AppProvider>
        <AuthProvider>
          <TenantProvider>
            <ServiceWizardProvider>
              <App />
            </ServiceWizardProvider>
          </TenantProvider>
        </AuthProvider>
      </AppProvider>
    </SafeHydrate>
  </BrowserRouter>
)
