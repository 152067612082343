import { ChangeEvent, FC, memo, useMemo, useRef, useState } from 'react'

import BgpSessionForm from '@/pages/ip-transit/partials/BgpSessionForm'
import CommittedDataRateSection from '@/pages/ip-transit/partials/CommittedDataRateSection'
import Input from '@/components/GenericInput'
import useIpTransitWizardState from '@/lib/hooks/useIpTransitWizardState'
import CheckIcon from '@icons/check'
import AggregatedBilling from '@/pages/ServiceWizard/partials/AggregatedBilling'

enum PDBFetchStates {
  fetchingFromPDB = 1,
  fetchedFromPDB = 2,
}

const TabIpTransit: FC = () => {
  const { ipTransitWizardState, updateTransitWizard } =
    useIpTransitWizardState()

  const handlePurchaseReferenceChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateTransitWizard({ purchaseReference: e.target.value })
  }

  const [pdbState, setPdbState] = useState<PDBFetchStates | null>(null)
  const pdbTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const toggleAggregatedBilling = (isChecked: boolean) =>
    updateTransitWizard({ hasAggregatedBilling: isChecked })

  const onLoadingChange = useMemo(
    () => (loading: boolean, isValid: boolean) => {
      setPdbState(
        loading
          ? PDBFetchStates.fetchingFromPDB
          : isValid
            ? PDBFetchStates.fetchedFromPDB
            : null
      )

      if (pdbTimeout.current !== null) {
        clearTimeout(pdbTimeout.current)
      }

      if (pdbState === PDBFetchStates.fetchedFromPDB) {
        pdbTimeout.current = setTimeout(() => {
          setPdbState(null)
          pdbTimeout.current = null
        }, 3000)
      }
    },
    [pdbState]
  )

  return (
    <div className="w-full gap-y-10 flex flex-col">
      <h1 className="text-heading-4 tracking-tightest text-brand-1">
        Configure your IP Transit
      </h1>

      <div>
        <div className="flex sm:justify-between justify-center items-center">
          <div className="text-heading-byline tracking-tightest text-brand-1">
            Configure IP-Transit
          </div>
          {pdbState === PDBFetchStates.fetchingFromPDB && (
            <div className="text-neutral-50 flex">
              <span>Fetching data from PeeringDB...</span>
            </div>
          )}
          {pdbState === PDBFetchStates.fetchedFromPDB && (
            <div className="flex space-x-2">
              <span className="text-neutral-50">
                Fetched data from PeeringDB
              </span>
              <CheckIcon className="w-4 h-4" />
            </div>
          )}
        </div>

        <BgpSessionForm onLoading={onLoadingChange} />
      </div>

      <div>
        <div className="text-heading-byline tracking-tightest text-brand-1">
          Configure IP-Transit
        </div>

        <div className="flex flex-col space-between gap-y-4 mt-2">
          <Input
            name="purchaseReference"
            cy="input-purchaseReference"
            label="Purchase Reference"
            onChange={handlePurchaseReferenceChange}
            value={ipTransitWizardState.purchaseReference || ''}
            placeholder="eg: PO-1"
          />
        </div>
      </div>

      <div>
        <div className="text-heading-byline tracking-tightest text-brand-1 mb-2">
          Committed Data Rate
        </div>

        <CommittedDataRateSection />
      </div>

      <AggregatedBilling
        isChecked={ipTransitWizardState.hasAggregatedBilling}
        toggleAggregatedBilling={toggleAggregatedBilling}
      />
    </div>
  )
}

export default memo(TabIpTransit)
