import { PortSpeedOption } from '@/pages/ServiceWizard/ServiceWizard.types'
import OptionBlock from '@/pages/ServiceWizard/partials/OptionBlock'

import Tooltip from '@/components/Tooltip'
import { classNames, price } from '@/lib/misc/Utils'

import WarningIcon from '@icons/exclamation-triangle'

interface PortSpeedsProps {
  portsClassName?: string
  speeds: PortSpeedOption[]
  selectedPortSpeed?: PortSpeedOption
  isSetupCostShown: boolean
  isExtension: boolean
  selectPortSpeed: (portSpeed: PortSpeedOption, portType: string) => void
}

const PortSpeeds = ({
  portsClassName,
  speeds,
  selectedPortSpeed,
  isSetupCostShown,
  isExtension,
  selectPortSpeed,
}: PortSpeedsProps) => (
  <div>
    <div
      data-cy="wizard-select-port-speed"
      className="text-heading-byline tracking-tightest text-brand-1"
    >
      Select port speed
    </div>

    <div className={classNames('flex mt-3 max-w-full', portsClassName)}>
      {speeds.map((speed: PortSpeedOption, index) => (
        <OptionBlock
          key={index}
          onClick={() => selectPortSpeed(speed, speed.type)}
          isActive={speed.name === selectedPortSpeed?.name}
          cy="port-speed-block"
          className="mb-2.5 md:mb-0"
        >
          <div className="flex flex-row md:flex-col items-center justify-between">
            <div className="relative flex flex-row md:flex-col items-center">
              {speed.speed === 1 && !isExtension && (
                <Tooltip
                  className="leading-5"
                  wrapperClassName="absolute top-0 -right-6 md:-right-3"
                  icon={<WarningIcon />}
                >
                  Optical level received on your end will be higher than usual
                  and can be up to -0.3dBm. Ensure to check the light level and
                  install an attenuator if required.
                </Tooltip>
              )}

              <div className="text-strong md:text-heading-4 text-brand-4 mb-0.5">
                {speed.speed}
              </div>
              <div className="text-strong text-brand-4">{speed.unit}</div>
            </div>

            <div className="text-neutral-40 text-14 mt-0 md:mt-4">
              {speed.type}
            </div>
          </div>

          <div className="flex flex-row md:flex-col justify-between md:justify-center mt-4 md:mt-0 mb-1 md:mb-0">
            <div className="text-neutral-40 text-fineprint mb-0.5">
              Monthly Cost
            </div>
            <div className="text-strong text-brand-5">
              {price(Number(speed.mrc.amount))}
            </div>
          </div>

          {isSetupCostShown && (
            <div className="flex flex-row md:flex-col justify-between md:justify-center">
              <div className="text-neutral-40 text-fineprint mb-0.5">
                Setup Cost
              </div>
              <div className="text-strong text-brand-5">
                {price(Number(speed.nrc.amount))}
              </div>
            </div>
          )}
        </OptionBlock>
      ))}
    </div>
  </div>
)

export default PortSpeeds
