import { PrefixOption } from '@/pages/ServiceWizard/ServiceWizard.types'
import OptionBlock from '@/pages/ServiceWizard/partials/OptionBlock'

import Tooltip from '@/components/Tooltip'
import { price } from '@/lib/misc/Utils'

import InfoIcon from '@icons/information-circle'

interface PrefixProps {
  prefix: PrefixOption
  onClick: Function
  isActive: boolean
  isSetupCostShown: boolean
  prefixType: 'IPv4' | 'IPv6'
}

const Prefix = ({
  prefix,
  onClick,
  isActive,
  isSetupCostShown,
  prefixType,
}: PrefixProps) => (
  <OptionBlock
    onClick={() => onClick(prefix)}
    isActive={isActive}
    cy={`prefix-${prefixType}-block`}
  >
    {prefix.isRecommended && (
      <div className="absolute top-2 left-0 right-0 py-0.5 text-center px-3">
        <span className="bg-brand-5/20 rounded-full px-2 text-fineprint text-brand-5">
          Recommended
        </span>
      </div>
    )}
    <div className="text-heading-4 text-brand-4 mt-3">/{prefix.id}</div>
    <div>
      <span className="text-neutral-40 text-fineprint flex justify-center items-center">
        {prefix.addresses} addresses
        {prefix.note && (
          <Tooltip className="ml-1" wrapperClassName="flex" icon={<InfoIcon />}>
            {prefix.note}
          </Tooltip>
        )}
      </span>
    </div>

    <div className="mt-1">
      <div className="text-neutral-40 text-fineprint mb-0.5">Monthly Cost</div>
      <div className="text-strong text-brand-5">
        {price(Number(prefix.mrc?.amount ?? 0))}
      </div>
    </div>

    {isSetupCostShown && (
      <div className="mt-1">
        <div className="text-neutral-40 text-fineprint mb-0.5">Setup Cost</div>
        <div className="text-strong text-brand-5">
          {price(Number(prefix.nrc?.amount ?? 0))}
        </div>
      </div>
    )}
  </OptionBlock>
)

export default Prefix
