import { FormEvent, ReactElement, ReactNode, useMemo, useState } from 'react'

import Button from '@/components/Button'
import Input from '@/components/GenericInput'
import { GenericErrors } from '@/lib/definitions/generic.types'
import { classNames } from '@/lib/misc/Utils'

import SuccessIcon from '@icons/check-circle'
import WarningIcon from '@icons/exclamation-triangle'
import InfoIcon from '@icons/information-circle'

interface DialogProps {
  title?: string
  message?: string | ReactElement
  children?: ReactNode
  actionConfirmationMessage?: string | ReactElement
  actionConfirmationValue?: string | number
  onDismiss: () => void
  onConfirm: () => void
  dismissOnBackdrop?: boolean
  cancellable?: boolean
  hasButtons?: boolean
  disabledButtons?: boolean
  confirmText?: string | ReactElement
  cancelText?: string
  cy?: string
  type?: 'warning' | 'info' | 'error' | 'success'
  wrapperClassName?: string
  fullPage?: boolean
}

const Dialog = ({
  title,
  message,
  children,
  onDismiss,
  onConfirm,
  actionConfirmationMessage,
  actionConfirmationValue,
  dismissOnBackdrop = true,
  cancellable = true,
  hasButtons = true,
  disabledButtons = false,
  confirmText = 'Proceed',
  cancelText = 'Cancel',
  cy,
  type,
  wrapperClassName,
  fullPage,
}: DialogProps) => {
  const [confirmationInputText, setConfirmationInputText] = useState('')
  const [confirmationInputError, setConfirmationInputError] = useState<
    GenericErrors | undefined
  >()

  const icon = useMemo(() => {
    if (type === 'warning') {
      return {
        icon: <WarningIcon />,
        classes: 'text-status-warning bg-status-warning/10',
      }
    } else if (type === 'error') {
      return {
        icon: <WarningIcon />,
        classes: 'text-status-error bg-status-error/10',
      }
    } else if (type === 'info') {
      return {
        icon: <InfoIcon />,
        classes: 'text-status-info bg-status-info/10',
      }
    } else if (type === 'success') {
      return {
        icon: <SuccessIcon />,
        classes: 'text-status-good bg-status-good/10',
      }
    }
  }, [type])

  const handleConfirmation = () => {
    if (
      actionConfirmationValue &&
      actionConfirmationValue !== confirmationInputText
    ) {
      setConfirmationInputError({
        'confirmation-input': [
          `"${confirmationInputText}" doesn't match "${actionConfirmationValue}"`,
        ],
      })
      return
    } else {
      setConfirmationInputError(undefined)
    }

    onConfirm()
  }

  return (
    <div
      className={classNames(
        'il-dialog flex fixed top-0 left-0 w-screen h-screen justify-center items-center z-50',
        'supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]',  // fix for viewport height issue on mobile
        fullPage && 'w-full h-full'
      )}
    >
      <div
        className="flex fixed bg-text-darker/70 w-full backdrop-blur h-full cursor-pointer"
        onClick={() => dismissOnBackdrop && onDismiss()}
      />

      <div
        className={classNames(
          'flex flex-row border bg-white p-5 md:rounded-lg shadow-2xl z-[999]',
          fullPage
            ? 'w-[95%] h-[95%]'
            : 'w-full h-full md:h-min md:max-w-[530px] md:max-h-[calc(100vh-40px)]',
          wrapperClassName
        )}
      >
        {!!icon && (
          <div
            className={classNames(
              `flex justify-center items-center w-11 h-11 p-3 rounded-full mr-5`,
              icon.classes
            )}
          >
            {icon.icon}
          </div>
        )}
        <div className="flex flex-col w-full">
          {!!title && (
            <h2 className="text-3xl mb-2 mt-1 text-brand-1">{title}</h2>
          )}
          {!!message && (
            <h4 className="text-base text-neutral-60">{message}</h4>
          )}
          {children}

          {!!actionConfirmationMessage && (
            <>
              <h4 className="text-base mt-6 text-accent-60">
                {actionConfirmationMessage}
              </h4>
              <Input
                name="confirmation-input"
                value={confirmationInputText}
                errors={confirmationInputError?.['confirmation-input']}
                onChange={(evt: FormEvent<HTMLInputElement>) =>
                  setConfirmationInputText(evt.currentTarget.value)
                }
              />
            </>
          )}

          {hasButtons && (
            <div className="flex justify-end w-full mt-9">
              {cancellable && (
                <Button
                  style="normal"
                  className="mr-4 text-accent-60"
                  onClick={onDismiss}
                  disabled={disabledButtons}
                >
                  {cancelText}
                </Button>
              )}
              <Button
                style="primary"
                onClick={handleConfirmation}
                disabled={disabledButtons}
                cy={cy}
              >
                {confirmText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Dialog
