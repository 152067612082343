import React, { FC, FormEvent, useState } from 'react'
import usePlacesAutocomplete, {
  getDetails,
  RequestOptions,
} from 'use-places-autocomplete'
import { SearchInput } from '@/components/SelectInputs'
import { useApp } from '@/lib/provider/AppProvider'


interface GooglePlacesAutocompleteProps {
  onPlaceSelected: (details: any) => void
  onChange?: (evt: FormEvent<HTMLInputElement>) => void | undefined
  onReset?: () => void | undefined
  autocompleteRequestOptions?: RequestOptions | undefined
  placeholder?: string | undefined
  showFullAddress?: boolean | undefined
  defaultValue?: string | number | readonly string[]
}

// https://developers.google.com/maps/documentation/javascript/place-autocomplete
const GooglePlacesAutocompleteInput: FC<GooglePlacesAutocompleteProps> = ({
  onPlaceSelected,
  autocompleteRequestOptions,
  placeholder,
  onChange,
  onReset,
  showFullAddress,
  defaultValue,
  ...otherProps
}) => {

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: autocompleteRequestOptions,
    debounce: 300,
  })

  const [inputValue, setInputValue] = useState(defaultValue || "")
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setInputValue(e.target.value)
    if (!!onChange) {
      onChange(e)
    }
  }

  const handleSelect = async (placeId: string) => {
    setValue(placeId, false)
    clearSuggestions()
    const details = await getDetails({ placeId: placeId })
    setInputValue(details[!!showFullAddress ? 'formatted_address' : 'name'])
    onPlaceSelected(details)
  }

  const handleReset = async () => {
    setInputValue("")
    clearSuggestions()
    if (!!onReset) {
      onReset()
    }
  }

  return (
    <SearchInput
      {...otherProps}
      onChange={handleInput}
      onReset={handleReset}
      setValue={handleSelect}
      value={inputValue}
      placeholder={placeholder || ""}
      options={Object.values(data).map(
        (suggestion: any) => ({
          value: suggestion.place_id,
          label: `${suggestion.structured_formatting.main_text} ${suggestion.structured_formatting.secondary_text || ""}`,
        })
      )}
    />
  )
}

const GooglePlacesAutocomplete: FC<GooglePlacesAutocompleteProps> = (props) => {
  const [appState] = useApp()
  const [isLoading, setIsLoading] = useState(false)

  if (appState.isGoogleMapsLoaded) {
    return <GooglePlacesAutocompleteInput {...props} />
  }
  if (!isLoading) {
    setIsLoading(true)
    const googleMapsScript = document.createElement('script')
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GCP_API_KEY}&libraries=places`
    googleMapsScript.async = true
    googleMapsScript.addEventListener("load", () => {
      appState.isGoogleMapsLoaded = true
      setIsLoading(false)
    })
    document.head.appendChild(googleMapsScript)
  }
  return <span>Loading ...</span>
}

export default GooglePlacesAutocomplete;
