import { FC, memo, useMemo } from 'react'

import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { price } from '@/lib/misc/Utils'
import { FlexEthernetPriceResponse } from '@/lib/definitions/api.types'

interface SummaryPricingRowProps {
  prices: number
  title: string
}

interface SummaryPricingBlockProps {
  title: string
  portSpeedPrice: number
  serviceSpeedPrice: number
  extensionPrice: number
  totalPrice: number
}

const SummaryPricingRow: FC<SummaryPricingRowProps> = memo(
  ({ prices, title }) => (
    <div className="flex justify-between font-text text-neutral-40 mb-2">
      <span>{title}</span>
      <span className="tracking-wide">{price(prices)}</span>
    </div>
  )
)

const SummaryPricingBlock: FC<SummaryPricingBlockProps> = memo(
  ({
    title,
    portSpeedPrice,
    serviceSpeedPrice,
    extensionPrice,
    totalPrice,
  }) => (
    <>
      <h3 className="text-white text-xl leading-6 font-medium mt-6 mb-4">
        {title}
      </h3>
      <div className="flex flex-col">
        <SummaryPricingRow title="Port" prices={portSpeedPrice} />
        <SummaryPricingRow title="Service" prices={serviceSpeedPrice} />
        {!!extensionPrice && (
          <SummaryPricingRow title="Extension" prices={extensionPrice} />
        )}

        <div className="flex justify-between mt-2 text-medium">
          <span className="text-auxiliary-1 mb-3">Total</span>
          <span className="text-brand-3" data-cy="wizard-summary-total-price">
            {price(totalPrice)}
          </span>
        </div>
      </div>
    </>
  )
)

const calculateTotalFees = (quotes: FlexEthernetPriceResponse[]) => {
  let portSpeedNrc = 0
  let portSpeedMrc = 0
  let serviceSpeedNrc = 0
  let serviceSpeedMrc = 0
  let extensionNrc = 0
  let extensionMrc = 0
  let totalNrc = 0
  let totalMrc = 0

  quotes.forEach((quote) => {
    if (quote === null || quote === undefined) return

    serviceSpeedMrc += Number(quote.bandwidth.mrc.amount)
    serviceSpeedNrc += Number(quote.bandwidth.nrc.amount)
    totalMrc += Number(quote.total.mrc.amount)
    totalNrc += Number(quote.total.nrc.amount)
    extensionMrc += Number(quote.extension?.mrc.amount)
    extensionNrc += Number(quote.extension?.nrc.amount)

    const lagMrc = Number(quote?.lag?.mrc.amount)
    const lagNrc = Number(quote?.lag?.nrc.amount)
    if (lagNrc || lagMrc) {
      portSpeedMrc += lagMrc
      portSpeedNrc += lagNrc
    } else {
      portSpeedMrc += Number(quote.port.mrc.amount)
      portSpeedNrc += Number(quote.port.nrc.amount)
    }
  })

  return {
    portSpeedNrc,
    portSpeedMrc,
    serviceSpeedNrc,
    serviceSpeedMrc,
    extensionNrc,
    extensionMrc,
    totalNrc,
    totalMrc,
  }
}

const ELanSummaryPricing = () => {
  const { eLanWizardState } = useELanWizardState()
  const [...quotes] = eLanWizardState.quotes

  const pricing = useMemo(() => calculateTotalFees(quotes), [quotes])

  return (
    <div className="col-span-1 px-6 pb-10 pt-7">
      <h3 className="text-heading-4 text-white">Total Fees</h3>

      <SummaryPricingBlock
        title="One-time Setup"
        serviceSpeedPrice={pricing.serviceSpeedNrc}
        portSpeedPrice={pricing.portSpeedNrc}
        extensionPrice={pricing.extensionNrc}
        totalPrice={pricing.totalNrc}
      />
      <SummaryPricingBlock
        title="Monthly Recurring"
        serviceSpeedPrice={pricing.serviceSpeedMrc}
        portSpeedPrice={pricing.portSpeedMrc}
        extensionPrice={pricing.extensionMrc}
        totalPrice={pricing.totalMrc}
      />
    </div>
  )
}

export default memo(ELanSummaryPricing)
