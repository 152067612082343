import Button from '@/components/Button'
import { ExistingPortItem } from '@/pages/ServiceWizard/ServiceWizard.types'
import ExistingPortCard from '@/pages/ServiceWizard/partials/ExistingPortCard'
import { ITEMS_COUNT_TO_SHOW } from '@/lib/definitions/constants'
import { LocationCardProps } from '@/pages/ServiceWizard/partials/Locations'

interface ExistingPortsCardsProps extends LocationCardProps {
  existingPorts: ExistingPortItem[]
  setExistingPort: (port: ExistingPortItem) => void
}

const ExistingPortsCards = ({
  title,
  itemsToShow,
  selectedLocation,
  existingPorts,
  selectedExistingPort,
  setItemsToShow,
  setExistingPort,
}: ExistingPortsCardsProps) => (
  <>
    <div className="text-brand-4 text-2xl leading-[120%] tracking-[-0.24px] mb-1 md:leading-[normal] md:tracking-[-0.48px]">
      {title}
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
      {existingPorts.slice(0, itemsToShow).map((port) => (
        <ExistingPortCard
          {...(!!setExistingPort && {
            selected: selectedLocation,
            onClick: () => setExistingPort(port),
          })}
          key={port.id}
          selected={selectedExistingPort}
          cy="wizard-city"
          existingPort={port}
        />
      ))}
    </div>
    {existingPorts.length > itemsToShow && (
      <Button
        style="normal"
        className="mx-auto mt-5"
        onClick={() => setItemsToShow((prev) => prev + ITEMS_COUNT_TO_SHOW)}
      >
        Load more
      </Button>
    )}
    {existingPorts.length <= itemsToShow &&
      existingPorts.length > ITEMS_COUNT_TO_SHOW && (
        <Button
          style="normal"
          className="mx-auto mt-5"
          onClick={() => setItemsToShow(ITEMS_COUNT_TO_SHOW)}
        >
          Hide
        </Button>
      )}
  </>
)

export default ExistingPortsCards
