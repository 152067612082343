import { useEffect, useMemo, useState } from 'react'
import { captureException } from '@sentry/react'
import { Notify } from '@/lib/misc/Notify'
import { GenericErrors } from '@/lib/definitions/generic.types'
import { useMutation } from 'react-query'

import useDDoSProtectionWizardState from '@/lib/hooks/useDDoSProtectionWizardState'
import { DDoSProtectionTabSteps } from '@/pages/ddos-protection/DDoSProtection'
import ServiceWizardSummary from '@/pages/ServiceWizard/ServiceWizardSummary'
import DDoSProtectionQuote from '@/pages/ddos-protection/partials/DDoSProtectionQuote'
import { useLoadLocations } from '@/lib/queries/Services.queries'
import SummaryFancyBox from '@/pages/ServiceWizard/partials/SummaryFancyBox'
import LocationsMap from '@/components/LocationsMap'
import { contractTerms } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import {
  DDOSPriceRequest,
  DDOSPriceResponse,
} from '@/lib/definitions/api.types'
import { DDosProtectionService } from '@/lib/services/DDoSProtectionService'
import { DDoSPrefix, DDoSProtectionPricing } from '@/pages/ServiceWizard/ServiceWizard.types'

interface DDoSProtectionWizardSummaryProps {
  services?: any[] // TODO add a proper type
}

const ddosService = new DDosProtectionService()

const DDoSProtectionWizardSummary = ({
  services,
}: DDoSProtectionWizardSummaryProps) => {
  const { ddosProtectionWizardState, updateDDoSProtectionWizard } =
    useDDoSProtectionWizardState()
  const [quotes, setQuotes] = useState<any[]>([])

  const isExtended: boolean = useMemo(() => {
    if (!ddosProtectionWizardState.activeTab) return false

    return (
      Number(DDoSProtectionTabSteps[ddosProtectionWizardState.activeTab]) ===
      DDoSProtectionTabSteps.Summary
    )
  }, [ddosProtectionWizardState.activeTab])

  const { data: locations } = useLoadLocations()

  const selectedLocations: string[] = useMemo(
    () => (services ? services.map((service) => service.location.name) : []),
    [services]
  )

  const requestPrice = ({
    prefix,
  }: {
    prefix: DDoSPrefix
    prefixIndex: number
  }) => {
    const term = contractTerms.find(
      (term) => term.label_short === prefix.term
    )?.period

    const pricingRequest: DDOSPriceRequest = {
      term: term!,
      location: 'BER1-DE',
      tier: prefix.size,
      prefixCount: prefix.count,
    }

    return ddosService.requestPriceSummary(pricingRequest)
  }

  const { mutate: requestPriceQuote } = useMutation(requestPrice, {
    onError: (error: GenericErrors) => {
      Notify.error(error?.message ?? 'An error occurred')
      captureException(error)
    },
    onSuccess: async ({ data }: { data: DDOSPriceResponse }, { prefix }) => {
      if (!data) {
        Notify.error('An error occurred while getting your order quote')
        updateDDoSProtectionWizard({ pricing: undefined })

        return
      }

      setQuotes((quotes) => [...quotes, { prefix, quote: data }])
    },
  })

  useEffect(() => {
    if (quotes.length !== ddosProtectionWizardState.prefixes.length) return

    const pricingBreakdown: DDoSProtectionPricing = {
      mrc: [],
      nrc: 0,
      total: 0,
    }

    quotes.forEach(({ quote, prefix }) => {
      const mrc = Number(quote.total.mrc.amount)
      const nrc = Number(quote.total.nrc.amount)
      const mrcTotal = mrc * prefix.count

      const existingPricingItem = pricingBreakdown.mrc.find(
        (pricingItem) =>
          pricingItem.size === prefix.size && pricingItem.term === prefix.term
      )

      if (existingPricingItem) {
        existingPricingItem.cost += mrcTotal
        existingPricingItem.count += prefix.count
      } else {
        pricingBreakdown.mrc.push({
          ...prefix,
          cost: mrcTotal,
        })
      }

      pricingBreakdown.nrc += nrc
      pricingBreakdown.total += mrcTotal + nrc
    })

    const pricing = {
      ...pricingBreakdown,
      mrc: pricingBreakdown.mrc.sort((a, b) => (b.size > a.size ? 1 : -1)),
    }

    updateDDoSProtectionWizard({ pricing })
  }, [
    quotes,
    ddosProtectionWizardState.prefixes.length,
    updateDDoSProtectionWizard,
  ])

  useEffect(() => {
    if (ddosProtectionWizardState.prefixes.length) {
      setQuotes([])
      ddosProtectionWizardState.prefixes.forEach((prefix, prefixIndex) =>
        requestPriceQuote({ prefix, prefixIndex })
      )
    }
  }, [requestPriceQuote, ddosProtectionWizardState.prefixes])

  return (
    <ServiceWizardSummary
      isExtended={isExtended}
      title="DDoS Protection"
      summaryBody={<DDoSProtectionQuote />}
      extendedSummaryBody={
        <div className="px-0 md:px-4">
          <SummaryFancyBox className="!p-0" wrapperClassName="my-4 shadow-1">
            <LocationsMap
              locations={locations}
              selectedLocations={selectedLocations}
            />
          </SummaryFancyBox>

          <h2 className="text-white text-heading-byline mt-6 mb-4">Prefixes</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-4">
            {ddosProtectionWizardState.prefixes.map((prefix, prefixIndex) => (
              <SummaryFancyBox
                key={prefixIndex}
                className="flex flex-col justify-start p-4 text-white"
                wrapperClassName="mb-2"
              >
                <div className="w-full h-full flex flex-row items-center mt-1">
                  <span>
                    {prefix.count}x {prefix.size}
                  </span>
                  <span className="w-1 h-1 mx-2 rounded-full bg-white mt-0.5" />
                  <span>{prefix.type}</span>
                  <span className="w-1 h-1 mx-2 rounded-full bg-white mt-0.5" />
                  <span>
                    {
                      contractTerms.find(
                        (term) => term.label_short === prefix.term
                      )?.label
                    }
                  </span>
                </div>
              </SummaryFancyBox>
            ))}
          </div>
        </div>
      }
      extendedSummarySidebar={<DDoSProtectionQuote />}
    />
  )
}

export default DDoSProtectionWizardSummary
