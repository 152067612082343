import {
  FC,
  InputHTMLAttributes,
  MouseEventHandler,
  Ref,
  forwardRef,
  memo,
} from 'react'

import Input from '@/components/GenericInput'

import ArrowDownIcon from '@icons/chevron-down/mini'
import ArrowUpIcon from '@icons/chevron-up/mini'

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  cy?: string
  label?: string
  errors?: string[]
  onIncrement: () => void
  onDecrement: () => void
}

const InputNumber: FC<InputNumberProps> = forwardRef(
  ({ onIncrement, onDecrement, ...props }, ref: Ref<HTMLInputElement>) => {
    const onIncrementHandler: MouseEventHandler<SVGSVGElement> = (e) => {
      e.stopPropagation()
      e.preventDefault()
      onIncrement()
    }

    const onDecrementHandler: MouseEventHandler<SVGSVGElement> = (e) => {
      e.stopPropagation()
      e.preventDefault()
      onDecrement()
    }
    return (
      <Input type="number" {...props} ref={ref}>
        <div className="absolute -translate-y-2/4 right-3 top-2/4 z-20">
          <ArrowUpIcon
            className="text-neutral-40"
            onClick={onIncrementHandler}
          />
          <ArrowDownIcon
            className="text-neutral-40"
            onClick={onDecrementHandler}
          />
        </div>
      </Input>
    )
  }
)

export default memo(InputNumber)
