import { useCallback } from 'react'

import { DDoSProtectionStep } from '@/pages/ddos-protection/DDoSProtection'
import { DDoSPrefix, DDoSProtectionPricing } from '@/pages/ServiceWizard/ServiceWizard.types'
import {
  DDOS_PROTECTION_WIZARD,
  RESET_DDOS_PROTECTION,
  useServiceWizard,
} from '@/lib/provider/ServiceWizardProvider'

export interface DDoSProtectionWizardState {
  activeTab: DDoSProtectionStep
  purchaseReference?: string
  prefixes: DDoSPrefix[]
  pricing?: DDoSProtectionPricing
}

const useDDoSProtectionWizardState = () => {
  const { state, dispatch } = useServiceWizard()

  const ddosProtectionWizardState: DDoSProtectionWizardState =
    state.ddosProtectionWizard as any

  const updateDDoSProtectionWizard = useCallback(
    (payload: Partial<DDoSProtectionWizardState>) => {
      dispatch({
        type: DDOS_PROTECTION_WIZARD,
        payload: {
          ...payload,
        },
      })
    },
    [dispatch]
  )

  const resetDDoSProtectionWizard = useCallback(() => {
    dispatch({
      type: RESET_DDOS_PROTECTION,
    })
  }, [dispatch])

  return {
    ddosProtectionWizardState,
    updateDDoSProtectionWizard,
    resetDDoSProtectionWizard,
  }
}

export default useDDoSProtectionWizardState
