import debounce from 'lodash/debounce'
import { useEffect, useLayoutEffect, useState } from 'react'

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

interface WindowSize {
  width: number
  height: number
}

export function useWindowSize(): [number, number] {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  })

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  const debouncedHandleSize = debounce(handleSize, 200)

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleSize)

    return () => window.removeEventListener('resize', debouncedHandleSize)
  }, [debouncedHandleSize])

  useIsomorphicLayoutEffect(() => {
    handleSize()
  }, [])

  return [windowSize.width, windowSize.height]
}
