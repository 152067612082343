import { ReactNode } from 'react'

const styleClasses = {
  success: 'brand-5',
  warning: 'status-warning',
  danger: 'red-400',
  neutral: 'gray-400',
}

interface TagProps {
  style?: keyof typeof styleClasses
  text?: string
  icon?: ReactNode
}

const Tag = ({ style = 'neutral', text, icon }: TagProps) => (
  <div
    className={`flex flex-row items-center wrap border-1 rounded-3xl pl-1 pr-2 bg-white border-${styleClasses[style]}/20`}
  >
    {!!icon && (
      <span
        className={`rounded-full text-white flex justify-center items-center mr-0.5 h-2 w-2 text-xs bg-${styleClasses[style]}`}
      >
        {icon}
      </span>
    )}
    {!!text && (
      <span className={`text-[12px] h-full text-${styleClasses[style]}`}>
        {text}
      </span>
    )}
  </div>
)

export default Tag
