import { Helmet } from 'react-helmet'

import { generatePortalTitle } from '@/lib/misc/Utils'

interface PageTitleProps {
  title?: string
  bodyClassNames?: string
}

const PageTitle = ({
  title,
  bodyClassNames = 'bg-neutral-10',
}: PageTitleProps) => (
  <Helmet>
    <title>{generatePortalTitle(title)}</title>
    {bodyClassNames && <body className={bodyClassNames} />}
  </Helmet>
)

export default PageTitle
