import useELanWizardState from '@/lib/hooks/useELanWizardState'
import { portSpeedOptions } from '@/pages/ServiceWizard/mocks/ServiceWizard.mocks'
import { useMemo } from 'react'
import TrashIcon from '@icons/trash'
import AdjustmentsIcon from '@icons/adjustments-vertical'
import { ELanEndpoint } from '@/pages/ServiceWizard/ServiceWizard.types'
import {
  generateBandwidthsData,
  renderLagMaxSpeedValue,
} from '@/lib/misc/Utils'

interface ELanEndpointDetailsProps {
  endpoint: ELanEndpoint
  onDeleteEndpoint: () => void
  onReconfigureEndpoint: () => void
}

const ELanEndpointDetails = ({
  endpoint,
  onDeleteEndpoint,
  onReconfigureEndpoint,
}: ELanEndpointDetailsProps) => {
  const { eLanWizardState } = useELanWizardState()

  const { location, portSpeed, serviceSpeed, existingPortName } =
    useMemo(() => {
      let existingPort
      let serviceSpeed

      const location = eLanWizardState.locations?.find(
        (loc) => loc.name === endpoint.selectedLocation
      )
      const portSpeed = portSpeedOptions.find(
        (port) => port.name === endpoint.selectedPortName
      )

      if (endpoint.selectedServiceSpeed)
        serviceSpeed = generateBandwidthsData(endpoint.selectedServiceSpeed)

      if (endpoint.existingPortId && eLanWizardState.existingPorts) {
        existingPort = eLanWizardState.existingPorts.find(
          (port) => port.locationName === endpoint.selectedLocation
        )
      }

      return {
        location,
        portSpeed,
        serviceSpeed,
        existingPortName: existingPort?.name,
      }
    }, [eLanWizardState.locations, eLanWizardState.existingPorts, endpoint])

  return (
    <div className="flex flex-col w-full text-brand-4 bg-white border-neutral-20 border-2 rounded-xl p-6 mb-5 relative box-border">
      <div className="flex flex-col md:flex-row justify-between">
        <span className="text-2xl leading-4 font-medium">
          {location?.city.name}
        </span>
        <div className="flex text-accent-40 text-xl justify-end">
          <AdjustmentsIcon
            className="mr-5 cursor-pointer"
            onClick={onReconfigureEndpoint}
          />
          <TrashIcon className="cursor-pointer" onClick={onDeleteEndpoint} />
        </div>
      </div>
      <div className="flex flex-wrap items-center text-sm leading-6 text-neutral-50 mt-2 mb-4 md:mb-2">
        {endpoint.selectedLocation}
        <span className="w-1 h-1 mx-2 rounded-full bg-neutral-40 mt-0.5" />
        {location?.description}
        {!!existingPortName && (
          <>
            <span className="w-1 h-1 mx-2 rounded-full bg-neutral-40 mt-0.5" />
            {existingPortName}
          </>
        )}
      </div>

      <div className="flex flex-col">
        <div className="flex flex-wrap items-center font-normal text-sm leading-6 mb-4 md:mb-0">
          Port Speed: {`${portSpeed?.speed} ${portSpeed?.unit}`}
          <span className="w-1 h-1 rounded-full mx-2 mt-1 bg-brand-4" />
          {endpoint.vlanType === 'Untagged' && <span>Untagged VLAN</span>}
          {endpoint.vlanType === 'Tagged' && (
            <span>VLAN-ID: {endpoint.vlanId}</span>
          )}
          <span className="w-1 h-1 rounded-full mx-2 mt-1 bg-brand-4" />
          Service Speed: {`${serviceSpeed?.speed} ${serviceSpeed?.unit}`}
        </div>
        {endpoint.lag && (
          <div className="flex flex-wrap items-center font-normal text-sm leading-6 mb-4 md:mb-0">
            LAG Members: {endpoint.lag.memberCount}
            <span className="w-1 h-1 rounded-full mx-2 mt-1 bg-brand-4" />
            LAG Name: {endpoint.lag.name}
            <span className="w-1 h-1 rounded-full mx-2 mt-1 bg-brand-4" />
            {!!portSpeed && (
              <>
                LAG Maximum Speed:{' '}
                {renderLagMaxSpeedValue(
                  portSpeed.speed,
                  portSpeed.unit,
                  endpoint.lag.memberCount
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ELanEndpointDetails
